import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
// Hooks
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu';
import { useModal } from '../../hooks/useModal';
// Components
import Loader from '../../components/Loader/loader';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { LabelInput } from '../../components/label/label.input';

import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FaFileExcel, FaPrint, FaSearch, FaEdit } from 'react-icons/fa';
import Multiselect from '../../components/selects/mult.select';
import { TablePaginationDefault } from '../../components/table/tablePagination.default';
import { Input } from '../../components/input/input';
import { FiSlash } from 'react-icons/fi';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { LoadingPulse } from '../../components/Loader/loadingPulse';

export function ReportEventsRelation() {

    const navigate = useNavigate()
    const { setShowNotificationModal, setNotificationModalText, setShowAprovalModal } = useModal()
    const { company, branch } = useCompanyBranch();
    const [loading, setLoading] = useState(false);
    const { setMenuTitle } = useMenu()
    setMenuTitle('Relatórios')
    const token = localStorage.getItem('@auth:token');
    let selectedPDF = false
    let selectedXLSX = false
    const [branchList, setBranchList] = useState([])
    const [selectedBranchs, setSelectedBranchs] = useState([])
    const [selectedCompetenceDate, setSelectedCompetenceDate] = useState('')
    const [eventsList, setEventsList] = useState([])
    const [selectedEvents, setSelectedEvents] = useState([])

    useEffect(() => {
        setLoading(true)
        async function getData() {
            if (company === 0 || branch === 0) {
                alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
                return navigate('/')
            }

            try {

                const resultBranches = await api.get(`api/v1/companies/branch/${company}`, {
                    headers: {
                        authorization: token
                    }
                })
                setBranchList(resultBranches?.data?.data?.filter(emp => emp?.value != branch))

                const eventsParamsAPI = await api.get(`/api/v1/general/eventsPerTabeve?company=${company}`, {
                    headers: {
                        Authorization: token
                    }
                })
                setEventsList(eventsParamsAPI?.data?.data?.map(event => {
                    return {
                        ...event,
                        inputLabel: `${event?.codeve} - ${event?.deseve}`
                    }
                }))

                setLoading(false)

            } catch (error) {
                setLoading(false)
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }
        getData()
    }, [])

    async function downloadFile() {
        setLoading(true)

        try {
            const type = selectedPDF ? 'pdf' : 'excel'
            let param = []
            selectedCompetenceDate && param.push(`competence=${selectedCompetenceDate}`)
            selectedEvents && param.push(`codeve=${selectedEvents?.map(event => { return event?.codeve })}`)
            param = param.join('&')

            const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''
            const file = await api.get(`api/v1/reports/eventsRelation/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}/type/${type}${param ? `?${param}` : ''}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `RelacaoEventos_${Number(new Date())}`)
            myElement.click()
            setLoading(false)
        } catch (error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Não há nada a listar')
        }

    }

    return (
        <>
            {
                loading ?
                    <div className="animate-[wiggleLeft_1s_ease-in-out] flex flex-col w-full min-h-[80vh] items-center justify-center">
                        <Loader />
                    </div>
                    :
                    <div className='flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14'>
                        <p className='text-xl font-semibold text-center justify-center text-primaryDefaultLight mt-5'>Relação de Eventos</p>
                        <div className='flex flex-col w-fit h-fit justify-center items-center shadow-xl rounded-lg mt-20 sm:mt-16 p-2'>
                            <div className='flex flex-col justify-start h-full items-start p-6 gap-1'>
                                <LabelInput text={'Competência'} required>
                                    <Input
                                        type={'month'}
                                        value={selectedCompetenceDate ? selectedCompetenceDate : ''}
                                        onChange={(e) => {
                                            setSelectedCompetenceDate(e.target.value)
                                        }}
                                        width={80}
                                    />
                                </LabelInput>
                                <LabelInput text={'Eventos'} required>
                                    <Multiselect
                                        items={eventsList}
                                        mult
                                        onChange={e => setSelectedEvents(e)}
                                        selectedLabel={'inputLabel'}
                                        value={selectedEvents ? selectedEvents : []}
                                        id={`selectedEvents`}
                                    />
                                </LabelInput>
                                <LabelInput text={'Filiais'}>
                                    <Multiselect
                                        items={[{ label: "0 - Todas", value: 'Todas' }, ...branchList]}
                                        mult
                                        onChange={e => {
                                            if (e[0]?.value == "Todas") {
                                                setSelectedBranchs(branchList)
                                            } else {
                                                setSelectedBranchs(e)
                                            }
                                        }}
                                        selectedLabel={'label'}
                                        value={selectedBranchs ? selectedBranchs : []}
                                        id={`selectedBranchs`}
                                    />
                                </LabelInput>
                                <div className='flex flex-row justify-between w-full gap-3 mt-5'>
                                    <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95'
                                        onClick={() => {
                                            selectedXLSX = false
                                            selectedPDF = true
                                            downloadFile()
                                        }}
                                    >
                                        <FaPrint className='text-sm' /> PDF
                                    </div>
                                    <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95'
                                        onClick={() => {
                                            selectedPDF = false
                                            selectedXLSX = true
                                            downloadFile()
                                        }}
                                    >
                                        <FaFileExcel className='text-sm' /> XLSX
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}