import { FiSend, FiSettings, FiUsers, FiHome, FiPercent, FiClipboard } from 'react-icons/fi';
import { FaCalculator, FaChartLine, FaChartPie } from 'react-icons/fa';
import { TbReportSearch, TbHeartHandshake } from 'react-icons/tb';

const menuOptions = [
    {
        name: 'Home',
        type: 'simple',
        to: '/',
        permission: [1, 2, 3, 4],
        icon: <FiHome size='20' color='white' />
    },
    {
        name: 'Dashboard',
        type: 'simple',
        to: '/dashboard',
        permission: [1, 2, 3],
        icon: <FaChartPie size='20' color='white' />
    },
    {
        name: 'Administrativo',
        type: 'simple',
        to: '/dashboardAdmin',
        permission: [1],
        icon: <FaChartLine size='20' color='white' />
    },
    {
        name: 'Eventos Folha',
        type: 'list',
        icon: <FiSend size='20' color='white' />,
        permission: [1, 2, 3],
        trigger: 'option2',
        list: [
            {
                permission: [1, 2, 3],
                to: '/history',
                name: 'Consulta e Lançamento',
            },
            {
                permission: [1, 2],
                to: '/parameterization',
                name: 'Parametrização',
            }
        ]
    },
    {
        name: 'Plano de saúde',
        type: 'simple',
        to: '/healthPlanPeriods',
        permission: [1],
        trigger: 'option3',
        icon: <TbHeartHandshake size='20' color='white' />
    },
    {
        name: 'Rateios',
        type: 'list',
        icon: <FiPercent size='20' color='white' />,
        permission: [1, 2],
        trigger: 'option4',
        list: [
            {
                permission: [1, 2],
                to: '/historyAverage',
                name: 'Consulta e Lançamento',
            },
            {
                permission: [1, 2],
                to: '/parameterizationAverage',
                name: 'Parametrização',
            }
        ]
    },
    {
        name: 'Colaboradores',
        type: 'list',
        icon: <FiUsers size='20' color='white' />,
        trigger: 'option5',
        permission: [1, 2, 3, 4],
        list: [
            {
                permission: [1, 2, 3, 4],
                to: '/employeeConsult',
                name: 'Consultar',
            },
            {
                permission: [1, 2, 3, 4],
                to: '/employeeAdmission',
                name: 'Admissão',
            },
            {
                permission: [1, 2, 3],
                to: '/notes',
                name: "Anotações"
            },
            {
                permission: [1, 2],
                to: '/employeeConsultPendings',
                name: 'Pendentes',
            },
            {
                permission: [1, 2],
                to: '/employeeConsultAdmissionsPendings',
                name: 'Em andamento',
            },
        ]
    },
    {
        name: 'Relatórios',
        type: 'list',
        icon: <FiClipboard size='20' color='white' />,
        permission: [1, 2, 3],
        trigger: 'option6',
        list: [
            {
                permission: [1, 2, 3],
                to: '/reportsEmployeesHistoric',
                name: 'Histórico colaboradores',
            },
            {
                permission: [1, 2, 3],
                to: '/reportsEmployeesBirthday',
                name: 'Relação aniversariantes',
            },
            {
                permission: [1, 2, 3],
                to: '/reportsCompanyBirthday',
                name: 'Relação de tempo de casa',
            },
            {
                permission: [1, 2, 3],
                to: '/reportsEmployeesEndContracts',
                name: 'Relação prazos de contratos',
            },
            {
                permission: [1, 2, 3],
                to: '/reportsEmployeesEndHolidays',
                name: 'Relação de vencimentos de férias',
            },
            {
                permission: [1, 2, 3],
                to: '/reportsEmployeesHolidaysPerPeriod',
                name: 'Colaboradores em gozo de férias',
            },
            {
                permission: [1, 2, 3],
                to: '/reportsEmployeesData',
                name: 'Relação de colaboradores',
            },
            {
                permission: [1, 2, 3],
                to: '/reportsEmployeesWorking',
                name: 'Colaboradores Ativos',
            },
            {
                permission: [1, 2, 3],
                to: '/reportsEmployeesWithDependents',
                name: 'Colaboradores com Dependentes',
            },
            {
                permission: [1, 2],
                to: '/reportsAbsenteeismTurnover',
                name: 'Absenteísmo e Turnover'
            },
            {
                permission: [1, 2, 3],
                to: '/reportsCostEmployees',
                name: 'Custo de Empregados'
            },
            {
                permission: [1, 2, 3],
                to: '/reportsIsonomySalary',
                name: 'Isonomia Salarial'
            },
            {
                permission: [1, 2, 3],
                to: '/reportsEventsRelation',
                name: 'Relação de Eventos'
            },
            {
                permission: [1, 2, 3],
                to: '/reportsNotes',
                name: 'Anotações de colaboradores'
            }
        ]
    },
    {
        name: 'Conferências',
        type: 'list',
        icon: <TbReportSearch size='22' color='white' />,
        permission: [1, 2],
        trigger: 'option7',
        list: [
            {
                permission: [1, 2],
                to: '/conferenceSends',
                name: 'Lançamentos de Eventos',
            }
        ]
    },
    {
        name: 'Prévia',
        type: 'list',
        icon: <FaCalculator size='20' color='white' />,
        permission: [1, 2, 3],
        trigger: 'option8',
        list: [
            {
                permission: [1, 2, 3],
                to: '/previews',
                name: 'Aumento de salário',
            },
            {
                permission: [1, 2, 3],
                to: '/previewSalarySingle',
                name: 'Aumento de salário avulso',
            },
            {
                permission: [1, 2],
                to: '/previewsRescision',
                name: 'Rescisão',
            },
            {
                permission: [1, 2, 3],
                to: '/previewsHoliday',
                name: 'Férias',
            }
        ]
    },
    {
        name: 'Configuração',
        type: 'list',
        icon: <FiSettings size='20' color='white' />,
        trigger: 'option9',
        permission: [1],
        list: [
            {
                permission: [1],
                to: '/users',
                name: 'Usuários',
            },
            {
                permission: [1],
                to: '/companyUser',
                name: 'Empresa e Usuário',
            },
            {
                permission: [1],
                to: '/eventsConfigDefault',
                name: 'Eventos Padrão',
            },
            {
                permission: [1],
                to: '/companies',
                name: 'Empresas'
            },
            {
                permission: [1],
                to: '/release',
                name: 'Atualizações'
            },
            {
                permission: [1],
                to: '/instructions',
                name: 'Instruções da folha',
            },
            {
                permission: [1],
                to: '/useTerms',
                name: 'Termos de Uso',
            }
        ]
    }
];

export default menuOptions;