import React, { useState, useEffect } from 'react'
import { FiUserPlus, FiChevronRight, FiAlertTriangle, FiX, FiChevronDown, FiSlash } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'
//services
import api from '../../services/api'
//hooks
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu'
import { useModal } from '../../hooks/useModal'
//components
import Loader from '../../components/Loader/loader'
import Modal from '../../components/Modal/modal'
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { LabelInput } from '../../components/label/label.input';
//css
import './requestAdmission.css'
import { reportStyle } from './reportStyle';
import { Input } from '../../components/input/input';
import { justNumber } from '../../utils/justNumber';
import { pisMask } from '../../utils/formatText/pisMask';
import { Button } from '../../components/buttons/button.default';
import { specialRetirementList } from '../../utils/specialRetirementList'
import {maskCep} from '../../utils/formatText/cepMask'
import {cnpjMask} from '../../utils/formatText/cnpjMask'
import {typesLinkList} from '../../utils/typeLinkList'
import { LoadingPulse } from '../../components/Loader/loadingPulse';
import { TablePaginationDefault } from '../../components/table/tablePagination.default';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { removeAccents } from '../../utils/formatText/removeAccents'
import { FaExclamationTriangle } from "react-icons/fa"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { cpfMask } from '../../utils/formatText/cpfMask'
import { formatSizeCpf } from '../../utils/formatText/formatSizeCpf'
import moment from 'moment';

export default function RequestAdmissionImport() {

    //#region 
    const {idFolpagConnect} = useParams()
    const employeeId = idFolpagConnect
    const thisYear = new Date().getUTCFullYear()
    const [missingRequiredFields, setMissingRequiredFields] = useState({ 'employeeName': false })
    const [missingRequiredFieldsModal, setMissingRequiredFieldsModal] = useState({})
    const [requiredFieldsForm, setRequiredFieldForm] = useState('')
    const [errorFields, setErrorFields] = useState({ 'birthday': '' })
    const [inEdit, setInEdit] = useState(false)
    const token = localStorage.getItem('@auth:token')
    const [selectedForm, setSelectedForm] = useState(1)
    const [loading, setLoading] = useState(true)
    const [insideLoading, setInsideLoading] = useState(false)
    const { setMenuTitle, menuStatus } = useMenu()
    const { company, branch, companyParams } = useCompanyBranch()
    const { setShow, show } = useModal()
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const [errorSubmit, setErrorSubmit] = useState(false)
    setMenuTitle('Requisição de Admissão')
    const navigate = useNavigate()
    const [dateError, setDateError] = useState({})
    const [persons, setPersons] = useState([])
    const [educationalInstitutionList, setEducationalInstitutionList] = useState([])

    const [showTableImportSenior, setShowTableImportSenior] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [filterConstructor, setFilterConstructor] = useState({ })
    const [filter, setFilter] = useState({ })
    const [whichImput, setWhichImput] = useState('')
    const [url, setUrl] = useState('/api/v1/employees/schedulesWithPagination')
    const [columnTable, setColumnTable] = useState([
        {
            name: 'description',
            type: 'string',
            description: 'Escala'
        }
    ])
    const [verifyFilter, setVerifyFilter] = useState({})
    const [warningWords, setWarningWords] = useState(['aprendiz', 'estagio', 'estagiario'])

    const [showModalApply, setShowModalApply] = useState(false)
    const [showModalSave, setShowModalSave] = useState(false)
    const [showModalConfirmedSave, setShowModalConfirmedSave] = useState(false)
    const [showModalFieldsRequired, setShowModalFieldsRequired] = useState(false)
    const [showErrorEmployeeIdSenior, setShowErrorEmployeeIdSenior] = useState(false)
    const [modalAlertPreliminary, setModalAlertPreliminary] = useState(false)
    const [existsPreliminary, setExistsPreliminary] = useState(null)

    // Api Datas
    const [nationalities, setNationalities] = useState([])
    const [nationalitiesState, setNationalitiesState] = useState([])
    const [nationalitiesStateCities, setNationalitiesStateCities] = useState([])
    const [banks, setBanks] = useState([])
    const [agencies, setAgencies] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [districts, setDistricts] = useState([])
    const [brazilStates, setBrazilStates] = useState([])
    const [schedules, setSchedules] = useState([])
    const [schedulesTeams, setSchedulesTeams] = useState([])
    const [jobPositions, setJobPositions] = useState([])
    const [local, setLocal] = useState([])
    const [ccu, setCcu] = useState([])
    const [workplaces, setWorkplaces] = useState([])
    const [laborUnionList, setLaborUnionList] = useState([])
    const [stopoverTransportList, setStopoverTransportList] = useState([])
    const [natureExpenseList, setNatureExpenseList] = useState([])
    const [companyConfig, setCompanyConfig] = useState('')
    const [othersCompanyList, setOthersCompanyList] = useState([])

    // Dados do Contrato
    const [companyName, setCompanyName] = useState('')
    const [branchCNPJ, setBranchCNPJ] = useState('')
    const [employeeName, setEmployeeName] = useState('')
    const [admissionDate, setAdmissionDate] = useState('')
    const [invalidAdmissionDate, setInvalidAdmissionDate] = useState(false)
    const [typeBusinessLink, setTypeBusinessLink] = useState(10)
    const [typeApuration, setTypeApuration] = useState(1)
    const [employeeLaborUnion, setEmployeeLaborUnion] = useState(false)
    const [laborUnion, setLaborUnion] = useState('')
    const [employeePosition, setEmployeePosition] = useState('')
    const [employeeScheduleType, setEmployeeScheduleType] = useState('')
    const [employeeScheduleMonday, setEmployeeScheduleMonday] = useState('')
    const [employeeScheduleTuesday, setEmployeeScheduleTuesday] = useState('')
    const [employeeScheduleWednesday, setEmployeeScheduleWednesday] = useState('')
    const [employeeScheduleThursday, setEmployeeScheduleThursday] = useState('')
    const [employeeScheduleFriday, setEmployeeScheduleFriday] = useState('')
    const [employeeScheduleSaturday, setEmployeeScheduleSaturday] = useState('')
    const [employeeScheduleSunday, setEmployeeScheduleSunday] = useState('')
    const [employeeSalary, setEmployeeSalary] = useState('')
    const [typeSalary, setTypeSalary] = useState(0)
    const [employeeLocal, setEmployeeLocal] = useState('')
    const [employeeCCU, setEmployeeCCU] = useState('')
    const [employeeWorkplace, setEmployeeWorplace] = useState('')
    const [employeeValueVT, setEmployeeValueVT] = useState(0)
    const [advanceSalary, setAdvanceSalary] = useState(false)
    const [advanceSalaryPorcentage, setAdvanceSalaryPorcentage] = useState(0)
    const [paydaySalary, setPaydaySalary] = useState("")
    const [employeeExperience, setEmployeeExperience] = useState(0)
    const [lifeInsurance, setLifeInsurance] = useState(false)
    const [insalubrity, setInsalubrity] = useState(false)
    const [periculosity, setPericulosity] = useState(false)
    const [employeeVA, setEmployeeVA] = useState(0)
    const [employeeVR, setEmployeeVR] = useState(0)
    const [paymentPeriod, setPaymentPeriod] = useState('M')
    const [paymentMethod, setPaymentMethod] = useState('R')
    const [employeeBank, setEmployeBank] = useState(0)
    const [employeeBankName, setEmployeBankName] = useState('')
    const [employeBankAgencie, setEmployeBankAgencie] = useState(0)
    const [employeeBankAccountType, setEmployeeBankAccountType] = useState(0)
    const [employeeBankAccount, setEmployeeBankAccount] = useState(0)
    const [employeeBankAccountDigit, setEmployeeBankAccountDigit] = useState(0)
    const [isIntermittent , setIsIntermittent] = useState()
    const [observation, setObservation] = useState('')
    const [traineeInsurancePolicy, setTraineeInsurancePolicy] = useState('')
    const [traineeEndForecast, setTraineeEndForecast] = useState('')
    const [traineeEducationalInstitution, setTraineeEducationalInstitution] = useState('')
    const [traineeCodSupervisor, setTraineeCodSupervisor] = useState('')
    const [advanceMonthAdmission, setAdvanceMonthAdmission] = useState('')
    const [natureExpense, setNatureExpense] = useState('')
    const [insalubrityPercentage, setInsalubrityPercentage] = useState('')
    const [periculosityPercentage, setPericulosityPercentage] = useState('')
    const [digitAgency, setDigitAgency] = useState('')
    const [apprenticeInstitutionName, setApprenticeInstitutionName] = useState('')
    const [apprenticeInstitutionCNPJ, setApprenticeInstitutionCNPJ] = useState('')
    const [apprenticeDateFinish, setApprenticeDateFinish] = useState('')
    const [gratificationFunction, setGratificationFunction] = useState('')

    //Dados do Empregado
    const [nickname, setNickname] = useState('')
    const [employeeSexualGender, setEmployeeSexualGender] = useState('')
    const [civilStatus, setCivilStatus] = useState(0)
    const [levelOfEducation, setLevelOfEducation] = useState(0)
    const [birthday, setBirthday] = useState('')
    const [nationality, setNationality] = useState('')
    const [nationalityDescription, setNationalityDescription] = useState('')
    const [employeeUf, setEmployeeUf] = useState('')
    const [employeeCity, setEmployeeCity] = useState('')
    const [wordCardType, setSelectedWorkCardType] = useState('0')
    const [workCard, setWorkCard] = useState('')
    const [workCardSerie, setWorkCardSerie] = useState('')
    const [workCardEmission, setWorkCardEmission] = useState('')
    const [workCardUf, setWorkCardUf] = useState('')
    const [cpf, setCpf] = useState('')
    const [firstJob, setFirstJob] = useState('')
    const [pis, setPis] = useState('')
    const [emissionPis, setEmissionPis] = useState('')
    const [rg, setRg] = useState('')
    const [sendingAgencyRG, setSendingAgencyRg] = useState('')
    const [EmissionRG, setEmissionRg] = useState('')
    const [rgUf, setRgUf] = useState('')
    const [voterRegistration, setVoterRegistration] = useState('')
    const [voterRegistrationZone, setVoterRegistrationZone] = useState('')
    const [voterRegistrationSection, setVoterRegistrationSection] = useState('')
    const [cnh, setCnh] = useState('')
    const [cnhExists, setCnhExists] = useState(false)
    const [cnhIssuer, setCnhIssuer] = useState('')
    const [cnhCategory, setCnhCategory] = useState('')
    const [cnhValidity, setCnhValidity] = useState('')
    const [cnhUf, setCnhUf] = useState('')
    const [cnhFirstDate, setCnhFirstDate] = useState('')
    const [cnhDispatchDate, setCnhDispatchDate] = useState('')
    const [militaryServiceCard, setMilitaryServiceCard] = useState('')
    const [militaryServiceCardUf, setMilitaryServiceCardUf] = useState('')
    const [deficiency, setDeficiency] = useState(false)
    const [deficiencyType, setDeficiencyType] = useState(0)
    const [deficiencyRehabilitatedInss, setDeficiencyRehabilitatedInss] = useState(false)
    const [deficiencyPcdQuote, setDeficiencyPcdQuote] = useState(false)
    const [employeeRace, setEmployeeRace] = useState('')
    const [addressCountry, setAddressCountry] = useState(0)
    const [addressCountryName, setAddressCountryName] = useState(0)
    const [addressCep, setAddressCep] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressCity, setAddressCity] = useState('')
    const [addressCityName, setAddressCityName] = useState('')
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressDistrictName, setAddressDistrictName] = useState('')
    const [addressType, setAddressType] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState(0)
    const [addressComplement, setAddressComplement] = useState('')
    const [phoneDdd, setPhoneDdd] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [employeeEmail, setEmployeeEmail] = useState('')
    const [employeeEmailCorporate, setEmployeeEmailCorporate] = useState('')
    const [dateEmploymentExamination, setDateEmploymentExamination] = useState('')
    const [employeeVT, setEmployeeVT] = useState(false)
    const [invalidCPF, setInvalidCPF] = useState(false)
    const [invalidDependentCPF, setInvalidDependentCPF] = useState([])
    const [foreignResidenceTime, setForeignResidenceTime] = useState('')
    const [foreignCondition, setForeignCondition] = useState('')
    const [foreignFinishResidence, setForeignFinishResidence] = useState('')
    const [foreignArrivalDate, setForeignArrivalDate] = useState('')
    const [foreignRegistry, setForeignRegistry] = useState('')
    const [foreignDateExpRegistry, setForeignDateExpRegistry] = useState('')
    const [foreignVisaValidity, setForeignVisaValidity] = useState('')
    const [foreignDateValidityCTPS, setForeignDateValidityCTPS] = useState('')
    const [foreignCasWithBrazilian, setForeignCasWithBrazilian] = useState('')
    const [foreignBrazilianChildren, setForeignBrazilianChildren] = useState('')

    //Dados dos dependentes
    const [fatherName, setFatherName] = useState('')
    const [motherName, setMotherName] = useState('')
    const [dependents, setDependents] = useState([])

    //Campos para importar no Senior
    const [employeeIdSenior, setEmployeeIdSenior] = useState(0)
    const [scheduleSenior, setScheduleSenior] = useState(0)
    const [scheduleSeniorObject, setScheduleSeniorObject] = useState({})
    const [laborUnionSeniorObject, setLaborUnionSeniorObject] = useState({})
    const [scheduleTeamSenior, setScheduleTeamSenior] = useState(0)
    const [scheduleTeamSeniorObject, setScheduleTeamSeniorObject] = useState({})
    const [localSenior, setLocalSenior] = useState(0)
    const [localSeniorObject, setLocalSeniorObject] = useState({})
    const [localOrganizationSenior, setLocalOrganizationSenior] = useState(0)
    const [jobPositionSenior, setJobPositionSenior] = useState(0)
    const [jobPositionSeniorObject, setJobPositionSeniorObject] = useState({})
    const [jobPositionEstructureSenior, setJobPositionEstructureSenior] = useState('')
    const [ccuSenior, setCcuSenior] = useState(0)
    const [ccuSeniorObject, setCcuSeniorObject] = useState({})
    const [ccuOriginSenior, setCcuOriginSenior] = useState(0)
    const [laborUnionSenior, setLaborUnionSenior] = useState(0)
    const [workplaceSenior, setWorkplaceSenior] = useState('')
    const [workplaceSeniorObject, setWorkplaceSeniorObject] = useState({})
    const [workplaceSeniorStructure, setWorkplaceSeniorStructure] = useState(0)
    const [stopoverTransportSenior, setStopoverTransportSenior] = useState(0)
    const [stopoverTransportObject, setStopoverTransportObject] = useState(0)
    const [traineeSelectedSupervisorSenior, setTraineeSelectedSupervisorSenior] = useState('')
    const [traineeSelectedInstitutionSenior, setTraineeSelectedInstitutionSenior] = useState('')
    const [employeeAgencySeniorObj, setEmployeeAgencySeniorObj] = useState('')
    const [employeeAgencySenior, setEmployeeAgencySenior] = useState('')
    const [tokenRegistry, setTokenRegistry] = useState('')
    const [codPlate, setCodPlate] = useState('')
    const [natureExpenseSeniorObj, setNatureExpenseSeniorObj] = useState('')
    const [natureExpenseSenior, setNatureExpenseSenior] = useState('')
    const [insalubritySenior, setInsalubritySenior] = useState('')
    const [dangerSenior, setDangerSenior] = useState('')
    const [specialRetirementSenior, setSpecialRetirementSenior] = useState('')
    const [confirmedTypeSenior, setConfirmedTypeSenior] = useState('')
    const [numberTag, setNumberTag] = useState('')
    const [apprenticeTypeContract, setApprenticeTypeContract] = useState('')
    const [apprenticeIndirectRegistration, setApprenticeIndirectRegistration] = useState('')
    const [apprenticeCnpjIndirectRegistration, setApprenticeCnpjIndirectRegistration] = useState('')
    const [apprenticeEntityQualify, setApprenticeEntityQualify] = useState('')
    const [apprenticeCnpjEntityQualify, setApprenticeCnpjEntityQualify] = useState('')
    const [apprenticeCnpjPracticalActivity, setApprenticeCnpjPracticalActivity] = useState('')

    //historico
    // Dados do Contrato
    const [companyNameHistory, setCompanyNameHistory] = useState('')
    const [branchCNPJHistory, setBranchCNPJHistory] = useState('')
    const [employeeNameHistory, setEmployeeNameHistory] = useState('')
    const [admissionDateHistory, setAdmissionDateHistory] = useState('')
    const [typeBusinessLinkHistory, setTypeBusinessLinkHistory] = useState(10)
    const [typeApurationHistory, setTypeApurationHistory] = useState(1)
    const [employeeLaborUnionHistory, setEmployeeLaborUnionHistory] = useState(false)
    const [laborUnionHistoty, setLaborUnionHistoty] = useState('')
    const [employeePositionHistory, setEmployeePositionHistory] = useState('')
    const [employeeScheduleTypeHistory, setEmployeeScheduleTypeHistory] = useState('')
    const [employeeScheduleMondayHistory, setEmployeeScheduleMondayHistory] = useState('')
    const [employeeScheduleTuesdayHistory, setEmployeeScheduleTuesdayHistory] = useState('')
    const [employeeScheduleWednesdayHistory, setEmployeeScheduleWednesdayHistory] = useState('')
    const [employeeScheduleThursdayHistory, setEmployeeScheduleThursdayHistory] = useState('')
    const [employeeScheduleFridayHistory, setEmployeeScheduleFridayHistory] = useState('')
    const [employeeScheduleSaturdayHistory, setEmployeeScheduleSaturdayHistory] = useState('')
    const [employeeScheduleSundayHistory, setEmployeeScheduleSundayHistory] = useState('')
    const [employeeSalaryHistory, setEmployeeSalaryHistory] = useState('')
    const [typeSalaryHistory, setTypeSalaryHistory] = useState(0)
    const [employeeLocalHistory, setEmployeeLocalHistory] = useState('')
    const [employeeCCUHistory, setEmployeeCCUHistory] = useState('')
    const [employeeWorkplaceHistory, setEmployeeWorplaceHistory] = useState('')
    const [employeeValueVTHistory, setEmployeeValueVTHistory] = useState(0)
    const [advanceSalaryHistory, setAdvanceSalaryHistory] = useState(false)
    const [advanceSalaryPorcentageHistory, setAdvanceSalaryPorcentageHistory] = useState(0)
    const [paydaySalaryHistory, setPaydaySalaryHistory] = useState("")
    const [employeeExperienceHistory, setEmployeeExperienceHistory] = useState(0)
    const [lifeInsuranceHistory, setLifeInsuranceHistory] = useState(false)
    const [insalubrityHistory, setInsalubrityHistory] = useState(false)
    const [periculosityHistory, setPericulosityHistory] = useState(false)
    const [employeeVAHistory, setEmployeeVAHistory] = useState(0)
    const [employeeVRHistory, setEmployeeVRHistory] = useState(0)
    const [paymentPeriodHistory, setPaymentPeriodHistory] = useState('M')
    const [paymentMethodHistory, setPaymentMethodHistory] = useState('R')
    const [employeeBankHistory, setEmployeBankHistory] = useState(0)
    const [employeeBankNameHistory, setEmployeBankNameHistory] = useState('')
    const [employeBankAgencieHistory, setEmployeBankAgencieHistory] = useState(0)
    const [employeeBankAccountTypeHistory, setEmployeeBankAccountTypeHistory] = useState(0)
    const [employeeBankAccountHistory, setEmployeeBankAccountHistory] = useState(0)
    const [employeeBankAccountDigitHistory, setEmployeeBankAccountDigitHistory] = useState(0)
    const [isIntermittentHistory, setIsIntermittentHistory] = useState()
    const [observationHistory, setObservationHistory] = useState('')
    const [traineeInsurancePolicyHistory, setTraineeInsurancePolicyHistory] = useState('')
    const [traineeEndForecastHistory, setTraineeEndForecastHistory] = useState('')
    const [traineeEducationalInstitutionHistory, setTraineeEducationalInstitutionHistory] = useState('')
    const [traineeCodSupervisorHistory, setTraineeCodSupervisorHistory] = useState('')
    const [traineeOrigin, setTraineeOrigin] = useState('')
    const [traineeOriginHistory, setTraineeOriginHistory] = useState('')
    const [traineeEducationLevel, setTraineeEducationLevel] = useState('')
    const [traineeEducationLevelHistory, setTraineeEducationLevelHistory] = useState('')
    const [advanceMonthAdmissionHistory, setAdvanceMonthAdmissionHistory] = useState('')
    const [natureExpenseHistory, setNatureExpenseHistory] = useState('')
    const [insalubrityPercentageHistory, setInsalubrityPercentageHistory] = useState('')
    const [periculosityPercentageHistory, setPericulosityPercentageHistory] = useState('')
    const [digitAgencyHistory, setDigitAgencyHistory] = useState('')
    const [apprenticeInstitutionNameHistory, setApprenticeInstitutionNameHistory] = useState('')
    const [apprenticeInstitutionCNPJHistory, setApprenticeInstitutionCNPJHistory] = useState('')
    const [apprenticeDateFinishHistory, setApprenticeDateFinishHistory] = useState('')
    const [gratificationFunctionHistory, setGratificationFunctionHistory] = useState('')

    //Dados do Empregado
    const [nicknameHistory, setNicknameHistory] = useState('')
    const [employeeSexualGenderHistory, setEmployeeSexualGenderHistory] = useState('')
    const [civilStatusHistory, setCivilStatusHistory] = useState(0)
    const [levelOfEducationHistory, setLevelOfEducationHistory] = useState(0)
    const [birthdayHistory, setBirthdayHistory] = useState('')
    const [nationalityHistory, setNationalityHistory] = useState('')
    const [nationalityDescriptionHistory, setNationalityDescriptionHistory] = useState('')
    const [employeeUfHistory, setEmployeeUfHistory] = useState('')
    const [employeeCityHistory, setEmployeeCityHistory] = useState('')
    const [workCardHistory, setWorkCardHistory] = useState('')
    const [workCardSerieHistory, setWorkCardSerieHistory] = useState('')
    const [workCardEmissionHistory, setWorkCardEmissionHistory] = useState('')
    const [workCardUfHistory, setWorkCardUfHistory] = useState('')
    const [cpfHistory, setCpfHistory] = useState('')
    const [firstJobHistory, setFirstJobHistory] = useState('')
    const [pisHistory, setPisHistory] = useState('')
    const [emissionPisHistory, setEmissionPisHistory] = useState('')
    const [rgHistory, setRgHistory] = useState('')
    const [sendingAgencyRGHistory, setSendingAgencyRgHistory] = useState('')
    const [EmissionRGHistory, setEmissionRgHistory] = useState('')
    const [rgUfHistory, setRgUfHistory] = useState('')
    const [voterRegistrationHistory, setVoterRegistrationHistory] = useState('')
    const [voterRegistrationZoneHistory, setVoterRegistrationZoneHistory] = useState('')
    const [voterRegistrationSectionHistory, setVoterRegistrationSectionHistory] = useState('')
    const [cnhHistory, setCnhHistory] = useState('')
    const [cnhIssuerHistory, setCnhIssuerHistory] = useState('')
    const [cnhCategoryHistory, setCnhCategoryHistory] = useState('')
    const [cnhValidityHistory, setCnhValidityHistory] = useState('')
    const [cnhUfHistory, setCnhUfHistory] = useState('')
    const [cnhFirstDateHistory, setCnhFirstDateHistory] = useState('')
    const [cnhDispatchDateHistory, setCnhDispatchDateHistory] = useState('')
    const [militaryServiceCardHistory, setMilitaryServiceCardHistory] = useState('')
    const [militaryServiceCardUfHistory, setMilitaryServiceCardUfHistory] = useState('')
    const [deficiencyHistory, setDeficiencyHistory] = useState(false)
    const [deficiencyTypeHistory, setDeficiencyTypeHistory] = useState(0)
    const [deficiencyRehabilitatedInssHistory, setDeficiencyRehabilitatedInssHistory] = useState(false)
    const [deficiencyPcdQuoteHistory, setDeficiencyPcdQuoteHistory] = useState(false)
    const [employeeRaceHistory, setEmployeeRaceHistory] = useState('')
    const [addressCountryHistory, setAddressCountryHistory] = useState(0)
    const [addressCountryNameHistory, setAddressCountryNameHistory] = useState(0)
    const [addressCepHistory, setAddressCepHistory] = useState('')
    const [addressStateHistory, setAddressStateHistory] = useState('')
    const [addressCityHistory, setAddressCityHistory] = useState('')
    const [addressCityNameHistory, setAddressCityNameHistory] = useState('')
    const [addressDistrictHistory, setAddressDistrictHistory] = useState('')
    const [addressDistrictNameHistory, setAddressDistrictNameHistory] = useState('')
    const [addressTypeHistory, setAddressTypeHistory] = useState('')
    const [addressRoadHistory, setAddressRoadHistory] = useState('')
    const [addressNumberHistory, setAddressNumberHistory] = useState(0)
    const [addressComplementHistory, setAddressComplementHistory] = useState('')
    const [phoneDddHistory, setPhoneDddHistory] = useState('')
    const [phoneNumberHistory, setPhoneNumberHistory] = useState('')
    const [employeeEmailHistory, setEmployeeEmailHistory] = useState('')
    const [employeeEmailCorporateHistory, setEmployeeEmailCorporateHistory] = useState('')
    const [dateEmploymentExaminationHistory, setDateEmploymentExaminationHistory] = useState('')
    const [employeeVTHistory, setEmployeeVTHistory] = useState(false)
    const [foreignResidenceTimeHistory, setForeignResidenceTimeHistory] = useState('')
    const [foreignConditionHistory, setForeignConditionHistory] = useState('')
    const [foreignFinishResidenceHistory, setForeignFinishResidenceHistory] = useState('')
    const [foreignArrivalDateHistory, setForeignArrivalDateHistory] = useState('')
    const [foreignRegistryHistory, setForeignRegistryHistory] = useState('')
    const [foreignDateExpRegistryHistory, setForeignDateExpRegistryHistory] = useState('')
    const [foreignVisaValidityHistory, setForeignVisaValidityHistory] = useState('')
    const [foreignDateValidityCTPSHistory, setForeignDateValidityCTPSHistory] = useState('')
    const [foreignCasWithBrazilianHistory, setForeignCasWithBrazilianHistory] = useState('')
    const [foreignBrazilianChildrenHistory, setForeignBrazilianChildrenHistory] = useState('')

    //Dados dos dependentes
    const [fatherNameHistory, setFatherNameHistory] = useState('')
    const [motherNameHistory, setMotherNameHistory] = useState('')
    const [dependentsHistory, setDependentsHistory] = useState([])
    //#endregion

    useEffect(() => {
        if (!show){
            setErrorSubmit(false)
            setShowModalApply(false)
            setShowModalSave(false)
            setShowModalConfirmedSave(false)
            setShowModalFieldsRequired(false)
            setShowErrorEmployeeIdSenior(false)
        }
    }, [show])

    useEffect(() => {
        if (!showTableImportSenior){
            setFilter({ })
            setFilterConstructor({ })
            setVerifyFilter({ })
        }
    }, [showTableImportSenior])

    useEffect(() => {
        async function getDatas() {
            try {
                const getNationalities = await api.get('api/v1/geo/nationalities', {
                    headers: {
                        authorization: token
                    }
                })
                setNationalities(getNationalities.data.data)

                const getBanks = await api.get('api/v1/employees/banks', {
                    headers: {
                        authorization: token
                    }
                })
                setBanks(getBanks.data.data)

                const getCountries = await api.get('api/v1/geo/countries', {
                    headers: {
                        authorization: token
                    }
                })
                setCountries(getCountries.data.data)

                const getBrazilStates = await api.get(`api/v1/geo/states/country/1`, {
                    headers: {
                        authorization: token
                    }
                })
                setBrazilStates(getBrazilStates.data.data)
            } catch (error) {
                setLoading(false)
                return alert('Erro ao tentar realizar buscar de dados. Se o problema persistir entre em contato com o suporte.')
            }
        }
        getDatas()
    }, [token])

    useEffect(() => {
        if (addressCountry != 0) {
            async function getStates() {
                setInsideLoading(true)
                try {
                    const getState = await api.get(`api/v1/geo/states/country/${addressCountry}`, {
                        headers: {
                            authorization: token
                        }
                    })
                    setInsideLoading(false)
                    return setStates(getState.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Estados, se o problema persistir entre em contato com o suporte.')
                }
            }
            getStates()
        }
    }, [addressCountry])

    useEffect(() => {
        if (addressCountry != 0 && addressState != '' && addressState != 0) {
            async function getCities() {
                setInsideLoading(true)
                try {

                    const getCities = await api.get(`api/v1/geo/cities/country/${addressCountry}/state/${addressState}`, {
                        headers: {
                            authorization: token
                        }
                    })
                    setInsideLoading(false)
                    return setCities(getCities.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Cidades, se o problema persistir entre em contato com o suporte.')
                }
            }
            getCities()
        }
    }, [addressState])

    useEffect(() => {
        if (addressState != '' && addressState != 0 && addressCity != 0 && addressCity != '') {
            async function getDistricts() {
                setInsideLoading(true)
                try {

                    const getDistricts = await api.get(`api/v1/geo/districts/country/${addressCountry}/state/${addressState}/city/${addressCity}`, {
                        headers: {
                            authorization: token
                        }
                    })
                    setInsideLoading(false)
                    return setDistricts(getDistricts.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Cidades, se o problema persistir entre em contato com o suporte.')
                }
            }
            getDistricts()
        }
    }, [addressCity])

    useEffect(() => {
        if (nationality) {
            async function getStates() {
                try {
                    setInsideLoading(true)
                    const getNationalitiesState = await api.get(`api/v1/geo/states/nationality/${nationality}`, {
                        headers: {
                            authorization: token
                        }
                    })
                    setInsideLoading(false)
                    return setNationalitiesState(getNationalitiesState.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Estados, se o problema persistir entre em contato com o suporte.')
                }
            }
            getStates()
        }
    }, [nationality])

    useEffect(() => {
        if (nationality && employeeUf) {
            async function getStates() {
                try {
                    setInsideLoading(true)
                    const getNationalitiesStateCities = await api.get(`api/v1/geo/cities/nationality/${nationality}/state/${employeeUf}`, {
                        headers: {
                            authorization: token
                        }
                    })
                    setInsideLoading(false)
                    return setNationalitiesStateCities(getNationalitiesStateCities.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Cidades, se o problema persistir entre em contato com o suporte.')
                }
            }
            getStates()
        }
    }, [employeeUf])

    useEffect(() => {

        async function getPerson(){
            try {
                const getPersonsAPI = await api.get(`api/v1/persons/${company}`, {
                    headers: {
                        Authorization: token,
                    }
                })
                
                setPersons(getPersonsAPI.data?.data);

            } catch (error){
                setLoading(false)
                return alert('Erro ao realizar busca de colaboradores da requisição. Se o problema persistir, entre em contato com suporte.')
            }
        }

        async function getNaturesExpenses(){
            try {
                const getNaturesAPI = await api.get(`api/v1/general/natureExpenses`, {
                    headers: {
                        Authorization: token,
                    }
                })
                setNatureExpenseList(getNaturesAPI.data?.data)

            } catch (error){
                setLoading(false)
                return alert('Erro ao realizar busca de colaboradores da requisição. Se o problema persistir, entre em contato com suporte.')
            }
        }

        async function getOthersCompany() {
            try {
                const getOthersCompanyApi = await api.get(`api/v1/general/othersCompany`, {
                    headers: {
                        Authorization: token,
                    }
                })
                setOthersCompanyList(getOthersCompanyApi.data?.data)

            } catch (error){
                setLoading(false)
                return alert('Erro ao realizar busca de colaboradores da requisição. Se o problema persistir, entre em contato com suporte.')
            }
        }

        async function getEducationalInstitution(){
            try {
                const getInstitutionAPI = await api.get(`api/v1/employees/educationalInstitution`, {
                    headers: {
                        Authorization: token,
                    }
                })
                setEducationalInstitutionList(getInstitutionAPI?.data?.data)

            } catch (error){
                setLoading(false)
                return alert('Erro ao realizar busca de dados da requisição. Se o problema persistir, entre em contato com suporte.')
            }
        }

        async function getEmployeeData() {
            try {
                const getCompanyConfigAPI = await api.get(`api/v1/companies/getCompanyConfig/company/${company}?branch=${branch}`, {
                    headers: {
                        authorization: token
                    }
                })
                setCompanyConfig(getCompanyConfigAPI?.data?.data[0])

                const employeeDataApi = await api.get(`api/v1/employees/company/${company}/branch/${branch}/employee/${employeeId}?origin=2`, {
                    headers: {
                        'Authorization': `${token}`,
                    }
                })

                const stopoverTransportApi = await api.get('/api/v1/employees/stopoverTransport', {
                    headers: {
                        'Authorization': `${token}`,
                    }
                })
                setStopoverTransportList(stopoverTransportApi.data.data)

                const dependents = await api.get(`api/v1/employees/company/${company}/branch/${branch}/employee/${employeeId}/dependents?origin=2`, {
                    headers: {
                        authorization: token
                    }
                })
                                
                setDependents(dependents.data.data)
                setDependentsHistory(structuredClone(dependents.data.data))

                const ccuApi = await api.get(`api/v1/employees/ccu/${company}`, {
                    headers: {
                        'Authorization': `${token}`,
                    }
                })
                setCcu(ccuApi.data.data)

                const workplaceApi = await api.get(`api/v1/employees/workplace/${company}`, {
                    headers: {
                        'Authorization': `${token}`,
                    }
                })
                setWorkplaces(workplaceApi.data.data)                            

                {
                    setEmployeeName(employeeDataApi.data.data.employeeName)
                    setEmployeeNameHistory(employeeDataApi.data.data.employeeName)
                    setCompanyName(employeeDataApi.data.data.companyName)
                    setCompanyNameHistory(employeeDataApi.data.data.companyName)
                    setEmployeeVT(employeeDataApi.data.data.employeeVt)
                    setEmployeeVTHistory(employeeDataApi.data.data.employeeVt)
                    setBranchCNPJ(employeeDataApi.data.data.branchCNPJ)
                    setBranchCNPJHistory(employeeDataApi.data.data.branchCNPJ)
                    const admissionDateToFormat = employeeDataApi?.data?.data?.admissionDate?.split('/')
                    setAdmissionDate(admissionDateToFormat[2] + '-' + admissionDateToFormat[1] + '-' + admissionDateToFormat[0])
                    setAdmissionDateHistory(admissionDateToFormat[2] + '-' + admissionDateToFormat[1] + '-' + admissionDateToFormat[0])
                    setIsIntermittent(employeeDataApi.data.data.isIntermittent)
                    setIsIntermittentHistory(employeeDataApi.data.data.isIntermittent)
                    setTypeBusinessLink(!employeeDataApi.data.data.isIntermittent ? employeeDataApi.data.data.typeBusinessLink : 11)
                    setTypeBusinessLinkHistory(!employeeDataApi.data.data.isIntermittent ? employeeDataApi.data.data.typeBusinessLink : 11)
                    setTypeApuration(employeeDataApi.data.data.typeApuration)
                    setTypeApurationHistory(employeeDataApi.data.data.typeApuration)
                    setEmployeeLaborUnion(employeeDataApi.data.data.employeeLaborUnion)
                    setEmployeeLaborUnionHistory(employeeDataApi.data.data.employeeLaborUnion)
                    setLaborUnion(!employeeDataApi.data.data.laborUnion ? '' : employeeDataApi.data.data.laborUnion)
                    setLaborUnionHistoty(!employeeDataApi.data.data.laborUnion ? '' : employeeDataApi.data.data.laborUnion)
                    setEmployeePosition(employeeDataApi.data.data.employeePosition)
                    setEmployeePositionHistory(employeeDataApi.data.data.employeePosition)
                    setEmployeeScheduleType(employeeDataApi.data.data.employeeScheduleType)
                    setEmployeeScheduleTypeHistory(employeeDataApi.data.data.employeeScheduleType)
                    setEmployeeScheduleMonday(employeeDataApi.data.data.employeeScheduleMonday)
                    setEmployeeScheduleMondayHistory(employeeDataApi.data.data.employeeScheduleMonday)
                    setEmployeeScheduleTuesday(employeeDataApi.data.data.employeeScheduleTuesday)
                    setEmployeeScheduleTuesdayHistory(employeeDataApi.data.data.employeeScheduleTuesday)
                    setEmployeeScheduleWednesday(employeeDataApi.data.data.employeeScheduleWednesday)
                    setEmployeeScheduleWednesdayHistory(employeeDataApi.data.data.employeeScheduleWednesday)
                    setEmployeeScheduleThursday(employeeDataApi.data.data.employeeScheduleThursday)
                    setEmployeeScheduleThursdayHistory(employeeDataApi.data.data.employeeScheduleThursday)
                    setEmployeeScheduleFriday(employeeDataApi.data.data.employeeScheduleFriday)
                    setEmployeeScheduleFridayHistory(employeeDataApi.data.data.employeeScheduleFriday)
                    setEmployeeScheduleSaturday(employeeDataApi.data.data.employeeScheduleSaturday)
                    setEmployeeScheduleSaturdayHistory(employeeDataApi.data.data.employeeScheduleSaturday)
                    setEmployeeScheduleSunday(employeeDataApi.data.data.employeeScheduleSunday)
                    setEmployeeScheduleSundayHistory(employeeDataApi.data.data.employeeScheduleSunday)
                    setEmployeeSalary(employeeDataApi.data.data.employeeSalary)
                    setEmployeeSalaryHistory(employeeDataApi.data.data.employeeSalary)
                    setTypeSalary(employeeDataApi.data.data.typeSalary)
                    setTypeSalaryHistory(employeeDataApi.data.data.typeSalary)
                    setEmployeeLocal(employeeDataApi.data?.data?.employeeLocalDescription)
                    setEmployeeLocalHistory(employeeDataApi.data?.data?.employeeLocalDescription)
                    setEmployeeCCU(!employeeDataApi.data.data.employeeCCU ? '' : employeeDataApi.data.data.employeeCCU)
                    setEmployeeCCUHistory(!employeeDataApi.data.data.employeeCCU ? '' : employeeDataApi.data.data.employeeCCU)
                    setEmployeeWorplace(!employeeDataApi.data.data.employeeWorkplace ? '' : employeeDataApi.data.data.employeeWorkplace)
                    setEmployeeWorplaceHistory(!employeeDataApi.data.data.employeeWorplace ? '' : employeeDataApi.data.data.employeeWorplace)
                    setEmployeeValueVT(employeeDataApi.data.data.employeeValueVT)
                    setEmployeeValueVTHistory(employeeDataApi.data.data.employeeValueVT)
                    setEmployeeVT(employeeDataApi.data.data.employeeVT)
                    setEmployeeVTHistory(employeeDataApi.data.data.employeeVT)
                    setAdvanceSalary(employeeDataApi.data.data.advanceSalary)
                    setAdvanceSalaryHistory(employeeDataApi.data.data.advanceSalary)
                    setAdvanceSalaryPorcentage(employeeDataApi.data.data.advanceSalaryPorcentage)
                    setAdvanceSalaryPorcentageHistory(employeeDataApi.data.data.advanceSalaryPorcentage)
                    setPaydaySalary(employeeDataApi.data.data.paydaySalary)
                    setPaydaySalaryHistory(employeeDataApi.data.data.paydaySalary)
                    setEmployeeExperience(employeeDataApi.data.data.employeeExperience)
                    setEmployeeExperienceHistory(employeeDataApi.data.data.employeeExperience)
                    setLifeInsurance(employeeDataApi.data.data.lifeInsurance)
                    setLifeInsuranceHistory(employeeDataApi.data.data.lifeInsurance)
                    setInsalubrity(employeeDataApi.data.data.insalubrity)
                    setInsalubrityHistory(employeeDataApi.data.data.insalubrity)
                    setPericulosity(employeeDataApi.data.data.periculosity)
                    setPericulosityHistory(employeeDataApi.data.data.periculosity)
                    setPaymentPeriod(employeeDataApi.data.data.paymentPeriod)
                    setPaymentPeriodHistory(employeeDataApi.data.data.paymentPeriod)
                    setPaymentMethod(employeeDataApi.data.data.paymentMethod)
                    setPaymentMethodHistory(employeeDataApi.data.data.paymentMethod)
                    setEmployeBank(!employeeDataApi.data.data.employeeBank ? null : employeeDataApi.data.data.employeeBank)
                    setEmployeBankHistory(!employeeDataApi.data.data.employeeBank ? null : employeeDataApi.data.data.employeeBank)
                    setEmployeBankName(!employeeDataApi.data.data.employeeBankName ? null : employeeDataApi.data.data.employeeBankName)
                    setEmployeBankNameHistory(!employeeDataApi.data.data.employeeBankName ? null : employeeDataApi.data.data.employeeBankName)
                    setEmployeBankAgencie(!employeeDataApi.data.data.employeBankAgencie ? null : employeeDataApi.data.data.employeBankAgencie)
                    setEmployeBankAgencieHistory(!employeeDataApi.data.data.employeBankAgencie ? null : employeeDataApi.data.data.employeBankAgencie)
                    setEmployeeBankAccountType(!employeeDataApi.data.data.employeeBankAccountType ? null : employeeDataApi.data.data.employeeBankAccountType)
                    setEmployeeBankAccountTypeHistory(!employeeDataApi.data.data.employeeBankAccountType ? null : employeeDataApi.data.data.employeeBankAccountType)
                    setEmployeeBankAccount(!employeeDataApi.data.data.employeeBankAccount ? null : employeeDataApi.data.data.employeeBankAccount)
                    setEmployeeBankAccountHistory(!employeeDataApi.data.data.employeeBankAccount ? null : employeeDataApi.data.data.employeeBankAccount)
                    setEmployeeBankAccountDigit(!employeeDataApi.data.data.employeeBankAccountDigit ? null : employeeDataApi.data.data.employeeBankAccountDigit)
                    setEmployeeBankAccountDigitHistory(!employeeDataApi.data.data.employeeBankAccountDigit ? null : employeeDataApi.data.data.employeeBankAccountDigit)
                    setEmployeeSexualGender(employeeDataApi.data.data.employeeSexualGender)
                    setEmployeeSexualGenderHistory(employeeDataApi.data.data.employeeSexualGender)
                    setCivilStatus(employeeDataApi.data.data.civilStatus)
                    setCivilStatusHistory(employeeDataApi.data.data.civilStatus)
                    setLevelOfEducation(employeeDataApi.data.data.levelOfEducation)
                    setLevelOfEducationHistory(employeeDataApi.data.data.levelOfEducation)
                    const birthdayToFormat = employeeDataApi.data.data.birthday.split('/')
                    setBirthday(birthdayToFormat[2] + '-' + birthdayToFormat[1] + '-' + birthdayToFormat[0])
                    setBirthdayHistory(birthdayToFormat[2] + '-' + birthdayToFormat[1] + '-' + birthdayToFormat[0])
                    setNationalityDescription(employeeDataApi.data.data.nationalityDescription)
                    setNationalityDescriptionHistory(employeeDataApi.data.data.nationalityDescription)
                    setNationality(employeeDataApi.data.data.nationality)
                    setNationalityHistory(employeeDataApi.data.data.nationality)
                    setEmployeeUf(employeeDataApi?.data?.data?.employeeUf?.toUpperCase())
                    setEmployeeUfHistory(employeeDataApi?.data?.data?.employeeUf?.toUpperCase())
                    setEmployeeCity(employeeDataApi?.data?.data?.employeeCity)
                    setEmployeeCityHistory(employeeDataApi?.data?.data?.employeeCity)
                    setWorkCard(employeeDataApi.data.data.workCard)
                    setWorkCardHistory(employeeDataApi.data.data.workCard)
                    setWorkCardSerie(employeeDataApi.data.data.workCardSerie)
                    setWorkCardSerieHistory(employeeDataApi.data.data.workCardSerie)
                    const workCardEmissionToFormat = !employeeDataApi?.data?.data?.workCardEmission ? undefined : employeeDataApi?.data?.data?.workCardEmission?.split('/')
                    setWorkCardEmission(!workCardEmissionToFormat ? '' : workCardEmissionToFormat[2] + '-' + workCardEmissionToFormat[1] + '-' + workCardEmissionToFormat[0])
                    setWorkCardEmissionHistory(!workCardEmissionToFormat ? '' : workCardEmissionToFormat[2] + '-' + workCardEmissionToFormat[1] + '-' + workCardEmissionToFormat[0])
                    setWorkCardUf(employeeDataApi.data.data.workCardUf)
                    setWorkCardUfHistory(employeeDataApi.data.data.workCardUf)
                    setCpf(employeeDataApi.data.data.cpf)
                    setCpfHistory(employeeDataApi.data.data.cpf)
                    setFirstJob(employeeDataApi.data.data.firstJob)
                    setFirstJobHistory(employeeDataApi.data.data.firstJob)
                    setPis(!employeeDataApi.data.data.pis ? '' : employeeDataApi.data.data.pis)
                    setPisHistory(!employeeDataApi.data.data.pis ? '' : employeeDataApi.data.data.pis)
                    const emissionPisToFormat = !employeeDataApi?.data?.data?.emissionPis ? undefined : employeeDataApi?.data?.data?.emissionPis?.split('/')
                    setEmissionPis(!employeeDataApi?.data?.data?.emissionPis ? '' : emissionPisToFormat[2] + '-' + emissionPisToFormat[1] + '-' + emissionPisToFormat[0])
                    setEmissionPisHistory(!employeeDataApi?.data?.data?.emissionPis ? '' : emissionPisToFormat[2] + '-' + emissionPisToFormat[1] + '-' + emissionPisToFormat[0])
                    setRg(!employeeDataApi.data.data.rg ? '' : employeeDataApi.data.data.rg)
                    setRgHistory(!employeeDataApi.data.data.rg ? '' : employeeDataApi.data.data.rg)
                    setSendingAgencyRg(!employeeDataApi.data.data.sendingAgencyRG ? '' : employeeDataApi.data.data.sendingAgencyRG)
                    setSendingAgencyRgHistory(!employeeDataApi.data.data.sendingAgencyRG ? '' : employeeDataApi.data.data.sendingAgencyRG)
                    const emissionRgToFormat = employeeDataApi?.data?.data?.emissionRG !== undefined ? employeeDataApi?.data?.data?.emissionRG?.split('/') : undefined
                    setEmissionRg(!emissionRgToFormat ? '' : emissionRgToFormat[2] + '-' + emissionRgToFormat[1] + '-' + emissionRgToFormat[0])
                    setEmissionRgHistory(!emissionRgToFormat ? '' : emissionRgToFormat[2] + '-' + emissionRgToFormat[1] + '-' + emissionRgToFormat[0])
                    setRgUf(!employeeDataApi.data.data.rgUf ? '' : employeeDataApi.data.data.rgUf)
                    setRgUfHistory(!employeeDataApi.data.data.rgUf ? '' : employeeDataApi.data.data.rgUf)
                    setVoterRegistration(!employeeDataApi.data.data.voterRegistration ? '' : employeeDataApi.data.data.voterRegistration)
                    setVoterRegistrationHistory(!employeeDataApi.data.data.voterRegistration ? '' : employeeDataApi.data.data.voterRegistration)
                    setVoterRegistrationZone(!employeeDataApi.data.data.voterRegistrationZone ? '' : employeeDataApi.data.data.voterRegistrationZone)
                    setVoterRegistrationZoneHistory(!employeeDataApi.data.data.voterRegistrationZone ? '' : employeeDataApi.data.data.voterRegistrationZone)
                    setVoterRegistrationSection(employeeDataApi.data.data.voterRegistrationSection !== undefined ? employeeDataApi.data.data.voterRegistrationSection : '')
                    setVoterRegistrationSectionHistory(employeeDataApi.data.data.voterRegistrationSection !== undefined ? employeeDataApi.data.data.voterRegistrationSection : '')
                    setCnh(!employeeDataApi.data.data.cnh ? '' : employeeDataApi.data.data.cnh)
                    setCnhHistory(!employeeDataApi.data.data.cnh ? '' : employeeDataApi.data.data.cnh)
                    setCnhIssuer(!employeeDataApi.data.data.cnhIssues ? '' : employeeDataApi.data.data.cnhIssues)
                    setCnhIssuerHistory(!employeeDataApi.data.data.cnhIssues ? '' : employeeDataApi.data.data.cnhIssues)
                    setCnhCategory(!employeeDataApi.data.data.cnhCategory ? '' : employeeDataApi.data.data.cnhCategory)
                    setCnhCategoryHistory(!employeeDataApi.data.data.cnhCategory ? '' : employeeDataApi.data.data.cnhCategory)
                    const cnhValidityToFormat = !employeeDataApi?.data?.data?.cnhValidity ? undefined : employeeDataApi?.data?.data?.cnhValidity?.split('/')
                    setCnhValidity(!cnhValidityToFormat ? '' : cnhValidityToFormat[2] + '-' + cnhValidityToFormat[1] + '-' + cnhValidityToFormat[0])
                    setCnhValidityHistory(!cnhValidityToFormat ? '' : cnhValidityToFormat[2] + '-' + cnhValidityToFormat[1] + '-' + cnhValidityToFormat[0])                    
                    setCnhUf(!employeeDataApi.data.data.cnhUf ? '' : employeeDataApi.data.data.cnhUf)
                    setCnhUfHistory(!employeeDataApi.data.data.cnhUf ? '' : employeeDataApi.data.data.cnhUf)
                    const cnhFirstDateToFormat = !employeeDataApi?.data?.data?.cnhFirstDate ? undefined : employeeDataApi?.data?.data?.cnhFirstDate?.split('/')
                    setCnhFirstDate(!cnhFirstDateToFormat ? '' : cnhFirstDateToFormat[2] + '-' + cnhFirstDateToFormat[1] + '-' + cnhFirstDateToFormat[0])
                    setCnhFirstDateHistory(!cnhFirstDateToFormat ? '' : cnhFirstDateToFormat[2] + '-' + cnhFirstDateToFormat[1] + '-' + cnhFirstDateToFormat[0])
                    const cnhDispatchDateToFormat = !employeeDataApi?.data?.data?.cnhDispatchDate ? undefined : employeeDataApi?.data?.data?.cnhDispatchDate?.split('/')
                    setCnhDispatchDate(!cnhDispatchDateToFormat ? '' : cnhDispatchDateToFormat[2] + '-' + cnhDispatchDateToFormat[1] + '-' + cnhDispatchDateToFormat[0])
                    setCnhDispatchDateHistory(!cnhDispatchDateToFormat ? '' : cnhDispatchDateToFormat[2] + '-' + cnhDispatchDateToFormat[1] + '-' + cnhDispatchDateToFormat[0])
                    setMilitaryServiceCard(!employeeDataApi.data.data.militaryServiceCard ? '' : employeeDataApi.data.data.militaryServiceCard)
                    setMilitaryServiceCardHistory(!employeeDataApi.data.data.militaryServiceCard ? '' : employeeDataApi.data.data.militaryServiceCard)
                    setMilitaryServiceCardUf(!employeeDataApi.data.data.militaryServiceCardUf ? '' : employeeDataApi.data.data.militaryServiceCardUf)
                    setMilitaryServiceCardUfHistory(!employeeDataApi.data.data.militaryServiceCardUf ? '' : employeeDataApi.data.data.militaryServiceCardUf)
                    setDeficiency(employeeDataApi.data.data.deficiency)
                    setDeficiencyHistory(employeeDataApi.data.data.deficiency)
                    setDeficiencyType(employeeDataApi.data.data.deficiencyType)
                    setDeficiencyTypeHistory(employeeDataApi.data.data.deficiencyType)
                    setDeficiencyRehabilitatedInss(employeeDataApi.data.data.deficiencyRehabilitatedInss)
                    setDeficiencyRehabilitatedInssHistory(employeeDataApi.data.data.deficiencyRehabilitatedInss)
                    setDeficiencyPcdQuote(employeeDataApi.data.data.deficiencyPcdQuote)
                    setDeficiencyPcdQuoteHistory(employeeDataApi.data.data.deficiencyPcdQuote)
                    setEmployeeRace(employeeDataApi.data.data.employeeRace)
                    setEmployeeRaceHistory(employeeDataApi.data.data.employeeRace)
                    setAddressCountry(employeeDataApi.data.data.addressCountry)
                    setAddressCountryHistory(employeeDataApi.data.data.addressCountry)
                    setAddressCountryName(employeeDataApi.data.data.addressCountryName)
                    setAddressCountryNameHistory(employeeDataApi.data.data.addressCountryName)
                    setAddressCep(employeeDataApi.data.data.addressCep)
                    setAddressCepHistory(employeeDataApi.data.data.addressCep)
                    setAddressState(employeeDataApi.data.data.addressState)
                    setAddressStateHistory(employeeDataApi.data.data.addressState)
                    setAddressCity(employeeDataApi.data.data.addressCity)
                    setAddressCityHistory(employeeDataApi.data.data.addressCity)
                    setAddressCityName(employeeDataApi.data.data.addressCityName)
                    setAddressCityNameHistory(employeeDataApi.data.data.addressCityName)
                    setAddressDistrict(employeeDataApi.data.data.addressDistrict)
                    setAddressDistrictHistory(employeeDataApi.data.data.addressDistrict)
                    setAddressDistrictName(employeeDataApi.data.data.addressDistrictName)
                    setAddressDistrictNameHistory(employeeDataApi.data.data.addressDistrictName)
                    setAddressRoad(employeeDataApi.data.data.addressRoad)
                    setAddressRoadHistory(employeeDataApi.data.data.addressRoad)
                    setAddressType(employeeDataApi.data.data.addressType)
                    setAddressTypeHistory(employeeDataApi.data.data.addressType)
                    setAddressNumber(employeeDataApi.data.data.addressNumber)
                    setAddressNumberHistory(employeeDataApi.data.data.addressNumber)
                    setAddressComplement(employeeDataApi.data.data.addressComplement)
                    setAddressComplementHistory(employeeDataApi.data.data.addressComplement)
                    setPhoneDdd(!employeeDataApi.data.data.phoneDDD ? '' : employeeDataApi.data.data.phoneDDD)
                    setPhoneDddHistory(!employeeDataApi.data.data.phoneDDD ? '' : employeeDataApi.data.data.phoneDDD)
                    setPhoneNumber(!employeeDataApi.data.data.phoneNumber ? '' : employeeDataApi.data.data.phoneNumber)
                    setPhoneNumberHistory(!employeeDataApi.data.data.phoneNumber ? '' : employeeDataApi.data.data.phoneNumber)
                    setEmployeeEmail(!employeeDataApi.data.data.employeeEmail ? '' : employeeDataApi.data.data.employeeEmail)
                    setEmployeeEmailHistory(!employeeDataApi.data.data.employeeEmail ? '' : employeeDataApi.data.data.employeeEmail)
                    setEmployeeEmailCorporate(!employeeDataApi.data.data.employeeEmailCorporate ? '' : employeeDataApi.data.data.employeeEmailCorporate)
                    setEmployeeEmailCorporateHistory(!employeeDataApi.data.data.employeeEmailCorporate ? '' : employeeDataApi.data.data.employeeEmailCorporate)
                    setFatherName(!employeeDataApi.data.data.fatherName ? '' : employeeDataApi.data.data.fatherName)
                    setFatherNameHistory(!employeeDataApi.data.data.fatherName ? '' : employeeDataApi.data.data.fatherName)
                    setMotherName(employeeDataApi.data.data.motherName)
                    setMotherNameHistory(employeeDataApi.data.data.motherName)
                    setObservation(employeeDataApi.data.data.observation)
                    setObservationHistory(employeeDataApi.data.data.observation)
                    setTraineeInsurancePolicy(employeeDataApi.data.data?.traineeInsurancePolicy)
                    setTraineeInsurancePolicyHistory(employeeDataApi.data.data?.traineeInsurancePolicy)
                    setTraineeEndForecast(employeeDataApi.data.data?.traineeEndForecast ? employeeDataApi.data.data?.traineeEndForecast.split('T')[0] : '')
                    setTraineeEndForecastHistory(employeeDataApi.data.data?.traineeEndForecast ? employeeDataApi.data.data?.traineeEndForecast.split('T')[0] : '')
                    setTraineeEducationalInstitution(employeeDataApi.data.data?.traineeEducationalInstitution)
                    setTraineeEducationalInstitutionHistory(employeeDataApi.data.data?.traineeEducationalInstitution)
                    setTraineeCodSupervisor(employeeDataApi.data.data?.traineeCodSupervisor)
                    setTraineeCodSupervisorHistory(employeeDataApi.data.data?.traineeCodSupervisor)
                    setTraineeOrigin(employeeDataApi.data.data?.traineeOrigin)
                    setTraineeOriginHistory(employeeDataApi.data.data?.traineeOrigin)
                    setTraineeEducationLevel(employeeDataApi.data.data?.traineeEducationLevel)
                    setTraineeEducationLevelHistory(employeeDataApi.data.data?.traineeEducationLevel)
                    setForeignResidenceTime(employeeDataApi.data.data?.foreignResidenceTime)
                    setForeignResidenceTimeHistory(employeeDataApi.data.data?.foreignResidenceTime)
                    setForeignCondition(employeeDataApi.data.data?.foreignCondition)
                    setForeignConditionHistory(employeeDataApi.data.data?.foreignCondition)
                    setForeignFinishResidence(employeeDataApi.data.data?.foreignFinishResidence?.split('T')[0])
                    setForeignFinishResidenceHistory(employeeDataApi.data.data?.foreignFinishResidence?.split('T')[0])
                    setForeignArrivalDate(employeeDataApi.data.data?.foreignArrivalDate?.split('T')[0])
                    setForeignArrivalDateHistory(employeeDataApi.data.data?.foreignArrivalDate?.split('T')[0])
                    setForeignRegistry(employeeDataApi.data.data?.foreignRegistry)
                    setForeignRegistryHistory(employeeDataApi.data.data?.foreignRegistry)
                    setForeignDateExpRegistry(employeeDataApi.data.data?.foreignDateExpRegistry?.split('T')[0])
                    setForeignDateExpRegistryHistory(employeeDataApi.data.data?.foreignDateExpRegistry?.split('T')[0])
                    setForeignVisaValidity(employeeDataApi.data.data?.foreignVisaValidity?.split('T')[0])
                    setForeignVisaValidityHistory(employeeDataApi.data.data?.foreignVisaValidity?.split('T')[0])
                    setForeignDateValidityCTPS(employeeDataApi.data.data?.foreignDateValidityCTPS?.split('T')[0])
                    setForeignDateValidityCTPSHistory(employeeDataApi.data.data?.foreignDateValidityCTPS?.split('T')[0])
                    setForeignCasWithBrazilian(employeeDataApi.data.data?.foreignCasWithBrazilian)
                    setForeignCasWithBrazilianHistory(employeeDataApi.data.data?.foreignCasWithBrazilian)
                    setForeignBrazilianChildren(employeeDataApi.data.data?.foreignBrazilianChildren)
                    setForeignBrazilianChildrenHistory(employeeDataApi.data.data?.foreignBrazilianChildren)
                    setAdvanceMonthAdmission(employeeDataApi.data.data?.advanceMonthAdmission)
                    setAdvanceMonthAdmissionHistory(employeeDataApi.data.data?.advanceMonthAdmission)
                    setNatureExpense(employeeDataApi.data.data?.natureExpenses)
                    setNatureExpenseHistory(employeeDataApi.data.data?.natureExpenses)
                    setInsalubrityPercentage(employeeDataApi.data.data?.insalubrityPercentage)
                    setInsalubrityPercentageHistory(employeeDataApi.data.data?.insalubrityPercentage)
                    setPericulosityPercentage(employeeDataApi.data.data?.periculosityPercentage)
                    setPericulosityPercentageHistory(employeeDataApi.data.data?.periculosityPercentage)
                    setNickname(employeeDataApi.data.data?.nickname)
                    setNicknameHistory(employeeDataApi.data.data?.nickname)
                    setDigitAgency(employeeDataApi.data.data?.digitAgency)
                    setDigitAgencyHistory(employeeDataApi.data.data?.digitAgency)
                    setApprenticeInstitutionName(employeeDataApi.data.data?.apprenticeInstitutionName)
                    setApprenticeInstitutionNameHistory(employeeDataApi.data.data?.apprenticeInstitutionName)
                    setApprenticeInstitutionCNPJ(employeeDataApi.data.data?.apprenticeInstitutionCNPJ)
                    setApprenticeInstitutionCNPJHistory(employeeDataApi.data.data?.apprenticeInstitutionCNPJ)
                    setApprenticeDateFinish(employeeDataApi.data.data?.apprenticeDateFinish?.split('T')[0])
                    setApprenticeDateFinishHistory(employeeDataApi.data.data?.apprenticeDateFinish?.split('T')[0])
                    setGratificationFunction(employeeDataApi.data.data?.gratificationFunction)
                    setGratificationFunctionHistory(employeeDataApi.data.data?.gratificationFunction)
                }
                setLoading(false)

            } catch (error) {
                setLoading(false)
                return alert('Erro ao realizar busca de dados da requisição. Se o problema persistir, entre em contato com suporte.')
            }
        }

        getPerson()
        getNaturesExpenses()
        getEducationalInstitution()
        getOthersCompany()
        getEmployeeData()
    }, [token])

    useEffect(() => {
        if (scheduleSenior != null && scheduleSenior != 0) {
            async function getTeams() {
                setInsideLoading(true)
                try {
                    const schedulesApi = await api.get(`api/v1/employees/schedules/${scheduleSenior}/teams`, {
                        headers: {
                            'Authorization': `${token}`,
                        }
                    })
                    setSchedulesTeams(schedulesApi.data.data)
                    return setInsideLoading(false)
                } catch (error) {
                    setInsideLoading(false)
                    return alert('Erro ao buscar times de escala')
                }
            }

            getTeams()
        }
    }, [scheduleSenior])

    function changeAccount() {

        setEmployeeBankAccount('')
        setEmployeeBankAccountDigit('')
    }

    async function goBackHistory() {
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 50))

        setEmployeeName(employeeNameHistory)
        setCompanyName(companyNameHistory)
        setEmployeeVT(employeeVTHistory)
        setBranchCNPJ(branchCNPJHistory)
        setAdmissionDate(admissionDateHistory)
        setTypeBusinessLink(typeBusinessLinkHistory)
        setIsIntermittent(isIntermittentHistory)
        setTypeApuration(typeApurationHistory)
        setEmployeeLaborUnion(employeeLaborUnionHistory)
        setLaborUnion(laborUnionHistoty)
        setEmployeePosition(employeePositionHistory)
        setEmployeeScheduleType(employeeScheduleTypeHistory)
        setEmployeeScheduleMonday(employeeScheduleMondayHistory)
        setEmployeeScheduleTuesday(employeeScheduleTuesdayHistory)
        setEmployeeScheduleWednesday(employeeScheduleWednesdayHistory)
        setEmployeeScheduleThursday(employeeScheduleThursdayHistory)
        setEmployeeScheduleFriday(employeeScheduleFridayHistory)
        setEmployeeScheduleSaturday(employeeScheduleSaturdayHistory)
        setEmployeeScheduleSunday(employeeScheduleSunday)
        setEmployeeSalary(employeeSalaryHistory)
        setTypeSalary(typeSalaryHistory)
        setObservation(observationHistory)
        setEmployeeLocal(employeeLocalHistory)
        setEmployeeCCU(employeeCCUHistory)
        setEmployeeWorplace(employeeWorkplaceHistory)
        setEmployeeValueVT(employeeValueVTHistory)
        setEmployeeVT(employeeVTHistory)
        setAdvanceSalary(advanceSalaryHistory)
        setAdvanceSalaryPorcentage(advanceSalaryPorcentageHistory)
        setPaydaySalary(paydaySalaryHistory)
        setEmployeeExperience(employeeExperienceHistory)
        setLifeInsurance(lifeInsuranceHistory)
        setInsalubrity(insalubrityHistory)
        setPericulosity(periculosityHistory)
        setPaymentPeriod(paymentPeriodHistory)
        setPaymentMethod(paymentMethodHistory)
        setEmployeBank(employeeBankHistory)
        setEmployeBankName(employeeBankNameHistory)
        setEmployeBankAgencie(employeBankAgencieHistory)
        setEmployeeBankAccountType(employeeBankAccountTypeHistory)
        setEmployeeBankAccount(employeeBankAccountHistory)
        setEmployeeBankAccountDigit(employeeBankAccountDigitHistory)
        setEmployeeSexualGender(employeeSexualGenderHistory)
        setCivilStatus(civilStatusHistory)
        setLevelOfEducation(levelOfEducationHistory)
        setBirthday(birthdayHistory)
        setNationalityDescription(nationalityDescriptionHistory)
        setNationality(nationalityHistory)
        setEmployeeUf(employeeUfHistory)
        setEmployeeCity(employeeCityHistory)
        setWorkCard(workCardHistory)
        setWorkCardSerie(workCardSerieHistory)
        setWorkCardEmission(workCardEmissionHistory)
        setWorkCardUf(workCardUfHistory)
        setCpf(cpfHistory)
        setFirstJob(firstJobHistory)
        setPis(pisHistory)
        setEmissionPis(emissionPisHistory)
        setRg(rgHistory)
        setSendingAgencyRg(sendingAgencyRGHistory)
        setEmissionRg(EmissionRGHistory)
        setRgUf(rgUfHistory)
        setVoterRegistration(voterRegistrationHistory)
        setVoterRegistrationZone(voterRegistrationZoneHistory)
        setVoterRegistrationSection(voterRegistrationSectionHistory)
        setCnh(cnhHistory)
        setCnhIssuer(cnhIssuerHistory)
        setCnhCategory(cnhHistory)
        setCnhValidity(cnhValidityHistory)
        setCnhUf(cnhUfHistory)
        setCnhFirstDate(cnhFirstDateHistory)
        setCnhDispatchDate(cnhDispatchDateHistory)
        setMilitaryServiceCard(militaryServiceCardHistory)
        setMilitaryServiceCardUf(militaryServiceCardUfHistory)
        setDeficiency(deficiencyHistory)
        setDeficiencyType(deficiencyType)
        setDeficiencyRehabilitatedInss(deficiencyRehabilitatedInssHistory)
        setDeficiencyPcdQuote(deficiencyPcdQuoteHistory)
        setEmployeeRace(employeeRaceHistory)
        setAddressCountry(addressCountryHistory)
        setAddressCountryName(addressCountryNameHistory)
        setAddressCep(addressCepHistory)
        setAddressState(addressStateHistory)
        setAddressCity(addressCityHistory)
        setAddressCityName(addressCityNameHistory)
        setAddressDistrict(addressDistrictHistory)
        setAddressDistrictName(addressDistrictNameHistory)
        setAddressRoad(addressRoadHistory)
        setAddressType(addressTypeHistory)
        setAddressNumber(addressNumberHistory)
        setAddressComplement(addressComplementHistory)
        setPhoneDdd(phoneDddHistory)
        setPhoneNumber(phoneNumberHistory)
        setEmployeeEmail(employeeEmailHistory)
        setEmployeeEmailCorporate(employeeEmailCorporateHistory)
        setFatherName(fatherNameHistory)
        setMotherName(motherNameHistory)
        setObservation(observation)
        setTraineeInsurancePolicy(traineeInsurancePolicyHistory)
        setTraineeEndForecast(traineeEndForecastHistory)
        setTraineeEducationalInstitution(traineeEducationalInstitutionHistory)
        setTraineeCodSupervisor(traineeCodSupervisorHistory)
        setTraineeOrigin(traineeOriginHistory)
        setTraineeEducationLevel(traineeEducationLevelHistory)
        setForeignResidenceTime(foreignResidenceTimeHistory)
        setForeignCondition(foreignConditionHistory)
        setForeignFinishResidence(foreignFinishResidenceHistory)
        setForeignArrivalDate(foreignArrivalDateHistory)
        setForeignRegistry(foreignRegistryHistory)
        setForeignDateExpRegistry(foreignDateExpRegistryHistory)
        setForeignVisaValidity(foreignVisaValidityHistory)
        setForeignDateValidityCTPS(foreignDateValidityCTPSHistory)
        setForeignCasWithBrazilian(foreignCasWithBrazilianHistory)
        setForeignBrazilianChildren(foreignBrazilianChildrenHistory)
        setAdvanceMonthAdmission(advanceMonthAdmissionHistory)
        setNatureExpense(natureExpenseHistory)
        setInsalubrityPercentage(insalubrityPercentageHistory)
        setPericulosityPercentage(periculosityPercentageHistory)
        setNickname(nicknameHistory)
        setDigitAgency(digitAgencyHistory)
        setApprenticeInstitutionName(apprenticeInstitutionNameHistory)
        setApprenticeInstitutionCNPJ(apprenticeInstitutionCNPJHistory)
        setApprenticeDateFinish(apprenticeDateFinishHistory)
        setGratificationFunction(gratificationFunctionHistory)
        setDependents(structuredClone(dependentsHistory))

        setLoading(false)
    }

    function contractDataForm() {

        return (
            <div id='partForm' className='FormRequestContainer overflow-auto xl:h-full h-[65vh] lg:pb-8 2xl:pb-0 top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                {
                    selectedForm == 4 ?
                        <></>
                        :
                        <div className="formDescription">
                            <p className="obsFormRequestAdmission"> Todos os campos com (*) são obrigatórios</p>
                        </div>
                }
                {
                    insideLoading ?
                        (
                            <div className='insideLoadingForm'>
                                <div>
                                    <Loader />
                                </div>
                            </div>
                        ) : <></>
                }

                <div className='hidden lg:flex lg:flex-col'>
                    <label style={{ color: missingRequiredFields['companyName'] && companyName == '' ? 'red' : '#464545' }} htmlFor='companyName'>Empresa *</label>
                    <input style={{ border: missingRequiredFields['companyName'] && companyName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='companyName' value={companyName} onChange={(e) => setCompanyName(e.target.value)} className="bigInput" disabled={true} />
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor='branchCNPJ'>Filial *</label>
                    <input type="text" id='branchCNPJ' value={branchCNPJ} onChange={(e) => setBranchCNPJ(e.target.value)} className="bigInput" disabled={true} />
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor='employeeName'>Nome do Funcionário *</label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeeName' value={employeeName} onChange={(e) => setEmployeeName(e.target.value)} className="bigInput" disabled={inEdit ? false : true} maxLength={255}/>
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['companyName'] && companyName == '' ? 'red' : '#464545' }} htmlFor='companyName'>Empresa *</label>
                    <input style={{ border: missingRequiredFields['companyName'] && companyName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='companyName' value={companyName} onChange={(e) => setCompanyName(e.target.value)} disabled={true} />
                </div>
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor='branchCNPJ'>Filial *</label>
                    <input type="text" id='branchCNPJ' value={branchCNPJ} onChange={(e) => setBranchCNPJ(e.target.value)} disabled={true} />
                </div>
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor='employeeName'>Nome do Funcionário *</label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeeName' value={employeeName} onChange={(e) => setEmployeeName(e.target.value)} disabled={inEdit ? false : true} maxLength={255}/>
                </div>
                <div>
                    <label style={{ color: ((missingRequiredFields['admissionDate'] && admissionDate == '') || missingRequiredFields['admissionDate'] && invalidAdmissionDate) ? 'red' : '#464545' }} htmlFor="admissionDate">{invalidAdmissionDate ? 'Data é menor do que a atual' : "Data Admissão *"}</label>
                    <input style={{ border: ((missingRequiredFields['admissionDate'] && admissionDate == '') || missingRequiredFields['admissionDate'] && invalidAdmissionDate) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='admissionDate' value={admissionDate} onChange={(e) => { setAdmissionDate(e.target.value); }} className="smallInput" disabled={inEdit ? false : true} required />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['typeBusinessLink'] && typeBusinessLink == '' ? 'red' : '#464545' }} htmlFor="typeBusinessLink">Tipo Vínculo *</label>
                    <select 
                        id="typeBusinessLink" 
                        value={typeBusinessLink} 
                        disabled={inEdit ? false : true}
                        onChange={(e) => {
                            // Verifica se o coloborador é intermitente e corrige para o código certo, pois o código CLT é o mesmo que intermitente.
                            if (e.target.value == '11'){
                                setTypeBusinessLink(parseInt(e.target.value))
                                setIsIntermittent(true)
                            } else {
                                setIsIntermittent(false)
                                setTypeBusinessLink(parseInt(e.target.value))
                            }
                        }} required
                    >
                        <option value="0">- Selecione -</option>
                        <option value="10">10 - Empregado</option>
                        <option value="55">55 - Menor Aprendiz</option>
                        <option value="90">90 - Estágiario</option>
                        <option value="11">10 - Intermitente</option>
                        <option value="49">49 - Doméstica</option>
                        <option value="80">80 - Pró Labore</option>
                        <option value="60">60 - Determinado - Lei 9.601/98</option>
                    </select>
                </div>
                {
                    typeBusinessLink == '90' &&
                    <>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeInsurancePolicy'] && traineeInsurancePolicy == '' ? 'red' : '#464545' }} htmlFor="traineeInsurancePolicy">Apólice de Seguro *</label>
                            <Input
                                id={'traineeInsurancePolicy'}
                                style={{ border: missingRequiredFields['traineeInsurancePolicy'] && traineeInsurancePolicy == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                charLimit={30}                           
                                value={traineeInsurancePolicy ? traineeInsurancePolicy : ''}
                                onChange={(e) => {
                                    setTraineeInsurancePolicy(e.target.value)
                                }}        
                                disabled={inEdit ? false : true}
                                width={80}                                
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeEndForecast'] && traineeEndForecast == '' ? 'red' : '#464545' }} htmlFor="traineeEndForecast">Término do Estágio *</label>
                            <input 
                                style={{ border: missingRequiredFields['traineeEndForecast'] && traineeEndForecast == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                disabled={inEdit ? false : true}
                                id='traineeEndForecast' 
                                value={traineeEndForecast} 
                                onChange={(e) => { 
                                    setTraineeEndForecast(e.target.value)
                                }} 
                                className="smallInput" />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeEducationalInstitution'] && traineeEducationalInstitution == '' ? 'red' : '#464545' }} htmlFor="traineeEducationalInstitution">Instituição de Ensino *</label>                            
                            <Input
                                id={'traineeEducationalInstitution'} 
                                disabled={inEdit ? false : true}
                                style={{ border: missingRequiredFields['traineeEducationalInstitution'] && traineeEducationalInstitution == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}   
                                charLimit={255}                            
                                value={traineeEducationalInstitution ? traineeEducationalInstitution : ''}
                                onChange={(e) => {
                                    setTraineeEducationalInstitution(e.target.value)
                                }}
                                width={80}
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeCodSupervisor'] && traineeCodSupervisor == '' ? 'red' : '#464545' }} htmlFor="traineeCodSupervisor">Supervisor *</label>
                            <Input
                                id={'traineeCodSupervisor'} 
                                disabled={inEdit ? false : true}
                                style={{ border: missingRequiredFields['traineeCodSupervisor'] && traineeCodSupervisor == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}   
                                charLimit={255} 
                                width={80}                           
                                value={traineeCodSupervisor ? traineeCodSupervisor : ''}
                                onChange={(e) => {
                                    setTraineeCodSupervisor(e.target.value)
                                }}
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeOrigin'] && traineeOrigin == '' ? 'red' : '#464545' }} htmlFor="typeApuration">Natureza do Estágio *</label>
                            <select style={{ border: missingRequiredFields['traineeOrigin'] && traineeOrigin == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="traineeOrigin" value={traineeOrigin} onChange={(e) => setTraineeOrigin(e.target.value)} disabled={inEdit ? false : true}>
                                <option value="0">- Selecione -</option>
                                <option value="O">Obrigatório</option>
                                <option value="N">Não Obrigatório</option>
                            </select>
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeEducationLevel'] && traineeEducationLevel == 0 ? 'red' : '#464545' }} htmlFor="typeApuration">Nível do Estágio *</label>
                            <select style={{ border: missingRequiredFields['traineeEducationLevel'] && traineeEducationLevel == 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="traineeEducationLevel" value={traineeEducationLevel} onChange={(e) => setTraineeEducationLevel(parseInt(e.target.value))} disabled={inEdit ? false : true}>
                                <option value="0">- Selecione -</option>
                                <option value="1">Fundamental</option>
                                <option value="2">Médio</option>
                                <option value="3">Formação Profissional</option>
                                <option value="4">Superior</option>
                                <option value="8">Especial</option>
                                <option value="9">Mãe social. (Lei 7644, de 1987)</option>
                            </select>
                        </div>
                    </>
                }
                {
                    typeBusinessLink == '55' ?
                    <>
                        <div>
                            <label style={{ color: missingRequiredFields['apprenticeInstitutionName'] && apprenticeInstitutionName == '' ? 'red' : '#464545' }} htmlFor="apprenticeInstitutionName">Nome da Instituição do Curso *</label>
                            <Input
                                id={'apprenticeInstitutionName'}
                                style={{ border: missingRequiredFields['apprenticeInstitutionName'] && apprenticeInstitutionName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                charLimit={100}                           
                                value={apprenticeInstitutionName ? apprenticeInstitutionName : ''}
                                onChange={(e) => {
                                    setApprenticeInstitutionName(e.target.value)
                                }}
                                disabled={inEdit ? false : true}
                                width={80}
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['apprenticeInstitutionCNPJ'] && apprenticeInstitutionCNPJ == '' ? 'red' : '#464545' }} htmlFor="apprenticeInstitutionCNPJ">CNPJ da Instituição do Curso *</label>
                            <Input
                                id={'apprenticeInstitutionCNPJ'}
                                style={{ border: missingRequiredFields['apprenticeInstitutionCNPJ'] && apprenticeInstitutionCNPJ == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                charLimit={30}                           
                                value={apprenticeInstitutionCNPJ ? cnpjMask(apprenticeInstitutionCNPJ) : ''}
                                onChange={(e) => {
                                    setApprenticeInstitutionCNPJ(cnpjMask(e.target.value))
                                }}
                                disabled={inEdit ? false : true}
                                width={80}
                            />
                        </div>
                        <div>
                            <label style={{ color: ((missingRequiredFields['apprenticeDateFinish'] && apprenticeDateFinish == '') || missingRequiredFields['apprenticeDateFinish']) ? 'red' : '#464545' }} htmlFor="apprenticeDateFinish">Data do Término Previsto do Contrato *</label>
                            <input style={{ border: ((missingRequiredFields['apprenticeDateFinish'] && apprenticeDateFinish == '') || missingRequiredFields['apprenticeDateFinish']) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='apprenticeDateFinish' value={apprenticeDateFinish} onChange={(e) => { setApprenticeDateFinish(e.target.value)}} className="smallInput" disabled={inEdit ? false : true} />
                        </div>
                    </>
                    : 
                    <></>
                }
                <div>
                    <label style={{ color: missingRequiredFields['typeApuration'] && typeApuration == '' ? 'red' : '#464545' }} htmlFor="typeApuration">Tipo Apuração Ponto *</label>
                    <select id="typeApuration" value={typeApuration} disabled={inEdit ? false : true} onChange={(e) => setTypeApuration(parseInt(e.target.value))} required>
                        <option value="0">- Selecione -</option>
                        <option value="1">Normal</option>
                        <option value="5">Sem Controle de Ponto e Refeitório</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeLaborUnion'] && employeeLaborUnion == '' ? 'red' : '#464545' }} htmlFor="employeeLaborUnion">Funcionário Sindicalizado *</label>
                    <select id="employeeLaborUnion" disabled={inEdit ? false : true} value={employeeLaborUnion} onChange={(e) => setEmployeeLaborUnion((e.target.value == 'true'))} required>
                        <option value="">- Selecione -</option>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                <div>
                    {
                        employeeLaborUnion == true
                            ?
                            <>
                                <label style={{ color: missingRequiredFields['laborUnion'] && laborUnion == '' ? 'red' : '#464545' }} htmlFor="laborUnion">Sindicato *</label>
                                <input style={{ border: missingRequiredFields['laborUnion'] && laborUnion == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='laborUnion' value={laborUnion} onChange={(e) => setLaborUnion(e.target.value)} className="mediumInput" disabled={inEdit ? false : true} required />
                            </>
                            :
                            <>
                                <label style={{ color: missingRequiredFields['laborUnion'] && laborUnion == '' ? 'red' : '#464545' }} htmlFor="laborUnion">Sindicato</label>
                                <input style={{ border: missingRequiredFields['laborUnion'] && laborUnion == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='laborUnion' value='' className="mediumInput" disabled={inEdit ? false : true} />
                            </>
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeePosition'] && employeePosition == '' ? 'red' : '#464545' }} htmlFor="employeePosition">Cargo *</label>
                    <input style={{ border: missingRequiredFields['employeePosition'] && employeePosition == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeePosition' value={employeePosition} onChange={(e) => setEmployeePosition(e.target.value)} className="mediumInput" disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="employeeSchedule">Escala de Trabalho *</label>
                    {
                        employeeScheduleType == '' || employeeScheduleFriday == '' || employeeScheduleMonday == '' || employeeScheduleSaturday == '' ||
                            employeeScheduleSunday == '' || employeeScheduleThursday == '' || employeeScheduleTuesday == '' || employeeScheduleWednesday == '' ?
                            <input type="button" id='employeeSchedule' onClick={() => setShow(true)} className="soSmallInput notCompleted" value="Informar" required /> :
                            <input type="button" id='employeeSchedule' onClick={() => setShow(true)} className="soSmallInput" value="Consultar" />
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeSalary'] && ((employeeSalary == '' || employeeSalary <= 0) && typeSalary != 4) ? 'red' : '#464545' }} htmlFor="employeeSalary">{typeSalary != 4 ? 'Salário Base *' : 'Salário Base'}</label>
                    <input style={{ border: missingRequiredFields['employeeSalary'] && ((employeeSalary == '' || employeeSalary <= 0) && typeSalary != 4) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="number" id='employeeSalary' value={employeeSalary} onChange={(e) => setEmployeeSalary(e.target.value)} className="soSmallInput" disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="typeSalary">Salário Tipo *</label>
                    <select id="typeSalary" value={typeSalary} disabled={inEdit ? false : true} onChange={(e) => setTypeSalary(parseInt(e.target.value))} required>
                        <option value="0">- Selecione -</option>
                        <option value="1">Mensalista</option>
                        <option value="2">Horista</option>
                        <option value="4">Comissionado</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="gratificationFunction">Gratificação de Função</label>
                    <select id="gratificationFunction" value={gratificationFunction} disabled={inEdit ? false : true} onChange={(e) => setGratificationFunction(e?.target?.value)}>
                        <option value="">- Selecione -</option>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeLocal'] && employeeLocal == '' ? 'red' : '#464545' }} htmlFor="employeeLocal">Local/Setor *</label>
                    <input style={{ border: missingRequiredFields['employeeLocal'] && employeeLocal == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id="employeeLocal" value={employeeLocal} onChange={(e) => setEmployeeLocal(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="employeeCCU">Centro de Custos</label>
                    <input type="text" id="employeeCCU" value={employeeCCU} onChange={(e) => setEmployeeCCU(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                {
                    companyConfig?.useNatureExpenses == 'S' &&
                    <div>
                        <label style={{ color: missingRequiredFields['natureExpense'] && natureExpense == '' ? 'red' : '#464545' }} htmlFor="natureExpense">Natureza de despesas *</label>
                        <select style={{ border: missingRequiredFields['natureExpense'] && natureExpense == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="natureExpense" value={natureExpense} onChange={(e) => setNatureExpense(e?.target?.value)} disabled={inEdit ? false : true}>
                            <option value="0">- Selecione -</option>
                            {
                                natureExpenseList?.map(natu => (
                                    <option value={natu?.natdes}>{natu?.natdes} - {natu?.nomnat}</option>
                                ))
                            }
                        </select>
                    </div>
                }
                <div>
                    <label htmlFor="employeeWorkplace">Posto de Trabalho</label>
                    <input type="text" id="employeeWorkplace" value={employeeWorkplace} onChange={(e) => setEmployeeWorplace(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeVT'] && employeeVT == '0' ? 'red' : '#464545' }} htmlFor="employeeVT">VT *</label>
                    <select style={{ border: missingRequiredFields['employeeVT'] && employeeVT == '0' ? '1px solid #e2a3a7' : '1px solid #999999' }} disabled={inEdit ? false : true} id="employeeVT" value={employeeVT} onChange={(e) => setEmployeeVT((e.target.value == 'true'))}>
                        <option value="">- Selecione -</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                {
                    employeeVT &&
                    <div>
                        <label htmlFor="employeeWorkplace">Quantidade por dia</label>
                        <input type="text" id="employeeWorkplace" value={employeeValueVT ? employeeValueVT : ''} onChange={(e) => setEmployeeValueVT(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                    </div>
                }
                <div>
                    <label htmlFor="advanceSalary">Adiantamento Salarial *</label>
                    <select id="advanceSalary" disabled={inEdit ? false : true} value={advanceSalary} onChange={(e) => setAdvanceSalary((e.target.value == 'true'))}>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div>
                    {
                        !advanceSalary ? (
                            <>
                                <label htmlFor="advanceSalaryPorcentage">Porcentagem</label>
                                <input type="text" id="advanceSalaryPorcentage" value='' className='soSmallInput' disabled={inEdit ? false : true} />
                            </>
                        ) : (
                            <>
                                <label style={{ color: missingRequiredFields['advanceSalaryPorcentage'] && advanceSalaryPorcentage == '' ? 'red' : '#464545' }} htmlFor="advanceSalaryPorcentage">Porcentagem *</label>
                                <input type="number" id="advanceSalaryPorcentage" value={advanceSalaryPorcentage} onChange={(e) => setAdvanceSalaryPorcentage(parseInt(e.target.value))} className='soSmallInput' required disabled={inEdit ? false : true}/>
                            </>
                        )
                    }
                </div>
                <div>
                    <label htmlFor="advanceMonthAdmission">Recebe Adto Mês Admissão</label>
                    <select id="advanceMonthAdmission" value={advanceMonthAdmission} disabled={inEdit ? false : true} onChange={(e) => setAdvanceMonthAdmission(e.target.value)}>
                        <option value="1">Sim</option>
                        <option value="">Não</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeExperience'] && employeeExperience == '' ? 'red' : '#464545' }} htmlFor="employeeExperience">Experiência *</label>
                    <select type="text" id="employeeExperience" value={employeeExperience} onChange={(e) => setEmployeeExperience(parseInt(e.target.value))} disabled={inEdit ? false : true}>
                        <option value="0">- Selecione -</option>
                        <option value="1">30 + 60</option>
                        <option value="2">45 + 45</option>
                        <option value="3">60 + 30</option>
                        <option value="4">30 + 30</option>
                        <option value="6">30 + 45</option>
                        <option value="7">15 + 30</option>
                        <option value="5">Não será aplicado</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="lifeInsurance">Seguro de Vida *</label>
                    <select type="text" id="lifeInsurance" value={lifeInsurance} onChange={(e) => setLifeInsurance((e.target.value == 'true'))} disabled={inEdit ? false : true}>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="insalubrity">Insalubridade</label>
                    <select type="text" id="insalubrity" disabled={inEdit ? false : true} value={insalubrity} onChange={(e) => setInsalubrity((e.target.value === 'true'))} className='soSmallInputLittle' required>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                {
                    insalubrity ?
                    <div>
                        <label htmlFor="insalubrityPercentage">Porcentagem Insalubridade</label>
                        <input type="number" id="insalubrityPercentage" value={insalubrityPercentage} onChange={(e) => setInsalubrityPercentage(parseInt(e.target.value))} className='soSmallInput' disabled={inEdit ? false : true}/>
                    </div>
                    :
                    <div>
                        <label htmlFor="insalubrityPercentage">Porcentagem Insalubridade</label>
                        <input type="text" id="insalubrityPercentage" value='Não aplicável' className='soSmallInput' disabled />
                    </div>
                }
                <div>
                    <label htmlFor="periculosity">Periculosidade</label>
                    <select type="text" id="periculosity" disabled={inEdit ? false : true} value={periculosity} onChange={(e) => setPericulosity((e.target.value) === 'true')} className='soSmallInputLittle' required>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                {
                    periculosity ?
                    <div>
                        <label htmlFor="periculosityPercentage">Porcentagem Periculosidade</label>
                        <input type="number" id="periculosityPercentage" value={periculosityPercentage} onChange={(e) => setPericulosityPercentage(parseInt(e.target.value))} className='soSmallInput' disabled={inEdit ? false : true}/>
                    </div>
                    :
                    <div>
                        <label htmlFor="periculosityPercentage">Porcentagem Periculosidade</label>
                        <input type="text" id="periculosityPercentage" value='Não aplicável' className='soSmallInput' disabled />
                    </div>
                }
                <div>
                    <label htmlFor="paymentPeriod">Período de Pagmento *</label>
                    <select id="paymentPeriod" disabled={inEdit ? false : true} value={paymentPeriod} onChange={(e) => setPaymentPeriod(e.target.value)} required>
                        <option value="">- Selecione -</option>
                        <option value="M">Mensal</option>
                        <option value="S">Semanal</option>
                        <option value="Q">Quinzenal</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['paymentMethod'] && paymentMethod == '' ? 'red' : '#464545' }} htmlFor="paymentMethod">Forma de Pagamento *</label>
                    <select id="paymentMethod" value={paymentMethod} onChange={(e) => {
                        setPaymentMethod(e.target.value);
                        changeAccount()
                    }} disabled={inEdit ? false : true}>
                        <option value="">- Selecione -</option>
                        <option value="D">Dinheiro</option>
                        <option value="R">Relação Bancária</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBank'] && paymentMethod == 'R' && employeeBank < 0 ? 'red' : '#464545' }} htmlFor="employeeBank">Banco</label>
                    {
                        paymentMethod == 'R'
                            ?
                            (
                                <select style={{ border: missingRequiredFields['employeeBank'] && employeeBank < 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeBank" value={employeeBank} onChange={(e) => {
                                    setEmployeBank(parseInt(e.target.value))
                                }} disabled={inEdit ? false : true}>
                                    <option value="0">- Selecione -</option>
                                    {
                                        banks.map(bank => {
                                            return <option value={bank.bank_id}>{bank.bank_name.toUpperCase()} - {bank.bank_id} </option>
                                        })
                                    }
                                </select>
                            )
                            :
                            (
                                <input type="text" id="employeeBank" value='Não aplicável' className='smallInput' disabled />
                            )
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBankAgencies'] && paymentMethod == 'R' && employeBankAgencie < 0 ? 'red' : '#464545' }} htmlFor="employeeBankAgencies">Agência</label>
                    {
                        paymentMethod == 'R'
                            ?
                            (
                                <Input
                                    style={{ border: missingRequiredFields['employeeBankAgencies'] && !employeBankAgencie ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                    id="employeeBankAgencies"
                                    value={employeBankAgencie} 
                                    onChange={(e) => {
                                        setEmployeBankAgencie(justNumber(e.target.value))
                                    }}
                                    width='20'
                                    charLimit={4}
                                    className='soSmallInputLittle'
                                    disabled={inEdit ? false : true}
                                />
                            )
                            :
                            (
                                <input type="text" id="employeeBankAgencies" value='Não aplicável' className='smallInput' disabled />
                            )
                    }
                </div>
                <div>
                    <label htmlFor="digitAgency">Dígito Agência</label>
                    {
                        paymentMethod == 'R' ?
                        <Input
                            id="digitAgency"
                            value={digitAgency ? digitAgency : ''} 
                            onChange={(e) => {
                                setDigitAgency(justNumber(e.target.value))
                            }}
                            width='20'
                            charLimit={1}
                            className='soSmallInputLittle'
                            disabled={inEdit ? false : true}
                        />
                        :
                        <input type="text" id="digitAgency" value='Não aplicável' className='smallInput' disabled />
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBankAccountType'] && paymentMethod == 'R' && employeeBankAccountType < 0 ? 'red' : '#464545' }} htmlFor="employeeBankAccountType">Tipo de Conta</label>
                    {
                        paymentMethod == 'R'
                            ?
                            (
                                <select disabled={inEdit ? false : true} style={{ border: missingRequiredFields['employeeBankAccountType'] && employeeBankAccountType < 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeBankAccountType" value={employeeBankAccountType} onChange={(e) => setEmployeeBankAccountType(parseInt(e.target.value))} className='smallInput'>
                                    <option value="0">- Selecione -</option>
                                    <option value="1">Conta Corrente</option>
                                    <option value="2">Conta Poupança</option>
                                    <option value="3">Conta Salário</option>
                                    <option value="9">Outros</option>
                                </select>
                            )
                            : <input type="text" id="employeeBankAccountType" value='Não aplicável' className='smallInput' disabled />
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBankAccount'] && (isNaN(employeeBankAccount) || employeeBankAccount <= 0) ? 'red' : '#464545' }} htmlFor="employeeBankAccount">Nº Conta</label>
                    {
                        paymentMethod == 'R'
                            ? <input style={{ border: missingRequiredFields['employeeBankAccount'] && (isNaN(employeeBankAccount) || employeeBankAccount <= 0) ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeBankAccount" value={employeeBankAccount} onChange={(e) => setEmployeeBankAccount(e.target.value)} className='soSmallInput' maxlength='12' disabled={inEdit ? false : true}/>
                            : <input type="text" id="employeeBankAccount" value='Não aplicável' className='soSmallInput' disabled />
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBankAccountDigit'] && paymentMethod == 'R' && employeeBankAccountDigit == '' ? 'red' : '#464545' }} htmlFor="employeeBankAccountDigit">Dígito Conta</label>
                    {
                        paymentMethod == 'R'
                            ? <input style={{ border: missingRequiredFields['employeeBankAccountDigit'] && employeeBankAccountDigit == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeBankAccountDigit" value={employeeBankAccountDigit} onChange={(e) => setEmployeeBankAccountDigit(e.target.value)} className='soSmallInputLittle' maxlength='3' disabled={inEdit ? false : true}/>
                            : <input type="text" id="employeeBankAccount" value='Não aplicável' className='soSmallInputLittle' disabled />
                    }
                </div>
                <div>
                    <label htmlFor="observation">Observação</label>
                    <textarea maxLength={255} id='observation' value={observation} onChange={(e) => setObservation(e.target.value)} disabled={inEdit ? false : true}/>
                </div>
            </div>
        )
    }

    function employeeDataForm() {
        return (
            <div id='partForm' className='FormRequestContainer overflow-auto xl:h-[75vh] 2xl:h-full h-[65vh] lg:pb-8 2xl:pb-0 top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                {
                    selectedForm == 4 ?
                        <></>
                        :
                        <div className="formDescription">
                            <p className="obsFormRequestAdmission"> Todos os campos com (*) são obrigatórios</p>
                        </div>
                }
                {
                    insideLoading ?
                        (
                            <div className='insideLoadingForm'>
                                <div>
                                    <Loader />
                                </div>
                            </div>
                        ) : <></>
                }
                <div className='hidden sm:flex sm:flex-col'>
                    <label htmlFor='nickname'>Apelido</label>
                    <input type="text" id='nickname' value={nickname ? nickname : '' } onChange={(e) => setNickname(e.target.value)} className="bigInput" maxLength={50} disabled={inEdit ? false : true}/>
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor='nickname'>Apelido</label>
                    <input type="text" id='nickname' value={nickname ? nickname : '' } onChange={(e) => setNickname(e.target.value)} maxLength={50} disabled={inEdit ? false : true}/>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeSexualGender'] && employeeSexualGender == '' ? 'red' : '#464545' }} htmlFor="employeeSexualGender">Sexo *</label>
                    <select id="employeeSexualGender" value={employeeSexualGender} onChange={(e) => setEmployeeSexualGender(e.target.value)} disabled={inEdit ? false : true}>
                        <option value="">- Selecione -</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['civilStatus'] && civilStatus == '' ? 'red' : '#464545' }} htmlFor="civilStatus">Estado Civil *</label>
                    <select id="civilStatus" value={civilStatus} onChange={(e) => setCivilStatus(parseInt(e.target.value))} disabled={inEdit ? false : true}>
                        <option value="0">- Selecione -</option>
                        <option value="1">1 - Solteiro</option>
                        <option value="2">2 - Casado</option>
                        <option value="3">3 - Divorciado</option>
                        <option value="4">4 - Viúvo</option>
                        <option value="5">5 - Concubinado</option>
                        <option value="6">6 - Separado</option>
                        <option value="7">7 - União Estável</option>
                        <option value="9">9 - Outros</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['levelOfEducation'] && levelOfEducation == '' ? 'red' : '#464545' }} htmlFor="levelOfEducation">Formação *</label>
                    <select id="levelOfEducation" value={levelOfEducation} onChange={(e) => setLevelOfEducation(parseInt(e.target.value))} disabled={inEdit ? false : true}>
                        <option value="0">- Selecione -</option>
                        <option value="1">1 - Analfabeto</option>
                        <option value="2">2 - 4ª série incompleta</option>
                        <option value="3">3 - 4ª série completa</option>
                        <option value="4">4 - 5ª a 8ª série incompleta</option>
                        <option value="5">5 - 1º grau completo</option>
                        <option value="6">6 - 2º grau incompleto </option>
                        <option value="7">7 - 2º grau completo</option>
                        <option value="8">8 - Superior incompleto</option>
                        <option value="9">9 - Superior completo</option>
                        <option value="10">10 - Pós-graduação</option>
                        <option value="11">11 - Mestrado Completo</option>
                        <option value="12">12 - Doutorado Completo</option>
                        <option value="13">13 - Ph.D</option>
                        <option value="14">14 - 2º grau tec. incompleto</option>
                        <option value="15">15 - 2º grau tec. completo</option>
                        <option value="16">16 - Mestrado Incompleto</option>
                        <option value="17">17 - Doutorado Incompleto</option>
                        <option value="18">18 - Pós-graduação Incompleto</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['birthday'] && birthday == '' ? 'red' : '#464545' }} htmlFor="birthday">Data de Nascimento *</label>
                    <input style={{ border: missingRequiredFields['birthday'] && birthday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='birthday' value={birthday} onChange={(e) => setBirthday(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['nationality'] && nationality == '' ? 'red' : '#464545' }} htmlFor="nationality">Nacionalidade *</label>
                    <select style={{ border: missingRequiredFields['birthday'] && (nationality < 0 || nationality == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} id="nationality" value={nationality} onChange={(e) => setNationality(parseInt(e.target.value))} disabled={inEdit ? false : true}>
                        <option value="-1">- Selecione -</option>
                        {
                            nationalities.map(nationality => {
                                return <option value={nationality.nationality_id}>{nationality.nationality_name}</option>
                            })
                        }
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeUF'] && employeeUF == '' ? 'red' : '#464545' }} htmlFor="employeeUF">UF Nascimento *</label>
                    <select style={{ border: missingRequiredFields['employeeUF'] && employeeUf == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeeUF' maxlength="2" value={employeeUf.toUpperCase()} onChange={(e) => setEmployeeUf(e.target.value)} className='smallInput' maxLength='2' disabled={inEdit ? false : true}>
                        <option value="">- Selecione -</option>
                        {
                            nationalitiesState.map(state => {
                                return <option value={state.state_id}> {state.state_id} </option>
                            })
                        }
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['City'] && City == '' ? 'red' : '#464545' }} htmlFor="City">Cidade Nascimento *</label>
                    <select style={{ border: missingRequiredFields['employeeCity'] && employeeCity == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeeCity' maxlength="2" value={employeeCity} onChange={(e) => { setEmployeeCity(parseInt(e.target.value)); }} className='smallInput' maxLength='2' disabled={inEdit ? false : true}>
                        <option value="">- Selecione -</option>
                        {
                            nationalitiesStateCities.map(city => {
                                return <option value={city.city_id}> {city.city_name} </option>
                            })
                        }
                    </select>
                </div>
                {
                    nationality != 10 &&
                    <>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignArrivalDate'] && foreignArrivalDate == '' ? 'red' : '#464545' }} htmlFor="foreignArrivalDate">Data Chegada *</label>
                            <input 
                                style={{ border: missingRequiredFields['foreignArrivalDate'] && foreignArrivalDate == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                id='foreignArrivalDate' 
                                value={foreignArrivalDate} 
                                onChange={(e) => { 
                                    setForeignArrivalDate(e.target.value)
                                }} 
                                className="smallInput" 
                                disabled={inEdit ? false : true}
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignResidenceTime'] && foreignResidenceTime == '' ? 'red' : '#464545' }} htmlFor="foreignResidenceTime">Tempo de Residência *</label>
                            <select style={{ border: missingRequiredFields['foreignResidenceTime'] && foreignResidenceTime == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="foreignResidenceTime" value={foreignResidenceTime} onChange={(e) => {if (e.target.value == 1) { setForeignFinishResidence('') } setForeignResidenceTime(parseInt(e.target.value)) }} disabled={inEdit ? false : true}>
                                <option value="0">- Selecione -</option>
                                <option value="1">Prazo indeterminado</option>
                                <option value="2">Prazo determinado</option>
                            </select>
                        </div>
                        {
                            foreignResidenceTime == 2 &&
                            <div>
                                <label style={{ color: missingRequiredFields['foreignFinishResidence'] && foreignFinishResidence == '' ? 'red' : '#464545' }} htmlFor="foreignFinishResidence">Data de Término da Residência *</label>
                                <input 
                                    style={{ border: missingRequiredFields['foreignFinishResidence'] && foreignFinishResidence == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                    type="date" 
                                    id='foreignFinishResidence' 
                                    value={foreignFinishResidence} 
                                    onChange={(e) => { 
                                        setForeignFinishResidence(e.target.value)
                                    }} 
                                    className="smallInput" 
                                    disabled={inEdit ? false : true}
                                />
                            </div>
                        }
                        <div>
                            <label style={{ color: missingRequiredFields['foreignCondition'] && foreignCondition == '' ? 'red' : '#464545' }} htmlFor="foreignCondition">Condição Estrangeiro *</label>
                            <select style={{ border: missingRequiredFields['foreignCondition'] && foreignCondition == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="foreignCondition" value={foreignCondition} onChange={(e) => setForeignCondition(parseInt(e.target.value))} disabled={inEdit ? false : true}>
                                <option value="0">- Selecione -</option>
                                <option value="1">Visto permanente</option>
                                <option value="2">Visto temporário</option>
                                <option value="3">Asilado</option>
                                <option value="4">Refugiado</option>
                                <option value="5">Solicitante de Refúgio</option>
                                <option value="6">Residente fora do Brasil</option>
                                <option value="7">Deficiente físico e com mais de 51 anos</option>
                                <option value="8">Com residência provisória e anistiado, em situação irregular</option>
                                <option value="9">Permanência no Brasil em razão de reunião familiar</option>
                                <option value="10">Beneficiado pelo acordo entre países do Mercosul</option>
                                <option value="11">Dependente de agente diplomático e/ou consular de países que mantém convênio de reciprocidade para o exercício ...</option>
                                <option value="12">Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil ...</option>
                                <option value="13">Outra condição</option>
                            </select>
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignRegistry'] && foreignRegistry == '' ? 'red' : '#464545' }} htmlFor="foreignRegistry">Reg.Estrangeiro *</label>
                            <Input
                                id={'foreignRegistry'}
                                style={{ border: missingRequiredFields['foreignRegistry'] && foreignRegistry == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                charLimit={14}                         
                                value={foreignRegistry ? foreignRegistry : ''}
                                onChange={(e) => {
                                    setForeignRegistry(e.target.value)
                                }}        
                                width={80}  
                                disabled={inEdit ? false : true}                              
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignDateExpRegistry'] && foreignDateExpRegistry == '' ? 'red' : '#464545' }} htmlFor="foreignDateExpRegistry">Data Expedição Reg.Estrangeiro *</label>
                            <input 
                                style={{ border: missingRequiredFields['foreignDateExpRegistry'] && foreignDateExpRegistry == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                id='foreignDateExpRegistry' 
                                value={foreignDateExpRegistry} 
                                onChange={(e) => { 
                                    setForeignDateExpRegistry(e.target.value)
                                }} 
                                disabled={inEdit ? false : true}
                                className="smallInput" />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignVisaValidity'] && foreignVisaValidity == '' ? 'red' : '#464545' }} htmlFor="foreignVisaValidity">Validade do Visto *</label>
                            <input 
                                style={{ border: missingRequiredFields['foreignVisaValidity'] && foreignVisaValidity == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                id='foreignVisaValidity' 
                                value={foreignVisaValidity} 
                                onChange={(e) => { 
                                    setForeignVisaValidity(e.target.value)
                                }} 
                                disabled={inEdit ? false : true}
                                className="smallInput" />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignDateValidityCTPS'] && foreignDateValidityCTPS == '' ? 'red' : '#464545' }} htmlFor="foreignDateValidityCTPS">Data Validade CTPS *</label>
                            <input 
                                style={{ border: missingRequiredFields['foreignDateValidityCTPS'] && foreignDateValidityCTPS == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                id='foreignDateValidityCTPS' 
                                value={foreignDateValidityCTPS} 
                                onChange={(e) => { 
                                    setForeignDateValidityCTPS(e.target.value)
                                }} 
                                disabled={inEdit ? false : true}
                                className="smallInput" />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignCasWithBrazilian'] && foreignCasWithBrazilian == '' ? 'red' : '#464545' }} htmlFor="foreignCasWithBrazilian">Casado com Brasileiro *</label>
                            <select style={{ border: missingRequiredFields['foreignCasWithBrazilian'] && foreignCasWithBrazilian == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="foreignCasWithBrazilian" value={foreignCasWithBrazilian} onChange={(e) => setForeignCasWithBrazilian(e.target.value)} disabled={inEdit ? false : true}>
                                <option value="">- Selecione -</option>
                                <option value="S">Sim</option>
                                <option value="N">Não</option>
                            </select>
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignBrazilianChildren'] && foreignBrazilianChildren == '' ? 'red' : '#464545' }} htmlFor="foreignBrazilianChildren">Filhos Brasileiros *</label>
                            <select style={{ border: missingRequiredFields['foreignBrazilianChildren'] && foreignBrazilianChildren == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="foreignBrazilianChildren" value={foreignBrazilianChildren} onChange={(e) => setForeignBrazilianChildren(e.target.value)} disabled={inEdit ? false : true}>
                                <option value="">- Selecione -</option>
                                <option value="S">Sim</option>
                                <option value="N">Não</option>
                            </select>
                        </div>
                </>
                }

                <div>
                    <label style={{ color: missingRequiredFields['workCard'] && workCard == '' ? 'red' : '#464545' }} htmlFor="workCard">Carteira de Trabalho *</label>
                    <input style={{ border: missingRequiredFields['workCard'] && workCard == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='workCard' value={workCard} onChange={(e) => setWorkCard(e.target.value)} className='mediumInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['workCardSerie'] && workCardSerie == '' ? 'red' : '#464545' }} htmlFor="workCardSerie">CTPS Serie *</label>
                    <input style={{ border: missingRequiredFields['workCardSerie'] && workCardSerie == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='workCardSerie' value={workCardSerie} onChange={(e) => setWorkCardSerie(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['workCardEmission'] && workCardEmission == '' ? 'red' : '#464545' }} htmlFor="workCardEmission">CTPS Emissão</label>
                    <input style={{ border: missingRequiredFields['workCardEmission'] && workCardEmission == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='workCardEmission' value={workCardEmission} onChange={(e)=> setWorkCardEmission(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['workCardUF'] && workCardUF == '' ? 'red' : '#464545' }} htmlFor="workCardUF">CTPS UF</label>
                    <select disabled={inEdit ? false : true} style={{ border: missingRequiredFields['workCardUF'] && (workCardUf < 0 || workCardUf == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} id="workCardUF" value={workCardUf} onChange={(e) => setWorkCardUf(e.target.value)} >
                        <option value="-1">- Selecione -</option>
                        {
                            brazilStates.map(state => {
                                return <option value={state.state_id}> {state.state_id} </option>
                            })
                        }
                        <option value="xx">Estrangeiro</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['cpf'] && cpf == '' ? 'red' : '#464545' }} htmlFor="cpf">CPF *</label>
                    <input style={{ border: missingRequiredFields['cpf'] && cpf == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='cpf' maxlength="14" value={cpf} onChange={(e) => setCpf(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="firstJob">Primeiro Emprego * </label>
                    <select value={firstJob ? 1 : 0} onChange={(e) => { setFirstJob((e.target.value == '1')) }} disabled={inEdit ? false : true}>
                        <option value='0'>Não</option>
                        <option value='1'>Sim</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="pis">PIS</label>
                    <input type="text" id='pis' value={pisMask(pis)} minLength="11" onChange={(e) => setPis(e.target.value)} className='mediumInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="pisEmission">Emissão PIS </label>
                    <input type="date" id='pisEmission' value={emissionPis} onChange={(e) => setEmissionPis(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="RG">RG </label>
                    <input type="text" id='RG' value={rg} onChange={(e) => setRg(e.target.value)} className='mediumInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="sendingAgencyRG">RG Orgão Expedidor </label>
                    <input type="text" id='sendingAgencyRG' value={sendingAgencyRG} onChange={(e) => setSendingAgencyRg(e.target.value)} className='mediumInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="emissionRG">RG Emissão </label>
                    <input type="date" id='emissionRG' value={EmissionRG} onChange={(e) => setEmissionRg(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="RGuf">RG UF </label>
                    <select disabled={inEdit ? false : true} style={{ border: missingRequiredFields['RGuf'] && (rgUf == '0' || rgUf == '') && rg !== '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="RGuf" value={rgUf} onChange={(e) => setRgUf(e.target.value)} >
                        <option value="">- Selecione -</option>
                        {
                            brazilStates.map(state => {
                                return <option value={state.state_id}> {state.state_id} </option>
                            })
                        }
                    </select>
                </div>
                <div>
                    <label htmlFor="voterRegistration">Titulo de Eleitor </label>
                    <input type="text" id='voterRegistration' value={voterRegistration} onChange={(e) => setVoterRegistration(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="voterRegistrationZone">Titulo de Eleitor Zona </label>
                    <input type="text" id='voterRegistrationZone' value={voterRegistrationZone} onChange={(e) => setVoterRegistrationZone(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="voterRegistrationSection">Titulo de Eleitor Seção </label>
                    <input type="text" id='voterRegistrationSection' value={voterRegistrationSection} onChange={(e) => setVoterRegistrationSection(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>

                <div>
                    <label style={{ color: missingRequiredFields['cnhExists'] && (cnhExists == '0' || cnhExists == '') && cnhExists !== '' ? 'red' : '#464545' }} htmlFor="cnh"> CNH </label>
                    <input tyle={{ border: missingRequiredFields['cnhExists'] && (cnhExists == '0' || cnhExists == '') && cnhExists !== '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='cnh' value={cnh} onChange={(e) => setCnh(e.target.value)} className='mediumInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="cnhCategory"> Categoria CNH  </label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='cnhCategory' value={cnhCategory} onChange={(e) => setCnhCategory(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="cnhValidity"> Validade CNH </label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='cnhValidity' value={cnhValidity} onChange={(e) => setCnhValidity(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="cnhIssuer"> Orgão Emissor CNH  </label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='cnhIssuer' value={cnhIssuer} onChange={(e) => setCnhIssuer(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="cnhUf"> UF CNH </label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="cnhUf" value={cnhUf} className='smallInput' disabled={inEdit ? false : true} onChange={(e) => setCnhUf(e.target.value)}/>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="cnhFirstDate"> Data 1ª CNH </label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='cnhFirstDate' value={cnhFirstDate} onChange={(e) => setCnhFirstDate(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="cnhDispatchDate"> Data Expedição CNH </label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='cnhDispatchDate' value={cnhDispatchDate} onChange={(e) => setCnhDispatchDate(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="militaryServiceCard"> Carteira Reservista </label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='militaryServiceCard' value={militaryServiceCard} onChange={(e) => setMilitaryServiceCard(e.target.value)} className='mediumInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="militaryServiceCardUf"> UF Carteira Reservista </label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='militaryServiceCardUf' value={militaryServiceCardUf} onChange={(e) => setMilitaryServiceCardUf(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="deficiency"> Possui Deficiência *</label>
                    <select type="text" id='deficiency' value={deficiency} onChange={(e) => setDeficiency((e.target.value === 'true'))} disabled={inEdit ? false : true}>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                {
                    !deficiency ?
                        (
                            <>
                                <div>
                                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="deficiencyType"> Tipo de Deficiencia </label>
                                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='deficiencyType' value="Não Aplicável" className='smallInput' disabled={inEdit ? false : true} />
                                </div>
                                <div>
                                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="deficiencyRehabilitatedInss"> Empregado Reabilitado do INSS </label>
                                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='deficiencyRehabilitatedInss' value="Não Aplicável" className='smallInput' disabled={inEdit ? false : true} />
                                </div>
                                <div>
                                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="deficiencyPcdQuote"> Compôe cota de PcD </label>
                                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='deficiencyPcdQuote' value="Não Aplicável" className='smallInput' disabled={inEdit ? false : true} />
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="deficiencyType"> Tipo de Deficiencia * </label>
                                    <select type="text" id='deficiencyType' value={deficiencyType} onChange={(e) => setDeficiencyType(parseInt(e.target.value))} className='smallInput' disabled={inEdit ? false : true}>
                                        <option value="0">- Selecione -</option>
                                        <option value="1">Deficiente Visual</option>
                                        <option value="2">Deficiente Fisica</option>
                                        <option value="3">Deficiente Auditiva</option>
                                        <option value="4">Deficiente Intelectual</option>
                                        <option value="5">Deficiente Mental</option>
                                        <option value="6">Deficiente Multipla</option>
                                    </select>
                                </div>
                                <div>
                                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="deficiencyRehabilitatedInss"> Empregado Reabilitado do INSS * </label>
                                    <select type="text" id='deficiencyRehabilitatedInss' value={deficiencyRehabilitatedInss} onChange={(e) => setDeficiencyRehabilitatedInss((e.target.value == 'true'))} className='smallInput' disabled={inEdit ? false : true}>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </select>
                                </div>
                                <div>
                                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="deficiencyPcdQuote"> Compôe cota de PcD * </label>
                                    <select type="text" id='deficiencyPcdQuote' value={deficiencyPcdQuote} onChange={(e) => setDeficiencyPcdQuote((e.target.value == 'true'))} className='smallInput' disabled={inEdit ? false : true}>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </select>
                                </div>
                            </>
                        )
                }
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && !employeeRace || employeeRace < 0 ? 'red' : '#464545' }} htmlFor="employeeRace"> Raça *</label>
                    <select type="text" id='employeeRace' value={employeeRace} onChange={(e) => setEmployeeRace(parseInt(e.target.value))} disabled={inEdit ? false : true}>
                        <option value="0" selected>Não informado</option>
                        <option value="1">Branca</option>
                        <option value="2">Preta</option>
                        <option value="3">Amarela</option>
                        <option value="4">Parda</option>
                        <option value="5">Indígena</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="addressCountry"> País * </label>
                    <select disabled={inEdit ? false : true} style={{ border: missingRequiredFields['addressCountry'] && (addressCountry < 0 || addressCountry == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressCountry' value={addressCountry} onChange={(e) => setAddressCountry(parseInt(e.target.value))} >
                        <option value="-1">- Selecione -</option>
                        {countries.map(country => {
                            return <option value={country.country_id}>{country.country_name.toUpperCase()}</option>
                        })}
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="addressState"> Estado * </label>
                    <select style={{ border: missingRequiredFields['addressState'] && (addressState < 0 || addressState == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressState' value={addressState} onChange={(e) => setAddressState(e.target.value)} disabled={inEdit ? false : true}>
                        <option value="-1">- Selecione -</option>
                        {states.map(state => {
                            return <option value={state.state_id}>{state.state_id.toUpperCase()} - {state.state_name.toUpperCase()}</option>
                        })}
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="addressCities"> Cidade * </label>
                    <select style={{ border: missingRequiredFields['addressCities'] && (addressCity < 0 || addressCity == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressCities' value={addressCity} onChange={(e) => setAddressCity(parseInt(e.target.value))} disabled={inEdit ? false : true}>
                        <option value="0">- Selecione -</option>
                        {cities.map(city => {
                            return <option value={city.city_id}>{city.city_name}</option>
                        })}
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor="addressDistrict"> Bairro * </label>
                    <select style={{ border: missingRequiredFields['addressDistrict'] && (addressDistrict == '0' || addressDistrict == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressDistrict' value={addressDistrict} onChange={(e) => setAddressDistrict(parseInt(e.target.value))} disabled={inEdit ? false : true}>
                        <option value="0">- Selecione -</option>
                        {districts.map(district => {
                            return <option value={district.district_id}>{district.district_name}</option>
                        })}
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressCep'] && addressCep == '' ? 'red' : '#464545' }} htmlFor="addressCep"> CEP * </label>
                    <input style={{ border: missingRequiredFields['addressCep'] && addressCep == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressCep' value={maskCep(addressCep)} onChange={(e) => setAddressCep(e.target.value)} className='smallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressType'] && (addressType < 0 || addressType == '') ? 'red' : '#464545' }} htmlFor="addressType"> Tipo de Logradouro * </label>
                    <select style={{ border: missingRequiredFields['addressType'] && (addressType < 0 || addressType == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressType' value={addressType} onChange={(e) => setAddressType(e.target.value)} disabled={inEdit ? false : true}>
                        <option value="R">Rua</option>
                        <option value="AV">Avenida</option>
                        <option value="VLA">Viela</option>
                        <option value="TV">Travessa</option>
                        <option value="ROD">Rodovia</option>
                        <option value="EST">Estrada</option>
                    </select>
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label style={{ color: missingRequiredFields['addressRoad'] && addressRoad == '' ? 'red' : '#464545' }} htmlFor="addressRoad"> Logradouro * </label>
                    <input style={{ border: missingRequiredFields['addressRoad'] && addressRoad == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressRoad' value={addressRoad} onChange={(e) => setAddressRoad(e.target.value)} className='bigInput' disabled={inEdit ? false : true} />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['addressRoad'] && addressRoad == '' ? 'red' : '#464545' }} htmlFor="addressRoad"> Logradouro * </label>
                    <input style={{ border: missingRequiredFields['addressRoad'] && addressRoad == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressRoad' value={addressRoad} onChange={(e) => setAddressRoad(e.target.value)} disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressNumber'] && addressNumber == '' ? 'red' : '#464545' }} htmlFor="addressNumber"> Número * </label>
                    <input style={{ border: missingRequiredFields['addressNumber'] && addressNumber == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="number" id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(e.target.value)} className='soSmallInputLittle' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="addressComplement"> Complemento </label>
                    <input type="text" id='addressComplement' value={addressComplement} maxLength={25} onChange={(e) => setAddressComplement(e.target.value)} className='mediumInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="phoneDdd"> DDD do Telefone </label>
                    <input type="text" id='phoneDdd' value={phoneDdd} onChange={(e) => setPhoneDdd(e.target.value)} className='soSmallInput' disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="phoneNumber"> Número do Telefone </label>
                    <input type="text" id='phoneNumber' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className='mediumInput' disabled={inEdit ? false : true} />
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="employeeEmail"> E-mail Particular </label>
                    <input type="text" id='employeeEmail' value={employeeEmail} onChange={(e) => setEmployeeEmail(e.target.value)} className='bigInput' disabled={inEdit ? false : true} />
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="employeeEmailCorporate"> E-mail Corporativo </label>
                    <input type="text" id='employeeEmailCorporate' value={employeeEmailCorporate} onChange={(e) => setEmployeeEmailCorporate(e.target.value)} className='bigInput' disabled={inEdit ? false : true} />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor="employeeEmail"> E-mail Particular </label>
                    <input type="text" id='employeeEmail' value={employeeEmail} onChange={(e) => setEmployeeEmail(e.target.value)} disabled={inEdit ? false : true} />
                </div>
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor="employeeEmailCorporate"> E-mail Corporativo </label>
                    <input type="text" id='employeeEmailCorporate' value={employeeEmailCorporate} onChange={(e) => setEmployeeEmailCorporate(e.target.value)} disabled={inEdit ? false : true} />
                </div>
                <div>
                    <label htmlFor="dateEmploymentExamination"> Data do exame Admissional </label>
                    <input type="date" id='dateEmploymentExamination' value={dateEmploymentExamination} onChange={(e) => setDateEmploymentExamination(e.target.value)} className='mediumInput' disabled={inEdit ? false : true} />
                </div>
            </div>
        )
    }

    function dependentsForm() {
        return (
            <div id='partForm' className='FormRequestContainer overflow-auto xl:h-full h-[65vh] top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                {
                    selectedForm == 4 ?
                        <></>
                        :
                        <div className="formDescription" >
                            <p className="obsFormRequestAdmission"> Todos os campos com (*) são obrigatórios</p>
                        </div>
                }
                {
                    insideLoading ?
                        (
                            <div className='insideLoadingForm'>
                                <div>
                                    <Loader />
                                </div>
                            </div>
                        ) : <></>
                }
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="motherName"> Nome da mãe do funcionário(a) * </label>
                    <input style={{ border: missingRequiredFields['motherName'] && motherName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='motherName' value={motherName} onChange={(e) => setMotherName(e.target.value)} className='bigInput' disabled={inEdit ? false : true} />
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor="fatherName"> Nome do pai do funcionário(a) </label>
                    <input type="text" id='fatherName' value={fatherName} onChange={(e) => setFatherName(e.target.value)} className='bigInput' disabled={inEdit ? false : true} />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor="motherName"> Nome da mãe do funcionário(a) * </label>
                    <input style={{ border: missingRequiredFields['motherName'] && motherName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='motherName' value={motherName} onChange={(e) => setMotherName(e.target.value)} disabled={inEdit ? false : true} />
                </div>
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor="fatherName"> Nome do pai do funcionário(a) </label>
                    <input type="text" id='fatherName' value={fatherName} onChange={(e) => setFatherName(e.target.value)} disabled={inEdit ? false : true} />
                </div>
                <div>
                    <div className='flex flex-col items-center justify-center'>
                        {
                            dependents?.map((dependent, index) => {
                                const id = `dependentType${index}`
                                return (
                                    <div className='dependentsContainer'>
                                        <div>
                                            <label style={{ color: missingRequiredFields['dependentType' + index] && (dependent['dependentType'] < 0 || dependent['dependentType'] == '') ? 'red' : '#464545' }} htmlFor={`dependentType${index}`}>Tipo *</label>
                                            <select style={{ border: missingRequiredFields['dependentType' + index] && (dependent['dependentType'] < 0 || dependent['dependentType'] == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} id={id} value={dependent.dependentType} onChange={(e) => { { dependent.dependentType = parseInt(e.target.value); setDependents([...dependents]) } }} disabled={true}>
                                                <option value="1">Filho</option>
                                                <option value="2">Cônjuge</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor={`dependentName${index}`}>Nome</label>
                                            <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id={`dependentName${index}`} value={dependent.name} onChange={(e) => { dependent.name = (e.target.value); setDependents([...dependents]) }} className='smallInput' disabled={inEdit ? false : true} maxLength={40}/>
                                        </div>
                                        <div className='disableFlexGrow'>
                                            <label htmlFor={`dependentCPF${index}`}>CPF</label>
                                            <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id={`dependentCPF${index}`} value={dependent.cpf} onChange={(e) => { dependent.cpf = (e.target.value); setDependents([...dependents]) }} className='soSmallInput' disabled={inEdit ? false : true} />
                                        </div>
                                        <div>
                                            <label htmlFor={`dependentIRRF${index}`}>Dep. de IRRF </label>
                                            <select id={`dependentIRRF${index}`} value={dependent.irrfDependent} onChange={(e) => { dependent.irrfDependent = (e.target.value == 'true'); setDependents([...dependents]) }} disabled={inEdit ? false : true}>
                                                <option value="true">Sim</option>
                                                <option value="false">Não</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor={`dependentHealthPlan${index}`}> Plano de Saúde</label>
                                            <select id={`dependentHealthPlan${index}`} value={dependent.healthPlan} onChange={(e) => { dependent.healthPlan = (e.target.value == 'true'); setDependents([...dependents]) }} disabled={inEdit ? false : true}>
                                                <option value="true">Sim</option>
                                                <option value="false">Não</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor={`dependentHasDeficient${index}`}>Deficiente</label>
                                            <select id={`dependentHasDeficient${index}`} value={dependent.hasDeficiency} onChange={(e) => { dependent.hasDeficiency = (e.target.value == 'true'); setDependents([...dependents]) }} disabled={inEdit ? false : true}>
                                                <option value="true">Sim</option>
                                                <option value="false">Não</option>
                                            </select>
                                        </div>
                                        <div>
                                            {
                                                dependent.hasDeficiency == true ?
                                                    (
                                                        <>
                                                            <label htmlFor={`dependentDeficient${index}`}>Deficiência *</label>
                                                            <select id={`dependentDeficient${index}`} value={dependent.deficiency} className='smallInput' onChange={(e) => { dependent.deficiency = (e.target.value); setDependents([...dependents]) }} disabled={inEdit ? false : true}>
                                                                <option value="0">- Selecione -</option>
                                                                <option value="1">Deficiente Visual</option>
                                                                <option value="2">Deficiente Fisica</option>
                                                                <option value="3">Deficiente Auditiva</option>
                                                                <option value="4">Deficiente Intelectual</option>
                                                                <option value="5">Deficiente Mental</option>
                                                                <option value="6">Deficiente Multipla</option>
                                                            </select>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <label htmlFor={`dependentDeficient${index}`}>Deficiência</label>
                                                            <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='laborUnion' value='Não aplicável' className="soSmallInput" disabled={true} />
                                                        </>
                                                    )
                                            }
                                        </div>
                                        <div>
                                            <label htmlFor={`dependentBirthday${index}`}>Data de Nascimento</label>
                                            <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type='date' className="soSmallInput" id={`dependentBirthday${index}`} value={dependent.birthday} onChange={(e) => { dependent.birthday = (e.target.value); setDependents([...dependents]) }} disabled={inEdit ? false : true} />
                                        </div>
                                        <div>
                                            <label htmlFor={`sexualGender${index}`}>Sexo *</label>
                                            <select id={`sexualGender${index}`} value={dependent.sexualGender} onChange={(e) => { dependent.sexualGender = e.target.value; setDependents([...dependents]) }} disabled>
                                                <option value="">- Selecione -</option>
                                                <option value="M">Masculino</option>
                                                <option value="F">Feminino</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor={`dependentCourtPension${index}`}>Pensão Judicial *</label>
                                            <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type='text' id={`dependentcourtPension${index}`} value={dependent.courtPension == false ? 'Não' : 'Sim'} className="soSmallInput" disabled={true} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    function connectToSeniorForm() {
        return (
            <>
                {
                    insideLoading ?
                        (
                            <div className='insideLoadingForm '>
                                <div>
                                    <Loader />
                                </div>
                            </div>
                        ) : <></>
                }
                <div className='flex flex-col w-full p-1 h-[32rem] overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 pb-14 lg:pb-14 2xl:pb-0'>

                    <div className='flex w-full justify-center items-center text-black pb-5 mt-4'>
                        <div className='w-full flex text-[#311962] justify-center items-center'>
                            <h2 className='text-xl font-black'>Folpag Connect</h2>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <h2 className='text-green-700 text-xl font-black'>Senior</h2>
                        </div>
                    </div>
                    <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'><i className='font-semibold'>Observação do cliente</i></p>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-[#311962]'>
                            <div className='w-[90%] flex justify-center items-center'>
                                <p className='text-center font-semibold text-base'>{observation ? <i>{observation}</i> : <i>Nenhuma observação</i>}</p>
                            </div>
                        </div>
                    </div>
                    <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'>  Informe um   <i className='font-semibold'>Número de Cadastro</i> disponivel no Senior </p>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <Input
                                type="number"
                                value={employeeIdSenior}
                                onChange={(e) => { setEmployeeIdSenior(parseInt(e.target.value)) }}   
                                // width={80}                                    
                                />
                            </LabelInput>
                        </div>
                    </div>
                    {
                        companyConfig?.numberTag == 'S' &&
                        <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                            <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'>
                                Número do Crachá:
                            </p>
                            </div>
                            <div>
                                <FiChevronRight className='w-[30px] text-black' />
                            </div>
                            <div className='w-full flex justify-center items-center text-black'>
                                <LabelInput>
                                    <Input
                                        onChange={(e) => {
                                            setNumberTag(justNumber(e?.target.value))
                                        }}
                                        type={'text'}                                        
                                        placeholder={'Informe o número do crachá *'}
                                        id='numberTag'
                                        value={numberTag ? numberTag : ''}
                                        charLimit={12}
                                    />
                                </LabelInput>
                            </div>
                        </div>
                    }
                    <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'>Tipo Vínculo: <i className='font-semibold'>{typesLinkList?.filter(type => type?.value == typeBusinessLink)[0]?.description}</i></p>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <InputAutoComplete
                                    data={typesLinkList}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    onChange={(e) => {
                                        if (e.value == '11'){
                                            setConfirmedTypeSenior(e)
                                            setIsIntermittent(true)
                                        } else if (e?.value == '90'){
                                            setIsIntermittent(false)
                                            setConfirmedTypeSenior(e)
                                            setSelectedForm(1)
                                            setInEdit(true)
                                        } else {
                                            setIsIntermittent(false)
                                            setConfirmedTypeSenior(e)
                                        }
                                    }}
                                    value={confirmedTypeSenior ? confirmedTypeSenior?.description : ''}
                                    placeHolder='Selecione um Vínculo'
                                />
                            </LabelInput>
                        </div>
                    </div>
                    {
                        typeBusinessLink == 90 &&
                        <>                            
                            <div className='w-full hidden lg:flex justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'> Estágio - Instituição de Ensino: <i className='font-semibold'>{traineeEducationalInstitution ? traineeEducationalInstitution : ''}</i></p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={educationalInstitutionList}
                                            selectedLabel={'nomoem'}
                                            optionList={['codoem', 'nomoem']}
                                            onChange={(e) => {
                                                setTraineeSelectedInstitutionSenior(e)
                                            }}
                                            preSelectedValue={traineeSelectedInstitutionSenior ? traineeSelectedInstitutionSenior?.nomoem : ''}
                                            placeHolder='Instituição de Ensino *'
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='w-full hidden lg:flex justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'> Estágio - Supervisor: <i className='font-semibold'>{traineeCodSupervisor ? traineeCodSupervisor : ''}</i></p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={persons}
                                            selectedLabel={'nomfun'}                                            
                                            optionList={['numcad','nomfun']}
                                            value={traineeSelectedSupervisorSenior ? traineeSelectedSupervisorSenior?.nomfun : ''}
                                            onChange={(e) => {
                                                setTraineeSelectedSupervisorSenior(e)
                                            }}                                            
                                            placeHolder='Supervisor *'
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                        </>
                    }
                    {
                        typeBusinessLink == 55 ?
                        <>
                            <div className='w-full hidden lg:flex justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>Informado pelo cliente: {cnpjMask(apprenticeInstitutionCNPJ)} - {apprenticeInstitutionName}</p>
                                </div>
                            </div>
                            <div className='w-full hidden lg:flex justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>Indicativo de Contratação</p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={[{id: 1, description: 'Contratação direta'}, {id: 2, description: 'Contratação indireta'}]}
                                            selectedLabel={'description'}
                                            optionList={['id', 'description']}
                                            onChange={(e) => {
                                                setApprenticeIndirectRegistration('')
                                                setApprenticeCnpjIndirectRegistration('')
                                                setApprenticeEntityQualify('')
                                                setApprenticeCnpjEntityQualify('')
                                                setApprenticeCnpjPracticalActivity('')
                                                setApprenticeTypeContract(e)
                                            }}
                                            value={apprenticeTypeContract ? apprenticeTypeContract?.description : ''}
                                            placeHolder='Selecione uma opção *'
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            {
                                apprenticeTypeContract && apprenticeTypeContract?.id == 2 ?
                                <>
                                    <div className='w-full hidden lg:flex justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                        <div className='w-full flex items-center justify-center text-[#311962]'>
                                            <p className='text-base'>Código da Outra Empresa Contratação Indireta</p>
                                        </div>
                                        <div>
                                            <FiChevronRight className='w-[30px] text-black' />
                                        </div>
                                        <div className='w-full flex justify-center items-center text-black'>
                                            <LabelInput>
                                                <InputAutoComplete
                                                    data={othersCompanyList}
                                                    selectedLabel={'nomoem'}
                                                    optionList={['numcgc', 'nomoem']}
                                                    onChange={(e) => {
                                                        setApprenticeIndirectRegistration(e)
                                                        setApprenticeCnpjIndirectRegistration(e?.numcgc)
                                                    }}
                                                    value={apprenticeIndirectRegistration ? apprenticeIndirectRegistration?.nomoem : ''}
                                                    preSelectedValue={apprenticeIndirectRegistration ? apprenticeIndirectRegistration?.nomoem : ''}
                                                    placeHolder='Selecione uma empresa *'
                                                />
                                            </LabelInput>
                                        </div>
                                    </div>
                                    <div className='w-full hidden lg:flex justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                        <div className='w-full flex items-center justify-center text-[#311962]'>
                                            <p className='text-base'>Número da Inscrição do Contratante Indireto do Aprendiz</p>
                                        </div>
                                        <div>
                                            <FiChevronRight className='w-[30px] text-black' />
                                        </div>
                                        <div className='w-full flex justify-center items-center text-black'>
                                            <LabelInput>
                                                <Input
                                                    onChange={(e) => {
                                                        setApprenticeCnpjIndirectRegistration(cnpjMask(e?.target.value))
                                                    }}
                                                    type={'text'}                                        
                                                    placeholder={'Informe o CNPJ *'}
                                                    id='apprenticeCnpjIndirectRegistration'
                                                    value={apprenticeCnpjIndirectRegistration ? cnpjMask(apprenticeCnpjIndirectRegistration) : ''}
                                                    charLimit={30}
                                                />
                                            </LabelInput>
                                        </div>
                                    </div>
                                </>
                                : apprenticeTypeContract && apprenticeTypeContract?.id == 1 ?
                                <>
                                    <div className='w-full hidden lg:flex justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                        <div className='w-full flex items-center justify-center text-[#311962]'>
                                            <p className='text-base'>Código da Outra Empresa Entidade Qualificadora</p>
                                        </div>
                                        <div>
                                            <FiChevronRight className='w-[30px] text-black' />
                                        </div>
                                        <div className='w-full flex justify-center items-center text-black'>
                                            <LabelInput>
                                                <InputAutoComplete
                                                    data={othersCompanyList}
                                                    selectedLabel={'nomoem'}
                                                    optionList={['numcgc', 'nomoem']}
                                                    onChange={(e) => {
                                                        setApprenticeEntityQualify(e)
                                                        setApprenticeCnpjEntityQualify(e?.numcgc)
                                                    }}
                                                    value={apprenticeEntityQualify ? apprenticeEntityQualify?.nomoem : ''}
                                                    preSelectedValue={apprenticeEntityQualify ? apprenticeEntityQualify?.nomoem : ''}
                                                    placeHolder='Selecione uma empresa *'
                                                />
                                            </LabelInput>
                                        </div>
                                    </div>
                                    <div className='w-full hidden lg:flex justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                        <div className='w-full flex items-center justify-center text-[#311962]'>
                                            <p className='text-base'>CNPJ Entidade Qualificadora</p>
                                        </div>
                                        <div>
                                            <FiChevronRight className='w-[30px] text-black' />
                                        </div>
                                        <div className='w-full flex justify-center items-center text-black'>
                                            <LabelInput>
                                                <Input
                                                    onChange={(e) => {
                                                        setApprenticeCnpjEntityQualify(cnpjMask(e?.target.value))
                                                    }}
                                                    type={'text'}                                        
                                                    placeholder={`Informe o CNPJ ${apprenticeTypeContract?.id == 1 ? '*' : ''}`}
                                                    id='apprenticeCnpjEntityQualify'
                                                    value={apprenticeCnpjEntityQualify ? cnpjMask(apprenticeCnpjEntityQualify) : ''}
                                                    charLimit={30}
                                                />
                                            </LabelInput>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                            }                            
                            <div className='w-full hidden lg:flex justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>CNPJ Atividades Práticas</p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <Input
                                            onChange={(e) => {
                                                setApprenticeCnpjPracticalActivity(cnpjMask(e?.target.value))
                                            }}
                                            type={'text'}                                        
                                            placeholder={'Informe o CNPJ (opcional)'}
                                            id='apprenticeCnpjPracticalActivity'
                                            value={apprenticeCnpjPracticalActivity ? cnpjMask(apprenticeCnpjPracticalActivity) : ''}
                                            charLimit={30}
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                    <div className='hidden lg:flex w-full justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <button type='button' className='p-2 border border-[#311962] bg-[#311962] text-white rounded-sm cursor-pointer duration-200 hover:bg-white hover:text-[#311962]' onClick={() => setShow(true)}>Ver escala informada pelo cliente</button>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                            <div className='w-full flex flex-col justify-center gap-1 items-center text-black'>
                                <a 
                                    className='underline text-primaryDefaultLight cursor-pointer duration-100 hover:text-bgSecondaryLight' 
                                    onClick={() => defineDataOnTableImport('schedule')}
                                > 
                                    Selecione uma Escala *
                                </a>
                                <i className='text-base text-primaryDefaultLight'>{scheduleSeniorObject?.scheduleName ?? 'Não selecionada'}</i>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={schedulesTeams}
                                            selectedLabel={'scheduleTeamName'}
                                            optionList={['scheduleTeamName']}
                                            onChange={(e) => {
                                                setScheduleTeamSenior(e.scheduleTeam)
                                                setScheduleTeamSeniorObject(e)
                                            }}
                                            preSelectedValue={scheduleTeamSeniorObject?.scheduleTeamName ? scheduleTeamSeniorObject?.scheduleTeamName : ''}
                                            placeHolder='Selecione uma Turma *'
                                        />
                                    </LabelInput>
                            </div>
                    </div>
                    <div className='hidden lg:flex w-full justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base flex gap-2 items-center'>                                                                                            
                                Cargo:  
                                <i className='font-semibold'>{employeePosition}</i>
                                {
                                    warningWords.some(word => removeAccents(employeePosition.toLowerCase()).includes(word)) &&
                                    <Tippy
                                        content={'Verifique se o vínculo está correto'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={80}>
                                        <div>
                                            <FaExclamationTriangle className='text-orange-500 cursor-help'/>
                                        </div>
                                    </Tippy>                                
                                }
                            </p>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex flex-col gap-1 justify-center items-center text-black'>
                            <a 
                                className='underline text-primaryDefaultLight cursor-pointer duration-100 hover:text-bgSecondaryLight' 
                                onClick={() => defineDataOnTableImport('jobPosition')}
                            > 
                                Selecione um Cargo *
                            </a>
                            <i className='text-base text-primaryDefaultLight'>{jobPositionSeniorObject?.jobPositionName ?? 'Não selecionado'}</i>
                        </div>
                    </div>
                    <div className='hidden lg:flex w-full justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'> Local Organograma: <i className='font-semibold'>{employeeLocal}</i> </p>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex flex-col gap-1 justify-center items-center text-black'>
                            <a 
                                className='underline text-primaryDefaultLight cursor-pointer duration-100 hover:text-bgSecondaryLight' 
                                onClick={() => defineDataOnTableImport('local')}
                            > 
                                Selecione um Local *
                            </a>
                            <i className='text-base text-primaryDefaultLight'>{localSeniorObject?.localName ?? 'Não selecionado'}</i>
                        </div>
                    </div>
                    <div className='hidden lg:flex w-full justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'> Centro de Custos:  <i className='font-semibold'>{employeeCCU == '-' ? 'Nada Declarado' : employeeCCU}</i> </p>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <InputAutoComplete
                                    data={ccu}
                                    selectedLabel={'ccuName'}
                                    optionList={['ccuName']}
                                    onChange={(e) => {
                                        setCcuSenior(e.ccuId)
                                        setCcuSeniorObject(e)
                                        setCcuOriginSenior(e.originCcu)
                                    }}
                                    preSelectedValue={ccuSeniorObject?.ccuName ? ccuSeniorObject?.ccuName : ''}
                                    placeHolder='Selecione um Centro de Custos'
                                    // width={80}
                                />
                            </LabelInput>
                        </div>
                    </div>
                    <div className='hidden lg:flex w-full justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'> Posto de Trabalho: <i className='font-semibold'>{employeeWorkplace == '-' ? 'Nada declarado' : employeeWorkplace}</i></p>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <InputAutoComplete
                                    data={workplaces}
                                    selectedLabel={'workplaceDescription'}
                                    optionList={['workplaceDescription']}
                                    onChange={(e) => {
                                        setWorkplaceSenior(e.workplace)
                                        setWorkplaceSeniorObject(e)
                                        setWorkplaceSeniorStructure(e.workplaceStructure)
                                    }}
                                    preSelectedValue={workplaceSeniorObject?.workplaceDescription ? workplaceSeniorObject?.workplaceDescription : ''}
                                    placeHolder='Selecione um Posto *'
                                    // width={80}
                                />
                            </LabelInput>
                        </div>
                    </div>
                    <div className='hidden lg:flex w-full justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'> Sindicato: <i className='font-semibold'>{laborUnion == '-' ? 'Nada Declarado' : laborUnion}</i></p>
                        </div>
                        <div>
                            <FiChevronRight className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex flex-col gap-1 justify-center items-center text-black'>
                            <a 
                                className='underline text-primaryDefaultLight cursor-pointer duration-100 hover:text-bgSecondaryLight' 
                                onClick={() => defineDataOnTableImport('laborUnion')}
                            > 
                                Selecione um Sindicato
                            </a>
                            <i className='text-base text-primaryDefaultLight'>{laborUnionSeniorObject?.laborUnionDescription ?? 'Não selecionado'}</i>
                        </div>
                    </div>
                    {
                        // Para Focus 656, 1071 e a FHA 1763 (grupo Focus) - Olhar natureza de despesas e adicionar dois novos campos
                        companyConfig?.useNatureExpenses == 'S' && [656, 1071, 1763]?.includes(company) &&
                        <>
                            <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>Ficha de Registro:</p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <Input
                                            type="number"
                                            value={tokenRegistry}
                                            onChange={(e) => { setTokenRegistry(parseInt(e.target.value)) }}
                                            id="tokenRegistry"
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>Código da Chapeira:</p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <Input
                                            type="number"
                                            value={codPlate}
                                            onChange={(e) => { setCodPlate(parseInt(e.target.value)) }} 
                                            id="codPlate"
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                        </>
                    }
                    {
                        companyConfig?.useNatureExpenses == 'S' &&
                        <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                            <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'>Natureza Despesa: <i className='font-semibold'>{natureExpense ? `${natureExpense} - ${natureExpenseList.filter(item => item?.natdes == natureExpense)[0]?.nomnat}` : ''}</i></p>
                            </div>
                            <div>
                                <FiChevronRight className='w-[30px] text-black' />
                            </div>
                            <div className='w-full flex justify-center items-center text-black'>
                                <LabelInput>
                                    <InputAutoComplete
                                        data={natureExpenseList}
                                        selectedLabel={'nomnat'}
                                        optionList={['natdes', 'nomnat']}
                                        onChange={(e) => {
                                            setNatureExpenseSeniorObj(e)
                                            setNatureExpenseSenior(e?.natdes)
                                        }}
                                        id='natureExpenseSenior'
                                        preSelectedValue={natureExpenseSeniorObj?.nomnat ? natureExpenseSeniorObj?.nomnat : ''}
                                        placeHolder='Selecione uma Natureza *'
                                    />
                                </LabelInput>
                            </div>
                        </div>
                    }
                    {
                        companyConfig?.addHistory == 'S' &&
                        <>
                            <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                <p className='text-base'>
                                    Insalubridade: 
                                    <i className='font-semibold'>{insalubrity ? ` Sim - ${insalubrityPercentage ? insalubrityPercentage : 0}%` : ' Não'}</i> 
                                </p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <Input
                                            onChange={(e) => {
                                                setInsalubritySenior(justNumber(e?.target.value))
                                            }}
                                            type={'text'}
                                            placeholder={'Informe a insalubridade *'}
                                            id='insalubritySenior'
                                            value={insalubritySenior ? insalubritySenior : ''}
                                            charLimit={3}
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>
                                        Periculosidade: 
                                        <i className='font-semibold'>{periculosity ? ` Sim - ${periculosityPercentage ? periculosityPercentage : 0}%` : ' Não'}</i> 
                                    </p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <Input
                                            onChange={(e) => {
                                                setDangerSenior(justNumber(e?.target.value))
                                            }}
                                            type={'text'}
                                            placeholder={'Informe a periculosidade *'}
                                            id='dangerSenior'
                                            value={dangerSenior ? dangerSenior : ''}
                                            charLimit={3}
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                <p className='text-base'>Aposentadoria Especial:</p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={specialRetirementList}
                                            selectedLabel={'value'}
                                            optionList={['id', 'value']}
                                            onChange={(e) => {
                                                setSpecialRetirementSenior(e)
                                            }}
                                            id='natureExpenseSenior'
                                            preSelectedValue={specialRetirementSenior ? specialRetirementSenior?.value : ''}
                                            placeHolder='Selecione uma Aposentadoria Especial *'
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                        </>
                    }
                    {
                        paymentMethod != 'D' &&
                        <>
                            <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>Agência do colaborador: <i className='font-semibold'>{employeBankAgencie}</i></p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex flex-col gap-1 justify-center items-center text-black'>
                                    <a 
                                        className='underline text-primaryDefaultLight cursor-pointer duration-100 hover:text-bgSecondaryLight' 
                                        onClick={() => defineDataOnTableImport('agencies')}
                                    >
                                        Selecione uma Agência *
                                    </a>
                                    <i className='text-base text-primaryDefaultLight'>{employeeAgencySeniorObj?.agency_id ? `${employeeAgencySeniorObj?.agency_id} - ${employeeAgencySeniorObj?.agency_digit}` : 'Não selecionada'}</i>
                                </div>
                            </div>
                            <div className='hidden lg:flex w-full justify-center items-center text-black border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'><i className='font-semibold'>Dígito da Agência</i></p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-[#311962]'>
                                    <div className='w-[90%] flex justify-center items-center'>
                                        <p className='text-center font-semibold text-base'>{digitAgency ? <i>{digitAgency}</i> : <i>Nenhum dígito informado</i>}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {/* Mobile */}
                    <div className='w-full gap-2 flex flex-col lg:hidden justify-center items-center text-black border-t border-t-[#cecece] p-5' id='onlyMobile'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'><i className='font-semibold'>Observação do cliente</i></p>
                        </div>
                        <div>
                            <FiChevronDown className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-[#311962]'>
                            <div className='w-[90%] flex justify-center items-center'>
                                <p className='text-center font-semibold text-base'>{observation ? <i>{observation}</i> : <i>Nenhuma observação</i>}</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full gap-2 flex flex-col lg:hidden justify-center items-center text-black border-t border-t-[#cecece] p-5' id='onlyMobile'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base text-center'>  Informe um   <i className='font-semibold'>Número de Cadastro</i> disponivel no Senior </p>
                        </div>
                        <div>
                            <FiChevronDown className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <Input
                                type="number"
                                value={employeeIdSenior}
                                onChange={(e) => { setEmployeeIdSenior(parseInt(e.target.value)) }}   
                                // width={80}                                    
                                />
                            </LabelInput>
                        </div>
                    </div>
                    {
                        companyConfig?.numberTag == 'S' &&
                        <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                            <div className='w-full flex items-center justify-center text-[#311962]'>
                                <p className='text-base'>
                                    Número do Crachá:
                                </p>
                            </div>
                            <div>
                                <FiChevronDown className='w-[30px] text-black' />
                            </div>
                            <div className='w-full flex justify-center items-center text-black'>
                                <LabelInput>
                                    <Input
                                        onChange={(e) => {
                                            setNumberTag(justNumber(e?.target.value))
                                        }}
                                        type={'text'}
                                        placeholder={'Informe o número do crachá *'}
                                        id='numberTag'
                                        value={numberTag ? numberTag : ''}
                                        charLimit={12}
                                    />
                                </LabelInput>
                            </div>
                        </div>
                    }
                    <div className='w-full gap-2 flex flex-col lg:hidden justify-center items-center text-black border-t border-t-[#cecece] p-5' id='onlyMobile'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'>Tipo Vínculo: <i className='font-semibold'>{typesLinkList?.filter(type => type?.value == typeBusinessLink)[0]?.description}</i></p>
                        </div>
                        <div>
                            <FiChevronDown className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <InputAutoComplete
                                    data={typesLinkList}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    onChange={(e) => {
                                        if (e.value == '11'){
                                            setConfirmedTypeSenior(e)
                                            setIsIntermittent(true)
                                        } else {
                                            setIsIntermittent(false)
                                            setConfirmedTypeSenior(e)
                                        }
                                    }}
                                    value={confirmedTypeSenior ? confirmedTypeSenior?.description : ''}
                                    placeHolder='Selecione um Vínculo'
                                />
                            </LabelInput>
                        </div>
                    </div>
                    {
                         typeBusinessLink == 90 || confirmedTypeSenior?.value == 90 &&
                         <>
                            <div className='w-full flex flex-col lg:hidden justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'> Estágio - Instituição de Ensino: <i className='font-semibold'>{traineeEducationalInstitution ? traineeEducationalInstitution : ''}</i></p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={educationalInstitutionList}
                                            selectedLabel={'nomoem'}
                                            optionList={['codoem', 'nomoem']}
                                            onChange={(e) => {
                                                setTraineeSelectedInstitutionSenior(e)
                                            }}
                                            preSelectedValue={traineeSelectedInstitutionSenior ? traineeSelectedInstitutionSenior?.nomoem : ''}
                                            placeHolder='Instituição de Ensino *'
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='w-full flex flex-col lg:hidden justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'> Estágio - Supervisor: <i className='font-semibold'>{traineeCodSupervisor ? traineeCodSupervisor : ''}</i></p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={persons}
                                            selectedLabel={'nomfun'}                                            
                                            optionList={['numcad','nomfun']}
                                            value={traineeSelectedSupervisorSenior ? traineeSelectedSupervisorSenior?.nomfun : ''}
                                            onChange={(e) => {
                                                setTraineeSelectedSupervisorSenior(e)
                                            }}                                            
                                            placeHolder='Supervisor *'
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                         </>
                    } 
                    <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <button type='button' className='p-2 border border-[#311962] bg-[#311962] text-white rounded-sm cursor-pointer duration-200 hover:bg-white hover:text-[#311962]' onClick={() => setShow(true)}>Ver escala informada pelo cliente</button>
                        </div>
                        <div>
                            <FiChevronDown className='w-[30px] text-black' />
                        </div>
                            <div className='w-full flex flex-col justify-center items-center text-black'>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={schedules}
                                            selectedLabel={'scheduleName'}
                                            optionList={['scheduleName']}
                                            onChange={(e) => {
                                                setScheduleSeniorObject(e)
                                                setScheduleSenior(e.scheduleId)
                                            }}
                                            preSelectedValue={scheduleSeniorObject?.scheduleName ? scheduleSeniorObject?.scheduleName : ''}
                                            placeHolder='Selecione uma Escala *'
                                            // width={80}
                                        />
                                    </LabelInput>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={schedulesTeams}
                                            selectedLabel={'scheduleTeamName'}
                                            optionList={['scheduleTeamName']}
                                            onChange={(e) => {
                                                setScheduleTeamSenior(e.scheduleTeam)
                                                setScheduleTeamSeniorObject(e)
                                            }}
                                            preSelectedValue={scheduleTeamSeniorObject?.scheduleTeamName ? scheduleTeamSeniorObject?.scheduleTeamName : ''}
                                            placeHolder='Selecione uma Turma *'
                                            // width={80}
                                        />
                                    </LabelInput>
                            </div>
                    </div>
                    <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base flex gap-2 items-center'> 
                                Cargo:  
                                <i className='font-semibold'>{employeePosition}</i>
                                {
                                    warningWords.some(word => removeAccents(employeePosition.toLowerCase()).includes(word)) &&
                                    <Tippy
                                        content={'Verifique se o vínculo está correto'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={80}>
                                        <div>
                                            <FaExclamationTriangle className='text-orange-500'/>
                                        </div>
                                    </Tippy>                                
                                }
                            </p>
                        </div>
                        <div>
                            <FiChevronDown className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <InputAutoComplete
                                    data={jobPositions}
                                    selectedLabel={'jobPositionName'}
                                    optionList={['jobPositionName']}
                                    onChange={(e) => {
                                        setJobPositionSenior(e.jobPositionId)
                                        setJobPositionSeniorObject(e)
                                        setJobPositionEstructureSenior(parseInt(e.jobStructure))
                                    }}
                                    preSelectedValue={jobPositionSeniorObject?.jobPositionName ? jobPositionSeniorObject?.jobPositionName : ''}
                                    placeHolder='Selecione um Cargo *'
                                    // width={80}
                                />
                            </LabelInput>
                        </div>
                    </div>
                    <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'> Local Organograma: <i className='font-semibold'>{employeeLocal}</i> </p>
                        </div>
                        <div>
                            <FiChevronDown className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <InputAutoComplete
                                    data={local}
                                    selectedLabel={'localName'}
                                    optionList={['localName']}
                                    onChange={(e) => {
                                        setLocalSenior(e.localId)
                                        setLocalSeniorObject(e)
                                        setLocalOrganizationSenior(e.localOrganization)
                                    }}
                                    preSelectedValue={localSeniorObject?.localName ? localSeniorObject?.localName : ''}
                                    placeHolder='Selecione um Local *'
                                    // width={80}
                                />
                            </LabelInput>
                        </div>
                    </div>
                    <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'> Centro de Custos:  <i className='font-semibold'>{employeeCCU == '-' ? 'Nada Declarado' : employeeCCU}</i> </p>
                        </div>
                        <div>
                            <FiChevronDown className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <InputAutoComplete
                                    data={ccu}
                                    selectedLabel={'ccuName'}
                                    optionList={['ccuName']}
                                    onChange={(e) => {
                                        setCcuSenior(e.ccuId)
                                        setCcuSeniorObject(e)
                                        setCcuOriginSenior(e.originCcu)
                                    }}
                                    preSelectedValue={ccuSeniorObject?.ccuName ? ccuSeniorObject?.ccuName : ''}
                                    placeHolder='Selecione um Centro de Custos'
                                    // width={80}
                                />
                            </LabelInput>
                        </div>
                    </div>
                    <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'> Posto de Trabalho: <i className='font-semibold'>{employeeWorkplace == '-' ? 'Nada declarado' : employeeWorkplace}</i></p>
                        </div>
                        <div>
                            <FiChevronDown className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <InputAutoComplete
                                    data={workplaces}
                                    selectedLabel={'workplaceDescription'}
                                    optionList={['workplaceDescription']}
                                    onChange={(e) => {
                                        setWorkplaceSenior(e.workplace)
                                        setWorkplaceSeniorObject(e)
                                        setWorkplaceSeniorStructure(e.workplaceStructure)
                                    }}
                                    preSelectedValue={workplaceSeniorObject?.workplaceDescription ? workplaceSeniorObject?.workplaceDescription : ''}
                                    placeHolder='Selecione um Posto *'
                                    // width={80}
                                />
                            </LabelInput>
                        </div>
                    </div>
                    <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                        <div className='w-full flex items-center justify-center text-[#311962]'>
                            <p className='text-base'> Sindicato: <i className='font-semibold'>{laborUnion == '-' ? 'Nada Declarado' : laborUnion}</i></p>
                        </div>
                        <div>
                            <FiChevronDown className='w-[30px] text-black' />
                        </div>
                        <div className='w-full flex justify-center items-center text-black'>
                            <LabelInput>
                                <InputAutoComplete
                                    data={laborUnionList}
                                    selectedLabel={'laborUnionDescription'}
                                    optionList={['laborUnionDescription']}
                                    onChange={(e) => {
                                        setLaborUnionSenior(e.laborUnionId)
                                        setLaborUnionSeniorObject(e)
                                    }}
                                    preSelectedValue={laborUnionSeniorObject?.laborUnionDescription ? laborUnionSeniorObject?.laborUnionDescription : ''}
                                    placeHolder='Selecione um Sindicato'
                                    // width={80}
                                />
                            </LabelInput>
                        </div>
                    </div>
                    {
                        paymentMethod != 'D' &&
                        <>
                            <div className='w-full gap-2 flex flex-col lg:hidden justify-center items-center text-black border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>Agência do colaborador: <i className='font-semibold'>{employeBankAgencie}</i></p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={agencies}
                                            selectedLabel={'agency_id'}
                                            optionList={['agency_id']}
                                            onChange={(e) => {
                                                setEmployeeAgencySeniorObj(e)
                                                setEmployeeAgencySenior(e?.agency_id)
                                            }}
                                            preSelectedValue={employeeAgencySeniorObj?.agency_id ? employeeAgencySeniorObj?.agency_id : ''}
                                            placeHolder='Selecione uma Agência *'
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='w-full gap-2 flex flex-col lg:hidden justify-center items-center text-black border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'><i className='font-semibold'>Dígito da Agência</i></p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-[#311962]'>
                                    <div className='w-[90%] flex justify-center items-center'>
                                        <p className='text-center font-semibold text-base'>{digitAgency ? <i>{digitAgency}</i> : <i>Nenhum dígito informado</i>}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        companyConfig?.useNatureExpenses == 'S' && [656, 1071, 1763]?.includes(company) &&
                        <>
                            <div className='w-full gap-2 flex flex-col lg:hidden justify-center items-center text-black border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base text-center'>  Ficha de Registro </p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <Input
                                        type="number"
                                        value={tokenRegistry}
                                        onChange={(e) => { setTokenRegistry(parseInt(e.target.value)) }}   
                                        // width={80}                                    
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='w-full gap-2 flex flex-col lg:hidden justify-center items-center text-black border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base text-center'>  Código da Chapeira </p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <Input
                                        type="number"
                                        value={codPlate}
                                        onChange={(e) => { setCodPlate(parseInt(e.target.value)) }}   
                                        // width={80}                                    
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                        </>
                    }
                    {
                        companyConfig?.useNatureExpenses == 'S' &&
                        <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                            <div className='w-full flex items-center justify-center text-[#311962]'>
                                <p className='text-base'>Natureza Despesa: <i className='font-semibold'>{natureExpense ? `${natureExpense} - ${natureExpenseList.filter(item => item?.natdes == natureExpense)[0]?.nomnat}` : ''}</i></p>
                            </div>
                            <div>
                                <FiChevronDown className='w-[30px] text-black' />
                            </div>
                            <div className='w-full flex justify-center items-center text-black'>
                                <LabelInput>
                                    <InputAutoComplete
                                        data={natureExpenseList}
                                        selectedLabel={'nomnat'}
                                        optionList={['natdes', 'nomnat']}
                                        onChange={(e) => {
                                            setNatureExpenseSeniorObj(e)
                                            setNatureExpenseSenior(e?.natdes)
                                        }}
                                        preSelectedValue={natureExpenseSeniorObj?.nomnat ? natureExpenseSeniorObj?.nomnat : ''}
                                        placeHolder='Selecione uma Natureza *'
                                    />
                                </LabelInput>
                            </div>
                        </div>
                    }
                    {
                        companyConfig?.addHistory == 'S' &&
                        <>
                            <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>
                                        Insalubridade:
                                        <i className='font-semibold'>{insalubrity ? ` Sim - ${insalubrityPercentage ? insalubrityPercentage : 0}%` : ' Não'}</i> 
                                    </p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <Input
                                            onChange={(e) => {
                                                setInsalubritySenior(justNumber(e?.target.value))
                                            }}
                                            type={'text'}
                                            placeholder={'Informe a insalubridade *'}
                                            id='insalubritySenior'
                                            value={insalubritySenior ? insalubritySenior : ''}
                                            charLimit={3}
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>
                                        Periculosidade:
                                        <i className='font-semibold'>{periculosity ? ` Sim - ${periculosityPercentage ? periculosityPercentage : 0}%` : ' Não'}</i> 
                                    </p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <Input
                                            onChange={(e) => {
                                                setDangerSenior(justNumber(e?.target.value))
                                            }}
                                            type={'text'}
                                            placeholder={'Informe a periculosidade *'}
                                            id='dangerSenior'
                                            value={dangerSenior ? dangerSenior : ''}
                                            charLimit={3}
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='w-full gap-2 flex flex-col lg:hidden  justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'>Aposentadoria Especial:</p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                    <LabelInput>
                                        <InputAutoComplete
                                            data={specialRetirementList}
                                            selectedLabel={'value'}
                                            optionList={['id', 'value']}
                                            onChange={(e) => {
                                                setSpecialRetirementSenior(e)
                                            }}
                                            id='natureExpenseSenior'
                                            preSelectedValue={specialRetirementSenior ? specialRetirementSenior?.value : ''}
                                            placeHolder='Selecione uma Aposentadoria Especial *'
                                        />
                                    </LabelInput>
                                </div>
                            </div>
                        </>
                    }
                    {
                        !employeeVT ? <></> :
                        <>
                            <div className='hidden lg:flex w-full justify-center items-center text-black  border-t border-t-[#cecece] p-5'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'> Usa Vale Transporte: <i className='font-semibold'>{!employeeVT ? 'Não' : 'Sim'}</i> </p>
                                </div>
                                <div>
                                    <FiChevronRight className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                <LabelInput>
                                    <InputAutoComplete
                                        data={stopoverTransportList}
                                        selectedLabel={'stopoverTransportName'}
                                        optionList={['stopoverTransportName']}
                                        onChange={(e) => {
                                            setStopoverTransportSenior(e.stopoverTransport)
                                            setStopoverTransportObject(e)
                                        }}
                                        preSelectedValue={stopoverTransportObject?.stopoverTransportName ? stopoverTransportObject?.stopoverTransportName : ''}
                                        placeHolder='Escala de Vale Trasporte *'
                                        // width={80}
                                    />
                                </LabelInput>
                                </div>
                            </div>
                            {/* Mobile */}
                            <div className='w-full gap-2 flex flex-col lg:hidden justify-center items-center text-black  border-t border-t-[#cecece] p-5' id='onlyMobile'>
                                <div className='w-full flex items-center justify-center text-[#311962]'>
                                    <p className='text-base'> Usa Vale Transporte: <i className='font-semibold'>{!employeeVT ? 'Não' : 'Sim'}</i> </p>
                                </div>
                                <div>
                                    <FiChevronDown className='w-[30px] text-black' />
                                </div>
                                <div className='w-full flex justify-center items-center text-black'>
                                <LabelInput>
                                    <InputAutoComplete
                                        data={stopoverTransportList}
                                        selectedLabel={'stopoverTransportName'}
                                        optionList={['stopoverTransportName']}
                                        onChange={(e) => {
                                            setStopoverTransportSenior(e.stopoverTransport)
                                            setStopoverTransportObject(e)
                                        }}
                                        preSelectedValue={stopoverTransportObject?.stopoverTransportName ? stopoverTransportObject?.stopoverTransportName : ''}
                                        placeHolder='Escala de Vale Trasporte *'
                                        // width={80}
                                    />
                                </LabelInput>
                                </div>
                            </div>
                        </>
                    } 
                </div>
            </>
        )
    }

    const showAlertPreliminary = async () => {
        setModalAlertPreliminary(true)
        return new Promise((resolve) => {
            const handleOkClick = () => {
                setModalAlertPreliminary(false)
                resolve()
            }
        
            document.getElementById("alert-ok-button").addEventListener("click", handleOkClick, { once: true })
        })
    }

    async function handleSubmitSenior() {

        if (inEdit) {
            setShowModalApply(true)
            return setShow(true)
        }

        if (!employeeIdSenior){
            setShowErrorEmployeeIdSenior(true)
            return setShow(true)
        }

        const validateAllFills = await checkFillTabs(4)

        if (!validateAllFills) {
            return
        }

        try {

            if (typeBusinessLink == 90 || confirmedTypeSenior?.value == 90){
                if (!traineeSelectedInstitutionSenior || !traineeSelectedSupervisorSenior) {
                    setShowModalFieldsRequired(true)
                    return setShow(true)
                }
            }

            if (typeBusinessLink == 55 || confirmedTypeSenior?.value == 55){
                if (!apprenticeTypeContract){
                    setShowModalFieldsRequired(true)
                    return setShow(true)
                }

                if (apprenticeTypeContract?.id == 1 && (!apprenticeEntityQualify || !apprenticeCnpjEntityQualify)){
                    setShowModalFieldsRequired(true)
                    return setShow(true)
                }

                if (apprenticeTypeContract?.id == 2 && (!apprenticeIndirectRegistration || !apprenticeCnpjIndirectRegistration)){
                    setShowModalFieldsRequired(true)
                    return setShow(true)
                }
            }

            if (companyConfig?.useNatureExpenses == 'S' && [656, 1071, 1763]?.includes(company)){
                if (!tokenRegistry || !codPlate){
                    setShowModalFieldsRequired(true)
                    return setShow(true)
                }
            }


            if (companyConfig?.useNatureExpenses == 'S'){
                if (!natureExpenseSenior){
                    setShowModalFieldsRequired(true)
                    return setShow(true)
                }
            }

            if (companyConfig?.addHistory == 'S'){
                if (!insalubritySenior || !dangerSenior || !specialRetirementSenior){
                    setShowModalFieldsRequired(true)
                    return setShow(true)
                }
            }

            if (companyConfig?.numberTag == 'S'){
                if (!numberTag){
                    setShowModalFieldsRequired(true)
                    return setShow(true)
                }
            }

            if (
                !scheduleSenior || !localSenior || !localOrganizationSenior || !jobPositionSenior || !jobPositionEstructureSenior ||
                !workplaceSenior || !workplaceSeniorStructure || (employeeVT && !stopoverTransportSenior) || (paymentMethod != 'D' && !employeeAgencySenior)
            ) {
                setInsideLoading(false)
                return setShow(true)
            }

            dependents.filter((field) => {
                if (!field.courtPension) {
                    field.courtPension = undefined
                }
                if (!field.deficiency) {
                    field.deficiency = undefined
                }
            })

            setLoading(true)

            let queryPreliminar = []
            queryPreliminar?.push(`admissionDate=${admissionDate}`)
            queryPreliminar?.push(`cpf=${cpf}`)
            queryPreliminar = queryPreliminar.join('&')

            const verifyExistsPreliminary = await api.get(`api/v1/general/registerPreliminary?${queryPreliminar}`, {
                headers: {
                    authorization: token
                }
            })
            const dataPreliminary = verifyExistsPreliminary?.data?.data
    
            if (dataPreliminary) {
                setExistsPreliminary(dataPreliminary)
                await showAlertPreliminary()
            }

            await api.post(`api/v1/employees/importSenior/${employeeId}`, {
                //dados senior
                laborUnionSenior: !laborUnionSenior ? undefined : laborUnionSenior,
                scheduleSenior, localSenior, localOrganizationSenior,
                jobPositionSenior, jobPositionEstructureSenior,
                ccuSenior: !ccuSenior ? undefined : ccuSenior,
                ccuOriginSenior: !ccuOriginSenior ? undefined : ccuOriginSenior,
                workplaceSenior, workplaceSeniorStructure, scheduleTeamSenior, employeeIdSenior, dependents,
                stopoverTransportSenior: !stopoverTransportSenior ? undefined : stopoverTransportSenior,
                traineeSelectedInstitutionSenior: traineeSelectedInstitutionSenior?.codoem, 
                traineeSelectedSupervisorSenior,
                //dados contrato/colab
                employeeName, admissionDate, 
                // Verifica se o coloborador é intermitente e corrige para o código certo, pois o código CLT é o mesmo que intermitente.
                isIntermittent: !isIntermittent ? undefined : true,
                typeBusinessLink: isIntermittent && confirmedTypeSenior?.value == 11 ? 10 : isIntermittent && typeBusinessLink == 11 ? 10 : confirmedTypeSenior ? confirmedTypeSenior?.value : typeBusinessLink,  
                typeApuration, employeeLaborUnion, employeePosition,
                laborUnion: !laborUnion ? undefined : laborUnion,
                employeeScheduleType, employeeScheduleMonday, employeeScheduleTuesday, employeeScheduleWednesday, employeeScheduleThursday, employeeScheduleFriday,
                employeeScheduleSaturday, employeeScheduleSunday, 
                employeeSalary, 
                typeSalary, employeeLocal,
                employeeCCU: !employeeCCU ? undefined : employeeCCU,
                employeeVT, advanceSalary,
                employeeValueVT: !employeeValueVT ? undefined : employeeValueVT,
                advanceSalaryPorcentage: !advanceSalaryPorcentage ? undefined : advanceSalaryPorcentage,
                employeeExperience, lifeInsurance, insalubrity, periculosity, paymentPeriod, paymentMethod, employeeSexualGender,
                employeeBank: !employeeBank ? undefined : employeeBank,
                employeBankAgencie: !employeeAgencySenior ? undefined : employeeAgencySenior,
                employeeBankAccountType: !employeeBankAccountType ? undefined : employeeBankAccountType,
                employeeBankAccount: !employeeBankAccount ? undefined : employeeBankAccount,
                employeeBankAccountDigit: !employeeBankAccountDigit ? undefined : employeeBankAccountDigit,
                civilStatus, levelOfEducation, birthday, nationality, employeeUf, employeeCity,
                workCard, workCardSerie, 
                workCardUf: !workCardUf || workCardUf == -1 ? undefined : workCardUf,
                workCardEmission: !workCardEmission ? undefined : workCardEmission,
                cpf,
                pis: !pis ? undefined : pis?.replace(/[^\d]+/g, ''),
                emissionPis: !emissionPis ? undefined : emissionPis,
                rg: !rg ? undefined : rg,
                sendingAgencyRG: !sendingAgencyRG ? undefined : sendingAgencyRG,
                EmissionRG: !EmissionRG ? undefined : EmissionRG,
                rgUf: !rgUf ? undefined : rgUf,
                voterRegistration: !voterRegistration ? undefined : voterRegistration,
                voterRegistrationZone: !voterRegistration ? undefined : voterRegistrationZone,
                voterRegistrationSection: !voterRegistration ? undefined : voterRegistrationSection,
                cnh: !cnh ? undefined : cnh,
                cnhIssuer: !cnhIssuer ? undefined : cnhIssuer,
                cnhCategory: !cnhCategory ? undefined : cnhCategory,
                cnhValidity: !cnhValidity ? undefined : cnhValidity,
                cnhUf: !cnhUf ? undefined : cnhUf,
                cnhFirstDate: !cnhFirstDate ? undefined : cnhFirstDate,
                cnhDispatchDate: !cnhDispatchDate ? undefined : cnhDispatchDate,
                militaryServiceCard: !militaryServiceCard ? undefined : militaryServiceCard,
                militaryServiceCardUf: !militaryServiceCardUf ? undefined : militaryServiceCardUf,
                deficiencyType: !deficiencyType ? undefined : deficiencyType,
                deficiencyRehabilitatedInss: !deficiencyRehabilitatedInss ? undefined : deficiencyRehabilitatedInss,
                deficiencyPcdQuote: !deficiencyPcdQuote ? undefined : deficiencyPcdQuote,
                deficiency, employeeRace: !employeeRace ? undefined : employeeRace, addressCountry, 
                addressCep: addressCep ? addressCep?.replace('-', '') : undefined,
                addressState,
                addressCity, addressDistrict, addressRoad, addressNumber, addressType,
                addressComplement: !addressComplement ? undefined : addressComplement,
                phoneDdd: !phoneDdd ? undefined : phoneDdd,
                phoneNumber: !phoneNumber ? undefined : phoneNumber,
                employeeEmail: !employeeEmail ? undefined : employeeEmail,
                employeeEmailCorporate: !employeeEmailCorporate ? undefined : employeeEmailCorporate,
                dateEmploymentExamination: !dateEmploymentExamination ? undefined : dateEmploymentExamination,
                fatherName: !fatherName ? undefined : fatherName,
                firstJob: firstJob,
                employeeWorkplace: !employeeWorkplace ? undefined : employeeWorkplace,
                motherName, dependents: dependents.map(field => {
                    delete field.employeeAdmission_id
                    delete field.dependentCpf
                    delete field.dependentIRRF
                    delete field.dependentBirthday
                    delete field.dependentName
                    return field
                }),
                observation: !observation ? undefined : observation,
                traineeInsurancePolicy: !traineeInsurancePolicy ? undefined : traineeInsurancePolicy, 
                traineeEndForecast: !traineeEndForecast ? undefined : traineeEndForecast, 
                traineeEducationalInstitution: !traineeEducationalInstitution ? undefined : traineeEducationalInstitution, 
                traineeCodSupervisor: !traineeCodSupervisor ? undefined : traineeCodSupervisor,
                traineeOrigin: !traineeOrigin ? undefined : traineeOrigin, 
                traineeEducationLevel: !traineeEducationLevel ? undefined : traineeEducationLevel,
                foreignResidenceTime: !foreignResidenceTime ? undefined : foreignResidenceTime,
                foreignCondition: !foreignCondition ? undefined : foreignCondition,
                foreignFinishResidence: !foreignFinishResidence ? undefined : foreignFinishResidence,
                foreignArrivalDate: !foreignArrivalDate ? undefined : foreignArrivalDate,
                foreignRegistry: !foreignRegistry ? undefined : foreignRegistry,
                foreignDateExpRegistry: !foreignDateExpRegistry ? undefined : foreignDateExpRegistry,
                foreignVisaValidity: !foreignVisaValidity ? undefined : foreignVisaValidity,
                foreignDateValidityCTPS: !foreignDateValidityCTPS ? undefined : foreignDateValidityCTPS,
                foreignCasWithBrazilian: !foreignCasWithBrazilian ? undefined : foreignCasWithBrazilian,
                foreignBrazilianChildren: !foreignBrazilianChildren ? undefined : foreignBrazilianChildren,
                advanceMonthAdmission: advanceMonthAdmission ? advanceMonthAdmission : undefined,
                tokenRegistry: tokenRegistry ? tokenRegistry : undefined,
                codPlate: codPlate ? codPlate : undefined,
                natureExpenses: natureExpenseSenior ? natureExpenseSenior : undefined,
                insalubritySenior: insalubritySenior ? insalubritySenior : undefined, 
                dangerSenior: dangerSenior ? dangerSenior : undefined, 
                specialRetirementSenior: specialRetirementSenior ? specialRetirementSenior?.id : undefined,
                nickname: nickname ? nickname : undefined,
                numberTag: numberTag ? numberTag : undefined,
                apprenticeTypeContract: apprenticeTypeContract ? apprenticeTypeContract?.id : undefined,
                apprenticeIndirectRegistration: apprenticeIndirectRegistration ? apprenticeIndirectRegistration?.codoem : undefined,
                apprenticeCnpjIndirectRegistration: apprenticeCnpjIndirectRegistration ? apprenticeCnpjIndirectRegistration?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                apprenticeEntityQualify: apprenticeEntityQualify ? apprenticeEntityQualify?.codoem : undefined,
                apprenticeCnpjEntityQualify: apprenticeCnpjEntityQualify ? apprenticeCnpjEntityQualify?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                apprenticeCnpjPracticalActivity: apprenticeCnpjPracticalActivity ? apprenticeCnpjPracticalActivity?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                gratificationFunction: [true, "true"].includes(gratificationFunction) ? gratificationFunction : undefined
            }, {
                headers: {
                    authorization: `${token}`,
                }
            })

            setLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Colaborador cadastro com sucesso')
            return navigate(-1)

        } catch (error) {
            if (error.response?.data?.message !== undefined) {
                setLoading(false)
                setShow(true)            
                return setErrorSubmit(true)
            }
            setLoading(false)
            setShow(true)            
            return setErrorSubmit(true)
        }
    }

    if (company === 0) {
        alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
        return navigate('/')
    }

    function validateFormModal() {

        const inputs = document.querySelectorAll('input')
        const selects = document.querySelectorAll('select')
        const arrayInputs = Array.prototype.slice.call(inputs);
        const arraySelects = Array.prototype.slice.call(selects);
        let existsMissingFields = false

        const requiredFields = [
            'employeeScheduleType',
            'employeeScheduleMonday',
            'employeeScheduleTuesday',
            'employeeScheduleWednesday',
            'employeeScheduleThursday',
            'employeeScheduleFriday',
            'employeeScheduleSaturday',
            'employeeScheduleSunday'
        ]

        arrayInputs.map((input) => {

            if ((input.value == '' || input.value <= 0) && (requiredFields.indexOf(input.id) > -1)) {
                missingRequiredFieldsModal[input.id] = true
                existsMissingFields = true
                setMissingRequiredFieldsModal(missingRequiredFieldsModal)

            } else {
                missingRequiredFieldsModal[input.id] = false
                setMissingRequiredFieldsModal(missingRequiredFieldsModal)
            }
        })

        arraySelects.map((select) => {
            if ((select.value == 0 || select.value == '0' || !select.value) && (requiredFields.indexOf(select.id) > -1)) {
                missingRequiredFieldsModal[select.id] = true
                existsMissingFields = true
                setMissingRequiredFieldsModal(missingRequiredFieldsModal)

            } else {
                missingRequiredFieldsModal[select.id] = false
                setMissingRequiredFieldsModal(missingRequiredFieldsModal)
            }
        })

        setMissingRequiredFieldsModal(missingRequiredFieldsModal)

        if (existsMissingFields) {
            setInsideLoading(true)
            setTimeout(() => {
                setInsideLoading(false)
            }, [300])
            return 1
        } else {
            return 0
        }
    }

    function validateForm(formToValidate) {

        let existsMissingFields = false
        let dateDependentsError = false

        const contractRequired =
        [
            { id: 'employeeName', value: employeeName, depends: '' }, { id: 'admissionDate', value: admissionDate, depends: '' },
            { id: 'typeApuration', value: typeApuration, depends: '' }, { id: 'employeeLaborUnion', value: employeeLaborUnion, depends: '' },
            { id: 'laborUnion', value: laborUnion, depends: employeeLaborUnion }, { id: 'employeePosition', value: employeePosition, depends: '' },
            { id: 'employeeSalary', value: employeeSalary, depends: '' },
            { id: 'typeSalary', value: typeSalary, depends: '' }, { id: 'typeBusinessLink', value: typeBusinessLink, depends: '' }, { id: 'employeeLocal', value: employeeLocal, depends: '' },
            { id: 'employeeVT', value: employeeVT, depends: '' }, { id: 'employeeBank', value: employeeBank, depends: paymentMethod },
            { id: 'employeeBankAccountType', value: employeeBankAccountType, depends: paymentMethod }, { id: 'employeeValueVT', value: employeeValueVT, depends: employeeVT },
            { id: 'employeeBankAccount', value: employeeBankAccount, depends: paymentMethod }, { id: 'employeeBankAccountDigit', value: employeeBankAccountDigit, depends: paymentMethod },
            { id: 'employeeBankAgencies', value: employeBankAgencie, depends: paymentMethod }, { id: 'advanceSalaryPorcentage', value: advanceSalaryPorcentage, depends: '' },
            { id: 'employeeBankAccount', value: employeeBankAccount, depends: paymentMethod },
            { id: 'employeeExperience', value: employeeExperience, depends: '' }, { id: 'lifeInsurance', value: lifeInsurance, depends: '' }, { id: 'insalubrity', value: insalubrity, depends: '' },
            { id: 'periculosity', value: periculosity, depends: '' }, { id: 'paymentPeriod', value: paymentPeriod }, { id: 'paymentMethod', value: paymentMethod, depends: '' }
        ]

        const employeeRequired =
        [
            { id: 'employeeSexualGender', value: employeeSexualGender, depends: '' },
            { id: 'civilStatus', value: civilStatus, depends: '' },
            { id: 'levelOfEducation', value: levelOfEducation, depends: '' },
            { id: 'birthday', value: birthday, depends: '' },
            { id: 'nationality', value: nationality, depends: '' },
            { id: 'employeeUF', value: employeeUf, depends: '' },
            { id: 'employeeCity', value: employeeUf, depends: '' },
            { id: 'workCard', value: workCard, depends: '' },
            { id: 'workCardSerie', value: workCardSerie, depends: '' },
            { id: 'cpf', value: cpf, depends: '' }, /*{ id: 'pis', value: pis, depends: '' }, { id: 'emissionPis', value: emissionPis, depends: '' },*/
            { id: 'deficiency', value: deficiency, depends: '' },
            { id: 'deficiencyType', value: deficiencyType, depends: deficiency },
            { id: 'deficiencyRehabilitatedInss', value: deficiencyRehabilitatedInss, depends: deficiency },
            { id: 'deficiencyPcdQuote', value: deficiencyPcdQuote, depends: deficiency },
            { id: 'addressState', value: addressState, depends: '' },
            { id: 'addressCities', value: addressCity, depends: '' },
            { id: 'addressDistrict', value: addressDistrict, depends: '' },
            { id: 'addressCep', value: addressCep, depends: '' },
            { id: 'addressRoad', value: addressRoad, depends: '' },
            { id: 'addressNumber', value: addressNumber, depends: '' },
            { id: 'addressCountry', value: addressCountry, depends: '' },
            { id: 'addressType', value: addressCountry, depends: '' },
            { id: 'employeeRace', value: employeeRace, depends: '' }
        ]

        if (nationality != 10){
            employeeRequired.push({ id: 'foreignResidenceTime', value: foreignResidenceTime, depends: '' })
            employeeRequired.push({ id: 'foreignCondition', value: foreignCondition, depends: '' })
            employeeRequired.push({ id: 'foreignArrivalDate', value: foreignArrivalDate, depends: '' })
            employeeRequired.push({ id: 'foreignRegistry', value: foreignRegistry, depends: '' })
            employeeRequired.push({ id: 'foreignDateExpRegistry', value: foreignDateExpRegistry, depends: '' })
            employeeRequired.push({ id: 'foreignVisaValidity', value: foreignVisaValidity, depends: '' })
            employeeRequired.push({ id: 'foreignDateValidityCTPS', value: foreignDateValidityCTPS, depends: '' })
            employeeRequired.push({ id: 'foreignCasWithBrazilian', value: foreignCasWithBrazilian, depends: '' })
            employeeRequired.push({ id: 'foreignBrazilianChildren', value: foreignBrazilianChildren, depends: '' })
        }
        
        if (foreignResidenceTime == 2){
            employeeRequired.push({ id: 'foreignFinishResidence', value: foreignFinishResidence, depends: '' })
        }

        if (cnh || cnhExists) {
            employeeRequired.push({ id: 'cnh', value: cnh, depends: '' })
            employeeRequired.push({ id: 'cnhCategory', value: cnhCategory, depends: '' })
            employeeRequired.push({ id: 'cnhDispatchDate', value: cnhDispatchDate, depends: '' })
            employeeRequired.push({ id: 'cnhFirstDate', value: cnhFirstDate, depends: '' })
            employeeRequired.push({ id: 'cnhIssuer', value: cnhIssuer, depends: '' })
            employeeRequired.push({ id: 'cnhUf', value: cnhUf, depends: '' })
            employeeRequired.push({ id: 'cnhValidity', value: cnhValidity, depends: '' })
        }

        if (typeBusinessLink == '90'){
            contractRequired.push({ id: 'traineeInsurancePolicy', value: traineeInsurancePolicy, depends: '' })
            contractRequired.push({ id: 'traineeEndForecast', value: traineeEndForecast, depends: '' })
            contractRequired.push({ id: 'traineeEducationalInstitution', value: traineeEducationalInstitution, depends: '' })
            contractRequired.push({ id: 'traineeCodSupervisor', value: traineeCodSupervisor, depends: '' })
            contractRequired.push({ id: 'traineeOrigin', value: traineeOrigin, depends: '' })
            contractRequired.push({ id: 'traineeEducationLevel', value: traineeEducationLevel, depends: '' })
        }

        if (typeBusinessLink == '55'){
            contractRequired.push({ id: 'apprenticeInstitutionName', value: apprenticeInstitutionName, depends: '' })
            contractRequired.push({ id: 'apprenticeInstitutionCPNJ', value: apprenticeInstitutionCNPJ, depends: '' })
            contractRequired.push({ id: 'apprenticeDateFinish', value: apprenticeDateFinish, depends: '' })
        }

        if (companyConfig?.useNatureExpenses == 'S'){
            contractRequired.push({ id: 'natureExpense', value: natureExpense, depends: '' })
        }

        const dependentsRequired = [
            { id: 'motherName', value: motherName, depends: '' }
        ]

        //Verifica os campos do formulário dados de contrato
        contractRequired.map((field) => {
            if ((formToValidate > 1 || !formToValidate) &&
                (field.value < 0 || (field.value === '0' && field.id !== 'employeeBankAccountDigit') || field.value === '') &&
                (field.depends === true || field.depends === '' || field.depends === 'R')
            ) {
                setErrorFields({ name: 'contractForm' })
                missingRequiredFields[field.id] = true
                existsMissingFields = true
                setMissingRequiredFields(missingRequiredFields)
                setSelectedForm(1)
            } else {
                missingRequiredFields[field.id] = false
                setMissingRequiredFields(missingRequiredFields)
            }
        })

        //Verifica os campos do formulário dados do empregado
        if (!existsMissingFields) {
            employeeRequired.map((field) => {
                if (
                    (formToValidate > 2 || !formToValidate) &&
                    (field.value < 0 || (field.value === '0' && field.id !== 'employeeBankAccountDigit') || field.value === '') &&
                    (field.depends === true || field.depends === '' || field.depends === 'R')
                ) {
                    setErrorFields({ name: 'employeeForm' })
                    missingRequiredFields[field.id] = true
                    existsMissingFields = true
                    setMissingRequiredFields(missingRequiredFields)
                    setSelectedForm(2)
                } else {
                    missingRequiredFields[field.id] = false
                    setMissingRequiredFields(missingRequiredFields)
                }
            })
        }

        //Verifica os campos do formulário dados de dependentes
        if (!existsMissingFields && (formToValidate > 3 || !formToValidate)) {
            dependentsRequired.map((field) => {
                if (!formToValidate &&
                    (field.value < 0 || (field.value === '0' && field.id !== 'employeeBankAccountDigit') || field.value === '') &&
                    (field.depends === true || field.depends === '' || field.depends === 'R')
                ) {
                    setErrorFields({ name: 'dependentsForm' })
                    missingRequiredFields[field.id] = true
                    existsMissingFields = true
                    setMissingRequiredFields(missingRequiredFields)
                    setSelectedForm(3)
                } else {
                    missingRequiredFields[field.id] = false
                    setMissingRequiredFields(missingRequiredFields)
                }
            })

            //Verifica os dados dos dependentes, se estão todos preenchidos
            dependents.map((dependent, index) => {
               
                const keyTocheck = Object.keys(dependent)

                if (!existsMissingFields) {
                    keyTocheck.map((field) => {
                        if (field === 'cpf' && !validateDependentsCPF(dependents[index][field])) {
                            const positionThisCpf = invalidDependentCPF.indexOf(index)
                            setErrorFields({ name: 'dependentsForm' })
                            missingRequiredFields[field + index] = true
                            existsMissingFields = true
                            setSelectedForm(3)
                            setMissingRequiredFields(missingRequiredFields)
                            if (positionThisCpf > -1) {
                                return
                            }
                            invalidDependentCPF.push(index)
                            setInvalidDependentCPF([...invalidDependentCPF])
                        } else if (field === 'cpf' && validateDependentsCPF(dependents[index][field])) {
                            const positionThisCpf = invalidDependentCPF.indexOf(index)
                            if (positionThisCpf > -1) {
                                invalidDependentCPF.splice(positionThisCpf, 1)
                                setInvalidDependentCPF([...invalidDependentCPF])
                            }
                        }
                        if (field === 'birthday') {
                            const birthdayYear = parseInt(dependents[index][field]?.slice(0, 4))
                            if (((thisYear - birthdayYear) > 100) || dependents[index][field] == '') {
                                dateDependentsError = true
                                missingRequiredFields[field + index] = true
                                setErrorFields({ name: 'dependentsForm' })
                                setMissingRequiredFields(missingRequiredFields)
                                return alert(`Data de nascimento do dependente ${dependents[index]['name']} na linha ${index + 1} invalida`)
                            }
                        }

                        if (!formToValidate && (formToValidate === 3 || !formToValidate) && ((dependents[index][field] < 0 || dependents[index][field] === '0' || dependents[index][field] === '') && field !== 'deficiency')
                            ||
                            (dependents[index]['hasDeficiency'] === true && (
                                dependents[index]['deficiency'] < 0 || dependents[index]['deficiency'] === '0' || dependents[index]['deficiency'] === ''))
                        ) {
                            setErrorFields({ name: 'dependentsForm' })
                            missingRequiredFields[field + index] = true
                            existsMissingFields = true
                            setMissingRequiredFields(missingRequiredFields)
                            setSelectedForm(3)
                        } else {
                            missingRequiredFields[field + index] = false
                            setMissingRequiredFields(missingRequiredFields)
                        }
                    })
                }
            })

        }

        if (existsMissingFields || validateFormModal() || dateDependentsError) {

            return true
        }
    }

    function validEspecificCPFDenpendent(cpf, index) {

        if (!validateDependentsCPF(cpf)) {
            const positionThisCpf = invalidDependentCPF.indexOf(index)
            setErrorFields({ name: 'dependentsForm' })
            missingRequiredFields['cpf' + index] = true
            setSelectedForm(3)
            setMissingRequiredFields(missingRequiredFields)
            if (positionThisCpf > -1) {
                return
            }
            invalidDependentCPF.push(index)
            setInvalidDependentCPF([...invalidDependentCPF])
        } else if (validateDependentsCPF(cpf)) {
            const positionThisCpf = invalidDependentCPF.indexOf(index)
            if (positionThisCpf > -1) {
                invalidDependentCPF.splice(positionThisCpf, 1)
                setInvalidDependentCPF([...invalidDependentCPF])
            }
        }
    }

    function handleDates(dateToHandle) {

        switch (dateToHandle === 'all') {
            case birthday > admissionDate:
                setDateError({ date: dateToHandle, message: 'Data de nascimento maior do que a data de admissão' })
                setErrorFields({ name: 'date' })
                setShow(true)
                return true
            case ((emissionPis !== '' && (emissionPis < birthday)) || (EmissionRG !== '' && (EmissionRG < birthday))):
                setDateError({ date: dateToHandle, message: 'Data de emissão do PIS ou RG menor do que a data de nascimento, verifique' })
                setErrorFields({ name: 'date' })
                setShow(true)
                return true
            case ((cnhFirstDate !== '' && (cnhFirstDate < birthday)) || (cnhDispatchDate !== '' && (cnhDispatchDate < birthday))):
                setDateError({ date: dateToHandle, message: 'Data de emissão da CNH ou da expedição da CNH menor do que a data de nascimento, verifique' })
                setErrorFields({ name: 'date' })
                setShow(true)
                return true
            default:
                return false;
        }

    }

    async function checkFillTabs(formToShow) {


        if (handleDates('all') === true) {
            return false
        }
        setInsideLoading(true)
        if (invalidCPF) {
            setErrorFields({ name: 'invalidCPF' })
            setTimeout(() => {
                setInsideLoading(false)
                return setShow(true)
            }, [300])
            return false
        }
        if (validateForm(formToShow)) {
            setTimeout(() => {
                setInsideLoading(false)
                setShow(true)
            }, [300])
            return false
        }

        if (typeSalary === 1 && employeeSalary <= 50){
            setInsideLoading(false)
            return alert('Mensalista precisa ter o salário base maior que R$ 50,00')
        } else if (typeSalary === 2 && employeeSalary >= 50){
            setInsideLoading(false)
            return alert('Horista precisa ter o salário base menor que R$ 50,00')
        }

        if ([3,4]?.includes(formToShow) && !employeeRace){
            setInsideLoading(false)
            return alert('O campo "Raça" é obrigatório. Verifique os dados')
        }

        setInsideLoading(false)
        setSelectedForm(formToShow)
        return true

    }

    function validateCPF(cpf, type) {

        let validCPF = false
        setErrorFields({ name: 'invalidCPF' })

        if (cpf?.length > 0) {

            const strCPF = cpf.replace(/[^a-zA-Z0-9s]/g, '')

            if (strCPF == "00000000000") {
                setInvalidCPF(true)
                return validCPF = false
            }

            let sum = 0
            let rest = 0
            for (let i = 1; i <= 9; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
            rest = (sum * 10) % 11

            if ((rest == 10) || (rest == 11)) rest = 0
            if (rest != parseInt(strCPF.substring(9, 10))) {
                setInvalidCPF(true)
                return validCPF = false
            }

            sum = 0;
            for (let i = 1; i <= 10; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
            rest = (sum * 10) % 11

            if ((rest == 10) || (rest == 11)) rest = 0
            if (rest != parseInt(strCPF.substring(10, 11))) {
                setInvalidCPF(true)
                return validCPF = false
            }
            validCPF = true
            setErrorFields({})
            setInvalidCPF(!validCPF)
            return validCPF
        }

        setInvalidCPF(!validCPF)
        return validCPF

    }

    function validateDependentsCPF(cpfNum) {

        let validCPF = false
        setErrorFields({ name: 'invalidCPF' })

        if (cpfNum?.length > 0) {

            const strCPF = cpfNum.replace(/[^a-zA-Z0-9s]/g, '')

            if (strCPF == "00000000000") {
                return validCPF = false
            }

            let sum = 0
            let rest = 0
            for (let i = 1; i <= 9; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
            rest = (sum * 10) % 11

            if ((rest == 10) || (rest == 11)) rest = 0
            if (rest != parseInt(strCPF.substring(9, 10))) {
                return validCPF = false
            }

            sum = 0;
            for (let i = 1; i <= 10; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
            rest = (sum * 10) % 11

            if ((rest == 10) || (rest == 11)) rest = 0
            if (rest != parseInt(strCPF.substring(10, 11))) {
                return validCPF = false
            }
            if (cpfNum === cpf.replace(/[^a-zA-Z0-9s]/g, '')) {
                validCPF = false
                return validCPF
            }
            validCPF = true
            setErrorFields({})
            return validCPF
        }
        return validCPF
    }
    
    async function generatePdf(e){
        e.preventDefault()
        
        try {            

            setLoading(true)

            const htmlData = document.getElementById('exportEmployee').innerHTML         

            const pdfWindow = window.open('Folpag Connect', 'Folpag Connect', 'width=1920, height= 1080')
            pdfWindow.document.write('<html lang="pt-BR">')
            pdfWindow.document.write('<head>')
            pdfWindow.document.write(`
                <meta charset="utf-8" />
                <title>
                    Colaborador: Nova Admissão
                </title>
                <meta name="description" content="Relatório" />
            `)            
            pdfWindow.document.write('</head>')
            pdfWindow.document.write('<style>')
            pdfWindow.document.write(reportStyle)
            pdfWindow.document.write('</style>')
            pdfWindow.document.write('<body>')
            pdfWindow.document.write(htmlData)
            pdfWindow.document.write('</body>')
            pdfWindow.document.write('<script>')
            pdfWindow.document.write(`
                document.querySelector('select[id="typeBusinessLink"]>option[value="${typeBusinessLink}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="typeApuration"]>option[value="${typeApuration}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeLaborUnion"]>option[value="${employeeLaborUnion}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="typeSalary"]>option[value="${typeSalary}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeVT"]>option[value="${employeeVT}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="advanceSalary"]>option[value="${advanceSalary}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeExperience"]>option[value="${employeeExperience}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="lifeInsurance"]>option[value="${lifeInsurance}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="insalubrity"]>option[value="${insalubrity}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="periculosity"]>option[value="${periculosity}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="paymentPeriod"]>option[value="${paymentPeriod}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="paymentMethod"]>option[value="${paymentMethod}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBank"]>option[value="${employeeBank}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBankAgencies"]>option[value="${employeBankAgencie}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBankAccountType"]>option[value="${employeeBankAccountType}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBankAccount"]>option[value="${employeeBankAccount}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBankAccountDigit"]>option[value="${employeeBankAccountDigit}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="traineeOrigin"]>option[value="${traineeOrigin}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="traineeEducationLevel"]>option[value="${traineeEducationLevel}"]')?.setAttribute('selected', '')

                document.querySelector('select[id="employeeSexualGender"]>option[value="${employeeSexualGender}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="civilStatus"]>option[value="${civilStatus}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="levelOfEducation"]>option[value="${levelOfEducation}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="nationality"]>option[value="${nationality}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeUF"]>option[value="${employeeUf}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeCity"]>option[value="${employeeCity}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="firstJob"]>option[value="${firstJob}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="selectedWorkCardType"]>option[value="${wordCardType}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="workCardUF"]>option[value="${workCardUf}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="RGuf"]>option[value="${rgUf}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="cnhUf"]>option[value="${cnhUf}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="deficiency"]>option[value="${deficiency}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="deficiencyType"]>option[value="${deficiencyType}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="deficiencyRehabilitatedInss"]>option[value="${deficiencyRehabilitatedInss}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="deficiencyPcdQuote"]>option[value="${deficiencyPcdQuote}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeRace"]>option[value="${employeeRace}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressCountry"]>option[value="${addressCountry}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressState"]>option[value="${addressState}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressCities"]>option[value="${addressCity}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressDistrict"]>option[value="${addressDistrict}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressType"]>option[value="${addressType}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="foreignResidenceTime"]>option[value="${foreignResidenceTime}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="foreignCondition"]>option[value="${foreignCondition}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="foreignCasWithBrazilian"]>option[value="${foreignCasWithBrazilian}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="foreignBrazilianChildren"]>option[value="${foreignBrazilianChildren}"]')?.setAttribute('selected', '')
                
                ${dependents.map((dependent, index) => {  
                    return `
                        document.querySelector('select[id="dependentType${index}"]>option[value="${dependent.dependentType}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentIRRF${index}"]>option[value="${dependent.irrfDependent}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentHealthPlan${index}"]>option[value="${dependent.healthPlan}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentHasDeficient${index}"]>option[value="${dependent.hasDeficiency}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentDeficient${index}"]>option[value="${dependent.deficiency}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="sexualGender${index}"]>option[value="${dependent.sexualGender}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentCourtPension${index}"]>option[value="${dependent.courtPension}"]')?.setAttribute('selected', '')
                    `
                }).join('\n')}
            `)
            pdfWindow.document.write('</script>')
            pdfWindow.document.write('</html>')
            pdfWindow.document.close()
            pdfWindow.print()
            pdfWindow.close()

            setLoading(false)

        } catch (error) {
            setLoading(false)
            return alert('Erro ao realizar geração de PDF')
        }

    }

    async function handleSave(){
        if (inEdit) {
            setShowModalSave(true)
            return setShow(true)
        }

        setLoading(true)
        try {
            await api.put(`api/v1/employeesSave/company/${company}/branch/${branch}/saveImportSenior/connectId/${employeeId}`,{
                employeeName: employeeName ? employeeName : undefined, 
                admissionDate: admissionDate ? admissionDate : undefined, 
                // Verifica se o coloborador é intermitente e corrige para o código certo, pois o código CLT é o mesmo que intermitente.
                isIntermittent: !isIntermittent ? undefined : true,
                typeBusinessLink: isIntermittent && typeBusinessLink == 11 ? 10 : typeBusinessLink, 
                typeApuration: typeApuration ? typeApuration : undefined, 
                employeeLaborUnion: employeeLaborUnion ? employeeLaborUnion : undefined, 
                employeePosition: employeePosition ? employeePosition : undefined,
                laborUnion: !laborUnion ? undefined : laborUnion, 
                typeSalary: typeSalary ? typeSalary : undefined, 
                employeeSalary: typeSalary == 4 ? 0 : employeeSalary,
                employeeLocal: employeeLocal ? employeeLocal : undefined,
                employeeScheduleType: employeeScheduleType ? employeeScheduleType : undefined, 
                employeeScheduleMonday: employeeScheduleMonday && employeeScheduleMonday != '0' && employeeScheduleMonday != 0 ? employeeScheduleMonday : 'n/d', 
                employeeScheduleTuesday: employeeScheduleTuesday && employeeScheduleTuesday != '0' && employeeScheduleTuesday != 0 ? employeeScheduleTuesday : 'n/d', 
                employeeScheduleWednesday: employeeScheduleWednesday && employeeScheduleWednesday != '0' && employeeScheduleWednesday != 0 ? employeeScheduleWednesday : 'n/d', 
                employeeScheduleThursday: employeeScheduleThursday && employeeScheduleThursday != '0' && employeeScheduleThursday != 0 ? employeeScheduleThursday : 'n/d', 
                employeeScheduleFriday: employeeScheduleFriday && employeeScheduleFriday != '0' && employeeScheduleFriday != 0 ? employeeScheduleFriday : 'n/d',
                employeeScheduleSaturday: employeeScheduleSaturday && employeeScheduleSaturday != '0' && employeeScheduleSaturday != 0 ? employeeScheduleSaturday : 'n/d', 
                employeeScheduleSunday: employeeScheduleSunday && employeeScheduleSunday != '0' && employeeScheduleSunday != 0 ? employeeScheduleSunday : 'n/d', 
                employeeCCU: !employeeCCU ? undefined : employeeCCU,
                employeeVT: employeeVT ? employeeVT : undefined, 
                advanceSalary: advanceSalary ? advanceSalary : undefined,
                employeeValueVT: !employeeValueVT ? undefined : employeeValueVT,
                advanceSalaryPorcentage: !advanceSalaryPorcentage ? undefined : advanceSalaryPorcentage,
                employeeExperience: employeeExperience ? employeeExperience : undefined, 
                lifeInsurance: lifeInsurance ? lifeInsurance : undefined, 
                insalubrity: insalubrity ? insalubrity : undefined, 
                periculosity: periculosity ? periculosity : undefined, 
                paymentPeriod: paymentPeriod ? paymentPeriod : undefined, 
                paymentMethod: paymentMethod ? paymentMethod : undefined, 
                employeeSexualGender: employeeSexualGender ? employeeSexualGender : undefined,
                employeeBank: !employeeBank ? undefined : employeeBank,
                employeBankAgencie: !employeBankAgencie ? undefined : employeBankAgencie,
                employeeBankAccountType: !employeeBankAccountType ? undefined : employeeBankAccountType,
                employeeBankAccount: !employeeBankAccount ? undefined : employeeBankAccount,
                employeeBankAccountDigit: !employeeBankAccountDigit ? undefined : employeeBankAccountDigit,
                civilStatus: civilStatus ? civilStatus : undefined, 
                levelOfEducation: levelOfEducation ? levelOfEducation : undefined, 
                birthday: birthday ? birthday : undefined, 
                nationality: nationality ? nationality : undefined, 
                employeeUf: employeeUf ? employeeUf : undefined, 
                employeeCity: employeeCity ? employeeCity : undefined,
                workCard: workCard ? workCard : undefined, 
                workCardSerie: workCardSerie ? workCardSerie : undefined, 
                workCardUf: !workCardUf || workCardUf == -1 ? undefined : workCardUf,
                workCardEmission: !workCardEmission ? undefined : workCardEmission,
                cpf: cpf ? cpf : undefined,
                pis: !pis ? undefined : pis.replace(/[^\d]+/g, ''),
                emissionPis: !emissionPis ? undefined : emissionPis,
                rg: !rg ? undefined : rg,
                sendingAgencyRG: !sendingAgencyRG ? undefined : sendingAgencyRG,
                EmissionRG: !EmissionRG ? undefined : EmissionRG,
                rgUf: !rgUf ? undefined : rgUf,
                voterRegistration: !voterRegistration ? undefined : voterRegistration,
                voterRegistrationZone: !voterRegistration ? undefined : voterRegistrationZone,
                voterRegistrationSection: !voterRegistration ? undefined : voterRegistrationSection,
                cnh: !cnh ? undefined : cnh,
                cnhIssuer: !cnhIssuer ? undefined : cnhIssuer,
                cnhCategory: !cnhCategory ? undefined : cnhCategory,
                cnhValidity: !cnhValidity ? undefined : cnhValidity,
                cnhUf: !cnhUf ? undefined : cnhUf,
                cnhFirstDate: !cnhFirstDate ? undefined : cnhFirstDate,
                cnhDispatchDate: !cnhDispatchDate ? undefined : cnhDispatchDate,
                militaryServiceCard: !militaryServiceCard ? undefined : militaryServiceCard,
                militaryServiceCardUf: !militaryServiceCardUf ? undefined : militaryServiceCardUf,
                deficiencyType: !deficiencyType ? undefined : deficiencyType,
                deficiencyRehabilitatedInss: !deficiencyRehabilitatedInss ? undefined : deficiencyRehabilitatedInss,
                deficiencyPcdQuote: !deficiencyPcdQuote ? undefined : deficiencyPcdQuote,
                deficiency: deficiency ? deficiency : undefined, 
                employeeRace: employeeRace ? employeeRace : undefined, 
                addressCountry: addressCountry ? addressCountry : undefined, 
                addressCep: addressCep ? addressCep?.replace('-', '') : undefined, 
                addressState: addressState ? addressState : undefined,
                addressCity: addressCity ? addressCity : undefined, 
                addressDistrict: addressDistrict ? addressDistrict : undefined, 
                addressRoad: addressRoad ? addressRoad : undefined, 
                addressNumber: addressNumber ? addressNumber : undefined, 
                addressType: addressType ? addressType : undefined,
                addressComplement: !addressComplement ? undefined : addressComplement,
                phoneDdd: !phoneDdd ? undefined : phoneDdd,
                phoneNumber: !phoneNumber ? undefined : phoneNumber,
                employeeEmail: !employeeEmail ? undefined : employeeEmail,
                employeeEmailCorporate: !employeeEmailCorporate ? undefined : employeeEmailCorporate,
                dateEmploymentExamination: !dateEmploymentExamination ? undefined : dateEmploymentExamination,
                fatherName: !fatherName ? undefined : fatherName,
                firstJob: firstJob,
                employeeWorkplace: !employeeWorkplace ? undefined : employeeWorkplace,
                motherName: motherName ? motherName : undefined, 
                dependents: dependents ? dependents : undefined,
                observation: observation ? observation : undefined,
                traineeInsurancePolicy: traineeInsurancePolicy ? traineeInsurancePolicy : undefined,
                traineeEndForecast: traineeEndForecast ? traineeEndForecast : undefined,
                traineeEducationalInstitution: traineeEducationalInstitution ? traineeEducationalInstitution : undefined,
                traineeCodSupervisor: traineeCodSupervisor ? traineeCodSupervisor : undefined,
                traineeOrigin: traineeOrigin ? traineeOrigin : undefined,
                traineeEducationLevel: traineeEducationLevel ? traineeEducationLevel : undefined,
                foreignResidenceTime: foreignResidenceTime ? foreignResidenceTime : undefined,
                foreignCondition: foreignCondition ? foreignCondition : undefined,
                foreignFinishResidence: foreignFinishResidence ? foreignFinishResidence : undefined,
                foreignArrivalDate: foreignArrivalDate ? foreignArrivalDate : undefined,
                foreignRegistry: foreignRegistry ? foreignRegistry : undefined,
                foreignDateExpRegistry: foreignDateExpRegistry ? foreignDateExpRegistry : undefined,
                foreignVisaValidity: foreignVisaValidity ? foreignVisaValidity : undefined,
                foreignDateValidityCTPS: foreignDateValidityCTPS ? foreignDateValidityCTPS : undefined,
                foreignCasWithBrazilian: foreignCasWithBrazilian ? foreignCasWithBrazilian : undefined,
                foreignBrazilianChildren: foreignBrazilianChildren ? foreignBrazilianChildren : undefined,
                advanceMonthAdmission: [true, false].includes(advanceMonthAdmission) ? advanceMonthAdmission : undefined,
                natureExpenses: natureExpense ? natureExpense : undefined,
                insalubrityPercentage: insalubrityPercentage ? insalubrityPercentage : undefined,
                periculosityPercentage: periculosityPercentage ? periculosityPercentage : undefined,
                apprenticeInstitutionName: apprenticeInstitutionName ?? undefined,
                apprenticeInstitutionCNPJ: apprenticeInstitutionCNPJ ? apprenticeInstitutionCNPJ?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                apprenticeDateFinish: apprenticeDateFinish ?? undefined,
                gratificationFunction: gratificationFunction ?? undefined,
                digitAgency: digitAgency ? digitAgency : undefined
            },{
                headers: {
                    authorization: token
                }
            })
            setLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Admissão salva com sucesso')
            return navigate('/employeeConsult')

        } catch (error) {
            setLoading(false)
            alert ('Erro ao salvar colaborador')
        }
    }

    function scrollTopDiv(){
        const myDiv = document.getElementById('partForm')
        myDiv.scrollTop = 0
    }

    async function selectedTableImport(selected){
        switch (whichImput) {
            case 'schedule':
                setScheduleSeniorObject(selected)
                setScheduleSenior(selected?.scheduleId)
            break
            case 'jobPosition':
                setJobPositionSenior(selected?.jobPositionId)
                setJobPositionSeniorObject(selected)
                setJobPositionEstructureSenior(parseInt(selected?.jobStructure))
            break
            case 'local':
                setLocalSenior(selected?.localId)
                setLocalSeniorObject(selected)
                setLocalOrganizationSenior(selected?.localOrganization)
            break
            case 'laborUnion':
                setLaborUnionSenior(selected?.laborUnionId)
                setLaborUnionSeniorObject(selected)
            break
            case 'agencies':
                setEmployeeAgencySeniorObj(selected)
                setEmployeeAgencySenior(selected?.agency_id)
            break
        }
        
        setShowTableImportSenior(false)
    }

    async function defineDataOnTableImport(witchField){
        switch (witchField) {
            case 'schedule':
                if (companyParams?.uniqueStructure == 'S'){
                    setFilter({ company })
                    setFilterConstructor({ company })
                    setVerifyFilter({ company })
                }
                setUrl('/api/v1/employees/schedulesWithPagination')
                setColumnTable([{
                    name: 'scheduleName',
                    type: 'string',
                    description: 'Escala'
                }])
                setWhichImput('schedule')
                setShowTableImportSenior(true)
            break
            case 'jobPosition':
                setUrl(`api/v1/employees/jobPositionWithPagination/${company}`)
                setColumnTable([{
                    name: 'jobPositionName',
                    type: 'string',
                    description: 'Cargo'
                }])
                setWhichImput('jobPosition')
                setShowTableImportSenior(true)
            break
            case 'local':
                setUrl(`api/v1/employees/localWithPagination/${company}`)
                setColumnTable([{
                    name: 'localName',
                    type: 'string',
                    description: 'Local'
                }])
                setWhichImput('local')
                setShowTableImportSenior(true)
            break
            case 'laborUnion':
                setUrl(`api/v1/employees/laborUnionWithPagination`)
                setColumnTable([{
                    name: 'laborUnionDescription',
                    type: 'string',
                    description: 'Sindicato'
                }])
                setWhichImput('laborUnion')
                setShowTableImportSenior(true)
            break
            case 'agencies':
                setFilter({ bank: employeeBank })
                setFilterConstructor({ bank: employeeBank })
                setVerifyFilter({ bank: employeeBank })
                setUrl(`api/v1/employees/agenciesPerBankWithPagination`)
                setColumnTable([
                    {
                        name: 'agency_id',
                        type: 'string',
                        description: 'Agência'
                    },
                    {
                        name: 'agency_digit',
                        type: 'string',
                        description: 'Dígito'
                    }
                ])
                setWhichImput('agencies')
                setShowTableImportSenior(true)
            break
        }
    }

    return (
        <div>
            <Modal>
                {   show && errorSubmit ?
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[30rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Erro ao tentar enviar admissão para o Senior <br/> Verifique os dados enviados.
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                    : show && showModalApply ?
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[30rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Aplique as alterações antes de enviar
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                    : show && showModalSave ?
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[30rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Aplique as alterações antes de salvar
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                    : show && showModalConfirmedSave ?
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-full sm:w-[28rem]`}>
                        <p className='text-center mb-5 text-[1rem] p-4'>Você realmente deseja salvar essa admissão?</p>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button background={'bg-red-800'} onClick={() => {setShow(false)}}>Cancelar</Button>
                            <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleSave()}}>Prosseguir</Button>
                        </div>
                    </div>
                    : show && showModalFieldsRequired ?
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[45rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center text-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7 text-2xl'/> Você precisa preencher os campos do "Folpag Connect para Senior" que possuem asterisco (*)
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                    : show && showErrorEmployeeIdSenior ?
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[45rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center text-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Número da matrícula no Senior precisa ser maior que 0.
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                    :
                    errorFields.name === 'date' ?
                        (
                            <div className='alertMsg'>
                                <p>{dateError.message}</p>
                                <button onClick={() => { setShow(false); setErrorFields({ name: '' }) }}>Fechar</button>
                            </div>
                        ) :
                        errorFields.name === 'invalidCPF' ?
                            (
                                <div className='alertMsg'>
                                    <p>Verifique o campo CPF em vermelho na aba: "Dados do contrato de trabalho"</p>
                                    <button onClick={() => { setShow(false); setErrorFields({ name: '' }) }}>Fechar</button>
                                </div>
                            ) :
                            errorFields.name === 'invalidDependentCPF' ?
                                (
                                    <div className='alertMsg'>
                                        <p>Verifique o campo CPF em vermelho na aba: "Dados de dependentes" os CPFs não podem serem iguais ou inválidos</p>
                                        <button onClick={() => { setShow(false); setErrorFields({ name: '' }) }}>Fechar</button>
                                    </div>
                                ) :
                                errorFields.name === 'contractForm' ?
                                    (
                                        <div className='alertMsg'>
                                            <p>Verifique os campos em vermelho na aba: "Dados do contrato de trabalho"</p>
                                            <button onClick={() => { setShow(false); setErrorFields({ name: '' }) }}>Fechar</button>
                                        </div>
                                    ) :
                                    errorFields.name === 'employeeForm' ?
                                        (
                                            <div className='alertMsg'>
                                                <p>Verifique os campos em vermelho na aba: "Dados do empregado"</p>
                                                <button onClick={() => { setShow(false); setErrorFields({ name: '' }) }}>Fechar</button>
                                            </div>
                                        ) :
                                        errorFields.name === 'dependentsForm' ?
                                            (
                                                <div className='alertMsg'>
                                                    <p>Verifique os campos em vermelho na aba: "Dados dos dependentes"</p>
                                                    <button onClick={() => { setShow(false); setErrorFields({ name: '' }) }}>Fechar</button>
                                                </div>
                                            )
                                            :
                                            (                                                
                                                <div className={`${!show ? 'hiddenModal' : 'w-[95%] lg:w-[40rem]'} flex flex-col items-center justify-center`}>
                                                    <div className='modalParamsDescription bg-slate-200 w-full flex items-center py-4 px-2 font-bold'>
                                                        <FiChevronRight />
                                                        <p>Informe abaixo a escala do colaborador.</p>
                                                    </div>
                                                    <div className='modalFields h-fit gap-1'>
                                                        <div>
                                                            <label htmlFor="employeeScheduleType">Tipo de escala</label>
                                                            <select id="employeeScheduleType" value={employeeScheduleType} onChange={(e) => { setEmployeeScheduleType(parseInt(e.target.value)) }} disabled={inEdit ? false : true}>
                                                                <option value="0">-Selecione uma opção-</option>
                                                                <option value="1">5x1</option>
                                                                <option value="2">5x2</option>
                                                                <option value="3">6x1</option>
                                                                <option value="4">12x36</option>
                                                                <option value="5">3x1</option>
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="employeeScheduleMonday">Horário de Segunda</label>
                                                            <input type="text" id='employeeScheduleMonday' value={employeeScheduleMonday} onChange={(e) => setEmployeeScheduleMonday(e.target.value)} disabled={inEdit ? false : true} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="employeeScheduleTuesday">Horário de Terça</label>
                                                            <input type="text" id='employeeScheduleTuesday' value={employeeScheduleTuesday} onChange={(e) => setEmployeeScheduleTuesday(e.target.value)} disabled={inEdit ? false : true} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="employeeScheduleWednesday">Horário de Quarta</label>
                                                            <input type="text" id='employeeScheduleWednesday' value={employeeScheduleWednesday} onChange={(e) => setEmployeeScheduleWednesday(e.target.value)} disabled={inEdit ? false : true} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="employeeScheduleThursday">Horário de Quinta</label>
                                                            <input type="text" id='employeeScheduleThursday' value={employeeScheduleThursday} onChange={(e) => setEmployeeScheduleThursday(e.target.value)} disabled={inEdit ? false : true} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="employeeScheduleFriday">Horário de Sexta</label>
                                                            <input type="text" id='employeeScheduleFriday' value={employeeScheduleFriday} onChange={(e) => setEmployeeScheduleFriday(e.target.value)} disabled={inEdit ? false : true} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="employeeScheduleSaturday">Horário de Sabádo</label>
                                                            <input type="text" id='employeeScheduleSaturday' value={employeeScheduleSaturday} onChange={(e) => setEmployeeScheduleSaturday(e.target.value)} disabled={inEdit ? false : true} />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="employeeScheduleSunday">Horário de Domingo</label>
                                                            <input type="text" id='employeeScheduleSunday' value={employeeScheduleSunday} onChange={(e) => setEmployeeScheduleSunday(e.target.value)} disabled={inEdit ? false : true} />
                                                        </div>                                                        
                                                    </div>
                                                    <button className='buttonSchedule' onClick={() => setShow(false)}>Confirmar</button>
                                                </div>
                                            )
                }

            </Modal>
            <div key={'modalPreliminary'} className={`${modalAlertPreliminary ? 'flex flex-col overflow-hidden' : 'overflow-hidden'}`}>
                <div className={`${modalAlertPreliminary ? 'flex ' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[99999]`}>
                    <div className={`animate-[wiggle_.4s_ease-in-out] lg:pb-0 absolute lg:relative bottom-0 w-full lg:w-auto transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow h-44 overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                        <div className='flex flex-col w-full h-full p-3 justify-between'>
                            <div className='flex flex-col w-full gap-2'>
                                <h3 className='text-base font-semibold text-primaryDefaultLight'>Existe um registro preliminar para esse colaborador</h3>
                                <div className='flex flex-col mt-1 gap-1'>
                                    <div className='flex items-center gap-2'>
                                        <p className='font-semibold'>Nome:</p>
                                        <p>{existsPreliminary?.nomfun}</p>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <p className='font-semibold'>CPF:</p>
                                        <p>{cpfMask(formatSizeCpf(existsPreliminary?.cpftra))}</p>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <p className='font-semibold'>Data admissão:</p>
                                        <p>{moment(existsPreliminary?.admcol, 'YYYY-MM-DD')?.utc(false)?.format('DD/MM/YYYY')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex w-full items-end justify-end'>
                                <button id="alert-ok-button" 
                                    className={`rounded-lg text-sm font-extrabold bg-primaryDefaultLight hover:bg-primaryDefaultLightHover transition-all duration-300 text-titleGrayTextLight w-20 h-7`}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${modalAlertPreliminary ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[100vh] sm:h-full z-[103]`}></div>
            </div>
            <div className={`${showTableImportSenior ? 'flex flex-col overflow-hidden' : 'overflow-hidden'}`}>
                <div className={`${showTableImportSenior ? 'flex ' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[99999]`}>
                    <div className={`animate-[wiggle_.4s_ease-in-out] lg:pb-0 absolute lg:relative bottom-0 w-full lg:w-auto transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow max-h-[39rem] h-[39rem] overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                        {
                            loading ?
                            <div className='flex justify-cente pl-3 py-5 items-center ml-7'>
                                <LoadingPulse/>
                            </div>
                            :
                            <div className='p-5 flex flex-col justify-start items-start gap-2 w-[35rem]'>
                                <div className='flex flex-row items-center justify-start w-full gap-2'>
                                    <Input
                                        id={'filterName'}
                                        onChange={(e) => setFilterConstructor({...filterConstructor, name: e?.target?.value})}
                                        value={filterConstructor?.name}
                                        width={80}
                                        charLimit={200}
                                        onKeyUp={(e) => {
                                            if (['Enter', 'NumpadEnter']?.includes(e?.code)){
                                                if (filterConstructor?.name){
                                                    setFilter(filterConstructor)
                                                }
                                            }
                                        }}
                                    />
                                    <FaSearch className='text-primaryDefaultLight cursor-pointer' onClick={() => setFilter(filterConstructor)}/>
                                    {
                                        filter && JSON.stringify(filter) != JSON.stringify(verifyFilter) ? 
                                        <FiSlash className='cursor-pointer text-primaryDefaultLight' title='Limpar filtro' onClick={async () => {
                                            setFilter(verifyFilter)
                                            setFilterConstructor(verifyFilter)
                                            await new Promise(resolve => setTimeout(resolve, 500))
                                        }} />
                                        : <></>
                                    }
                                    <IoIosCloseCircleOutline 
                                        title='Fechar' 
                                        className='text-2xl absolute right-1 top-2 cursor-pointer text-primaryDefaultLight hover:text-bgSecondaryLight duration-100' 
                                        onClick={() => {
                                            setShowTableImportSenior(false)
                                            setFilter({ })
                                            setFilterConstructor({ })
                                        }}
                                    />
                                </div>
                                <TablePaginationDefault
                                    onClick={(e) => e.func ? e.func.execute(e.data) : selectedTableImport(e)}
                                    quantity={(e) => setQuantity(e)}
                                    apiUrl={url}
                                    filters={filter}
                                    mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": '' }]}
                                    collumns={columnTable}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className={`${showTableImportSenior ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[100vh] sm:h-full z-[103]`}></div>
            </div>
            <div className='animate-[wiggleLeft_1s_ease-in-out] flex flex-row pt-12 justify-center w-full h-fit mb-20'>
                <div className={'containerFormsRequestAdmission w-[90%] lg:pb-3 lg:h-full h-[90%]'}>
                    {
                        loading ?
                        <>
                            <div className='hidden headerFormRequestAdmission lg:flex opacity-50'>
                                <div className={selectedForm === 1 ? 'hasHoverRequest selected' : 'hasHoverRequest'}><p>Dados do Contrato de Trabalho</p></div>
                                <div className={selectedForm === 2 ? 'hasHoverRequest selected' : 'hasHoverRequest'}><p>Dados do Empregado</p></div>
                                <div className={selectedForm === 3 ? 'hasHoverRequest selected' : 'hasHoverRequest'}><p>Familiares e Dados de dependentes</p></div>
                                <div className={selectedForm === 4 ? 'hasHoverRequest selected' : 'hasHoverRequest'}><p>Folpag Connect para Senior</p></div>
                                <div>
                                    <button> <p> {!inEdit ? "Editar" : "Aplicar"} </p> </button>
                                    <button> <p> Salvar </p> </button>
                                    <button> <p> Reverter </p> </button>
                                    <button> <p> Voltar </p> </button>
                                    <button> <p className='lg:w-20'> Gerar PDF </p> </button>
                                    <button> <FiUserPlus /> <p> Importar  </p> </button>
                                </div>
                            </div>
                            <div className='flex lg:hidden gap-2 justify-around h-10 text-center bg-[#ececec] text-primaryDefaultLight relative border-l border-l-[#ececec] border-r border-r-[#ececec] border-b border-b-[#311962] opacity-50'>
                                <div className={selectedForm === 1 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#31196236]' : 'p-2'}>
                                <p className='text-xs'>{selectedForm === 1 ? 'Contrato de Trabalho' : 'Contrato...'}</p>
                                </div>
                                <div className={selectedForm === 2 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#31196236]' : 'p-2'}>
                                <p className='text-xs'>{selectedForm === 2 ? 'Empregado' : 'Empre...'}</p>
                                </div>
                                <div className={selectedForm === 3 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#31196236]' : 'p-2'}>
                                <p className='text-xs'>{selectedForm === 3 ? 'Familiares' : 'Fami...'}</p>
                                </div>
                                <div className={selectedForm === 4 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#31196236]' : 'p-2'}>
                                    <p className='text-xs'>{selectedForm === 4 ? 'Folpag para Senior' : 'Folpag...'}</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='hidden headerFormRequestAdmission lg:flex'>
                                <div className={selectedForm === 1 ? 'hasHoverRequest selected' : 'hasHoverRequest'} onClick={() => checkFillTabs(1)}><p>Dados do Contrato de Trabalho</p></div>
                                <div className={selectedForm === 2 ? 'hasHoverRequest selected' : 'hasHoverRequest'} onClick={() => checkFillTabs(2)}><p>Dados do Empregado</p></div>
                                <div className={selectedForm === 3 ? 'hasHoverRequest selected' : 'hasHoverRequest'} onClick={() => checkFillTabs(3)}><p>Familiares e Dados de dependentes</p></div>
                                <div className={selectedForm === 4 ? 'hasHoverRequest selected' : 'hasHoverRequest'} onClick={() => checkFillTabs(4)}><p>Folpag Connect para Senior</p></div>
                                <div>
                                    <button onClick={() => {setInEdit(!inEdit)}} type='button'> <p> {!inEdit ? "Editar" : "Aplicar"} </p> </button>
                                    <button onClick={() => {setShow(true); setShowModalConfirmedSave(true)}} type='button'> <p> Salvar </p> </button>
                                    <button onClick={() => { setInEdit(false); goBackHistory() }} type='button'> <p> Reverter </p> </button>
                                    <button onClick={() => navigate(-1)} type='button'> <p> Voltar </p> </button>
                                    <button onClick={generatePdf} type='button'> <p className='lg:w-20'> Gerar PDF </p> </button>
                                    <button type='button' onClick={() => handleSubmitSenior()}> <FiUserPlus /> <p> Importar  </p> </button>
                                </div>
                            </div>
                            <div className='flex lg:hidden gap-2 justify-around h-10 text-center bg-[#ececec] text-primaryDefaultLight relative border-l border-l-[#ececec] border-r border-r-[#ececec] border-b border-b-[#311962]'>
                                <div className={selectedForm === 1 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#31196236]' : 'p-2'} onClick={() => checkFillTabs(1)}>
                                <p className='text-xs'>{selectedForm === 1 ? 'Contrato de Trabalho' : 'Contrato...'}</p>
                                </div>
                                <div className={selectedForm === 2 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#31196236]' : 'p-2'} onClick={() => checkFillTabs(2)}>
                                <p className='text-xs'>{selectedForm === 2 ? 'Empregado' : 'Empre...'}</p>
                                </div>
                                <div className={selectedForm === 3 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#31196236]' : 'p-2'} onClick={() => checkFillTabs(3)}>
                                <p className='text-xs'>{selectedForm === 3 ? 'Familiares' : 'Fami...'}</p>
                                </div>
                                <div className={selectedForm === 4 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#31196236]' : 'p-2'} onClick={() => checkFillTabs(4)}>
                                    <p className='text-xs'>{selectedForm === 4 ? 'Folpag para Senior' : 'Folpag...'}</p>
                                </div>
                            </div>
                        </>
                    } 
                    <div id='divMainContainerForm' className='w-full h-full overflow-hidden'>
                        {
                            loading ?
                                (
                                    <div className='loadingFormRequest'>
                                        <Loader />
                                    </div>
                                ) : selectedForm === 1 ? 
                                    contractDataForm()
                                : selectedForm === 2 ? 
                                    employeeDataForm()
                                : selectedForm === 3 ? 
                                    dependentsForm()
                                : selectedForm === 4 ? 
                                    connectToSeniorForm()
                                : null
                        }

                        <div id="exportEmployee">
                            <h1>Folpag Connect</h1>
                            <h2>Dados de Contrato</h2>
                                {contractDataForm()}
                            <h2>Dados do Empregado</h2>
                                {employeeDataForm() }                            
                            <h2>Dados do Dependente</h2>
                                {dependentsForm()}                 
                        </div>
                    </div>
                </div>
                {
                    loading ? 
                    <div className='flex lg:hidden absolute py-1 z-40 bottom-8 bg-white h-10 w-full flex-row items-center justify-around opacity-50'>
                        <button className='p-1 rounded-sm bg-primaryDefaultLight text-white'>Voltar</button>
                        <button className='p-1 rounded-sm bg-primaryDefaultLight text-white'>Salvar</button>
                        <button className='p-1 rounded-sm bg-primaryDefaultLight text-white'>{!inEdit ? "Editar" : "Aplicar"}</button>
                        <button className='p-1 rounded-sm bg-primaryDefaultLight text-white'>Reverter</button>
                        <div className='flex flex-row p-1 rounded-sm bg-primaryDefaultLight text-white items-center gap-2'>
                            <p><FiUserPlus /></p>
                            <button type='button'>Importar</button>
                        </div>
                    </div>
                    :
                    <div className='flex lg:hidden absolute py-1 z-40 bottom-8 bg-white h-10 w-full flex-row items-center justify-around'>
                        <button 
                            onClick={() => navigate(-1)} 
                            type='button'
                            className='p-1 rounded-sm bg-primaryDefaultLight text-white'
                        >
                            Voltar
                        </button>
                        <button 
                            onClick={() => {setShow(true); setShowModalConfirmedSave(true)}}
                            type='button'
                            className='p-1 rounded-sm bg-primaryDefaultLight text-white'
                        >
                            Salvar
                        </button>
                        <button 
                            onClick={() => {setInEdit(!inEdit)}} 
                            type='button'
                            className='p-1 rounded-sm bg-primaryDefaultLight text-white'
                        >
                            {!inEdit ? "Editar" : "Aplicar"}
                        </button>
                        <button 
                            onClick={() => { setInEdit(false); goBackHistory() }} 
                            type='button'
                            className='p-1 rounded-sm bg-primaryDefaultLight text-white'
                        >
                            Reverter
                        </button>
                        <div className='flex flex-row p-1 rounded-sm bg-primaryDefaultLight text-white items-center gap-2'>
                            <p><FiUserPlus /></p>
                            <button type='button' onClick={() => {scrollTopDiv(); handleSubmitSenior()}}>Importar</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )

}