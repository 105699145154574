/**
 * Desenvolvedor: Tiago Murilo Ochôa da Luz
 * Descrição: Area do sistema onde há uma tabela onde a primeira coluna e o o cabeçalho são estaticos, podendo
 *    inserir e remover colunas para enviar os eventos de folha por colaborador, de forma que seja semelhante 
 *    a uma planilha excel.
 */

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; //comportamento SPA, não recarrega todo react ao trafegar por paginas
import { FiArrowLeft, FiUpload, FiCheck, FiDownload, FiAlertTriangle, FiX, FiClock } from 'react-icons/fi'; 
// import {StickyTable, Row, Cell} from 'react-sticky-table';
import underscore from 'underscore';
import { Input } from '../../components/input/input'
import { LabelInput } from '../../components/label/label.input'
import { Button } from '../../components/buttons/button.default'

import './sendEvents.css';
import api from '../../services/api';
import sendEventsUtil from './sendEventsUtil'
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu';
import Loader from '../../components/Loader/loader';
import { useModal } from '../../hooks/useModal';
import Modal from '../../components/Modal/modal';

export default function SendEvents() {

    const { company, branch } = useCompanyBranch();
    const periodName = sessionStorage.getItem('period');
    const period = sessionStorage.getItem('periodId');
    const token = localStorage.getItem('@auth:token');
    const { setShow, show } = useModal()
    
    const [persons, setPersons] = useState([]); //lista de colaboradores
    const [events, setEvents] = useState([]); //lista de eventos
    const [sendPersonEvents, setSendPersonEvents] = useState([]);
    const [selectedOption, setSelectedOption] = useState(1);
    const [selectedInput, setSelectedInput] = useState(1)
    const [documentToImport, setDocumentToImport] = useState('');
    const [loading, setLoading] = useState(true); 
    const { setMenuTitle, menuStatus } = useMenu()
    const navigate = useNavigate();
    setMenuTitle('Lançar Eventos')
    const { setShowNotificationModal, setNotificationModalText } = useModal()
    const [ emptyEvents, setEmptyEvents ] = useState(false)
    const [ buttonSubmit, setButtonSubmit ] = useState(false)
    const [ buttonSave, setButtonSave ] = useState(false)
    const [ errorAttach, setErrorAttach ] = useState(false)
    const [ buttonSubmitImport, setButtonSubmitImport ] = useState(false)
    const [ errorFormat, setErrorFormat ] = useState(false)

    const [totals, setTotals] = useState([])

    useEffect(()=>{
      
        setLoading(true);
        async function apiConsult(){
            
            try {
                const reponseEvents = await api.get(`api/v1/eventsParam/${company}/${branch}`, {
                    headers: {
                        Authorization: token
                    }
                })

                setEvents(reponseEvents.data);

                const reponsePersons = await api.get(`api/v1/persons/persons/${company}/${branch}/${period}`, {
                    headers: {
                        Authorization: token,
                    }
                })
                setPersons(reponsePersons.data);

                const reponseSendPersonEvents = await api.get(`api/v1/sendEvents/saveSendEvents/company/${company}/${branch}/period/${period}`, {
                    headers: {
                        Authorization: token
                    }
                })

                setSendPersonEvents(reponseSendPersonEvents.data);

                setTotals([])
                reponseEvents.data.map(event => {
                    let valuesPerEvent = 0
                    reponseSendPersonEvents.data.map(send => {
                        if (event?.event == send?.event){
                            valuesPerEvent += send?.value
                        }
                    })
                    
                    let objOfValues = {
                        event: event?.event,
                        eventType: event?.eventType,
                        total: valuesPerEvent
                    }
                           
                    setTotals(prev => ([...prev, objOfValues]))
                })
                
                setLoading(false);
            } catch (error) {
                alert('Erro ao buscar dados, se o problema persistir entre em contato com o suporte')
                setLoading(false);
            }
            
        }

        apiConsult();

    },  [ token, company, period, selectedOption]);

    useEffect(() => {
        if (!show){
            setButtonSubmit(false)
            setEmptyEvents(false)
            setErrorAttach(false)
            setButtonSubmitImport(false)
            setErrorFormat(false)
        }
    }, [show])

    function handleEvents(value, person, event, type){
        const searchItemInArray = underscore.where(sendPersonEvents, { person: person, event: event });
        if(searchItemInArray.length === 0){
            return setSendPersonEvents([...sendPersonEvents, { company: parseInt(company), person: parseInt(person), event: parseInt(event), value: selectedInput == 1 ? parseFloat(value) : value, type: parseInt(type) }] );
        }else{
            const positionItemInArray = sendPersonEvents.indexOf(searchItemInArray[0]);
            if(!value){
                return sendPersonEvents.splice(positionItemInArray, 1);
            }
            return sendPersonEvents[positionItemInArray].value = selectedInput == 1 ? parseFloat(value) : value;
        }
    }

    async function handleSubmitForSave(element){

        if(sendPersonEvents.length === 0){
            setShow(true)
            return setEmptyEvents(true)
        } 

        sendPersonEvents.map(events => {
            if (events.type == 1 && typeof(events.value) == 'string'){
                let separateHourMinute = events.value.split(':')
                let hour = parseInt(separateHourMinute[0])
                let minute = separateHourMinute[1] ? parseInt(separateHourMinute[1]) : 0
                return events.value = Number(hour + minute / 60)?.toFixed(2)
            }
        })
                
        try{
            setLoading(true)
            await api.post('api/v1/sendEvents/saveSendEvents', {
                company: company,
                branch: branch,
                period: period,
                sendEvents: sendPersonEvents
            },{
                headers: {
                    Authorization: token,
                }
            });

        }catch(error){
            return alert('Falha ao salvar lançamentos.');
        }

        setLoading(false)
        setShowNotificationModal(true)
        setNotificationModalText('Lançamentos salvos com sucesso')
        return navigate('/history')
      
    }

    async function handleSubmitDefinitive(element){

        if(sendPersonEvents.length > 0){
            sendPersonEvents.map(events => {
                if (events.type == 1 && typeof(events.value) == 'string'){
                    let separateHourMinute = events.value.split(':')
                    let hour = parseInt(separateHourMinute[0])
                    let minute = separateHourMinute[1] ? parseInt(separateHourMinute[1]) : 0
                    return events.value = Number(hour + minute / 60)?.toFixed(2)
                }
            })
        }

        try{
            setLoading(true)
            await api.post('api/v1/sendEvents/sendEvents', {
              company: company,
              branch: branch,
              period: period,
              sendEvents: sendPersonEvents?.length > 0 ? sendPersonEvents : []
            },{
              headers: {
                Authorization: token,
              }
            });
            setLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Eventos lançados com sucesso')
            return navigate('/history')

        }catch(error){
            setLoading(false)
            return alert('Falha ao salvar lançamentos.');

        }
    }

    async function getPlan(){ //função que realiza download do arquivo excel.
        try{
            setLoading(true)
            const responseApi = await api({
                url: `api/v1/sendEvents/sendEvents/company/${company}/branch/${branch}/period/${period}`, //sua URL
                method: 'GET',
                responseType: 'blob', //faz com que se entenda em arquivo bruto
                headers: {
                    Authorization: token,
                }
            })
            const url = window.URL.createObjectURL(new Blob([responseApi.data])); // cria um novo objeto documento instanciando o que foi recebido no download
            const link = document.createElement('a'); // cria elemento do dom 'a'
            link.href = url; // vincula dom ao objeto criado
            link.setAttribute('download', `(${company} - ${branch}) Planilha de Importação.xlsx`); //coloca os atributos 
            document.body.appendChild(link); 
            link.click(); //clica para que a web entenda o download
            setLoading(false)
            return
        }catch(error){
            return alert('Erro ao tentar realizar download da planilha modelo')
        }
    }

    async function handleImportPlan(e){
        
        if(!documentToImport){
            setShow(true)
            return setErrorAttach(true)
        }
    
        setLoading(true)
        const sends = await sendEventsUtil.readPlanToImport(documentToImport, events, persons);
        
        if(sends === "ERROR"){
            setShow(true)
            setErrorFormat(true)
            setDocumentToImport('')
            setSelectedOption(1)
            return setSelectedOption(selectedOption + 1)
        }

        try{
            setLoading(true)
            await api.post('api/v1/sendEvents/importSendEvents',
                {
                    period: period,
                    branch: branch,
                    company: company,
                    sends: sends
                },{
                    headers: {
                        Authorization: token,
                    }
                }
            )
            
            setLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Importação realizada com Sucesso, Verifique antes de "Enviar em Definitivo"')
            navigate('/history')
            navigate('/sendEvents')
            return

        }catch(error){
            setLoading(0)
            return alert('Erro ao realizar importação, verifique a planilha.')
        }
      
    }

    function validTimeFormat(time){
        const timeFormat = /^[0-9]{0,4}:[0-5]{0,1}[0-9]{0,1}$/;
        
        if (timeFormat.test(time)) {
            let size = time.split(':')[1].length
            if(size == 1){
                return time += '0'
            }else{
                return time
            }
        } else {
            let inputValue = time;
            if (!inputValue.includes(":")) {
                if(inputValue.length > 4){
                    return false
                }
                if(isNaN(Number(time.split(':')[0]))){
                    return false
                }
            }
            if (timeFormat.test(time)) {
                let size = time.split(':')[1].length
                if(isNaN(Number(time.split(':')[0]))){
                    return false
                }
                if(size == 1){
                    return inputValue += '0'
                }else{
                    return inputValue
                }
            } else {
                return false
            }
        }

    }


    async function sumTotalValues(event){        
        if (selectedInput == 1 || (selectedInput == 2 && (event?.eventType == 2 || event?.eventType == 1))){
            const total = (sendPersonEvents.filter(personEvent => personEvent?.event === event?.event)).map(eventValue => {
                let valueEvent = eventValue?.value
                if (typeof(eventValue?.value) === 'string'){
                    const splitValue = String(eventValue?.value)?.split(':')
                    const calcMinute = Number(splitValue[1]) / 60
                    valueEvent = Number(splitValue[0]) + calcMinute
                }
                return valueEvent
            })?.reduce(function(a, b){ return Number(a) + Number(b) }, 0)
    
            setTotals(totals.map(totalRow => {
                if(totalRow?.event == event?.event){
    
                   return {
                    ...totalRow,
                    total: total
                   }
                }else {
                    return totalRow
                }
            }))
        } else {
            if (event?.value != '' || event?.value != 0){
                const total = (sendPersonEvents.filter(personEvent => personEvent.event === event.event)).map(eventValue => {
                    const splitValue = eventValue?.value?.split(':')
                    const newFormat = `${splitValue[0]}:${splitValue[1]}`
                    return newFormat
                })
                
                const newTotal = total.map(total => {
                    let separateHourMinute = total.split(':')
                    let hour = parseInt(separateHourMinute[0])
                    let minute = separateHourMinute[1] ? parseInt(separateHourMinute[1]) : 0
                    return Number(Number(hour + minute / 60)?.toFixed(2))
                }).reduce((a, b) => { return a + b })
                
                setTotals(totals.map(totalRow => {
                    if(totalRow.event == event.event){
        
                        return {
                        ...totalRow,
                        total: newTotal ? newTotal?.toFixed(2) : 0
                        }
                    }else {
                        return totalRow
                    }
                }))                
            }
        }
    }

    function handleSendHours(){
        if(document.getElementById('sistemaCheck')!== null && selectedOption === 1){
            document.getElementById('sistemaCheck').checked = true;
        }else if(document.getElementById('sistemaCheck')!== null && selectedOption !== 1){
            document.getElementById('sistemaCheck').checked = false;
        }

        if(selectedOption === 1){
            return(
                <div className='flex flex-col gap-5 justify-center items-center'>
                    <form method="#" id="formulario" className='border border-primaryDefaultLight flex flex-col h-[60vh] w-[85%] mt-5 overflow-auto top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 z-0'>
                        <table className='border-collapse'>
                            <thead className='m-0 bg-primaryDefaultLight text-white font-bold relative'>
                                <tr className='sticky top-0 z-[300]'>
                                    <th className='sticky left-0 top-0 bg-primaryDefaultLight'>
                                        <div className='min-w-[20rem] z-5 py-3 px-2 border-0 border-r-2 border-r-gray-300 w-full'>Colaborador</div>
                                    </th>
                                    <th className='left-0 top-0 bg-primaryDefaultLight'>
                                        <div className='min-w-[20rem] z-5 py-3 px-2 border-0 border-r-2 border-r-gray-300 w-full'>Centro de Custo</div>
                                    </th>
                                    {
                                        events.map(event => {
                                            return (
                                                <th id={'event' + event.event} className='bg-primaryDefaultLight'>{ event.event } - { event.description } </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody className='pb-3 m-0'>
                                {
                                    persons.map((person, index) => {
                                        return (
                                            <tr className={`${index % 2 == 0 ? 'bg-white': 'bg-[#ececec]'}`}>
                                                <td className={`m-0 p-0 z-10 sticky left-0 ${index % 2 == 0 ? 'bg-white': 'bg-[#ececec]'} z-3`}> 
                                                    <div id={'person'+ person.numcad} className='min-w-[20rem] py-2 px-2 border-0 border-r-2 border-r-gray-300 w-full'>{person.numcad} - {person.nomfun} </div> 
                                                </td>
                                                <td className={`${index % 2 == 0 ? 'bg-white': 'bg-[#ececec]'}`}> 
                                                    <div id={'person'+ person.codccu} className='min-w-[20rem] py-2 px-2 border-0 border-r-2 border-r-gray-300 w-full'>{person.codccuConcat ? person.codccuConcat : ' - '} </div> 
                                                </td>
                                                {
                                                    events.map((event, index) => {
                                                        
                                                        if(event.eventType == 1 && selectedInput == 2){
                                                            let searchItemInArray = underscore.where(sendPersonEvents, { person: person.numcad, event: event.event });
                                                            let formatHour = ''
                                                            if(searchItemInArray.length > 0){
                                                                let positionItemInArray = sendPersonEvents.indexOf(searchItemInArray[0]);
                                                                sendPersonEvents[positionItemInArray].value = sendPersonEvents[positionItemInArray].value.toString()                                                                
                                                                let separator = sendPersonEvents[positionItemInArray].value.split('.')
                                                                let formatMinute = separator[1]
                                                                if(!formatMinute){
                                                                    formatMinute = '00'
                                                                }else{
                                                                    if(formatMinute[0] == '0'){
                                                                        formatMinute = "0"+ ((separator[1]/10)*60).toString()[0]
                                                                        if(formatMinute[1]){
                                                                            formatMinute = "0"+ Math.round(((separator[1]*60)/100)).toString()[0]
                                                                        }
                                                                    }else{
                                                                        let calc = ((separator[1]/10)*60)
                                                                        if(calc.toString()[2]){
                                                                            if(Number(calc.toString()[2]) > 5){
                                                                                formatMinute = ((separator[1]*61)/100).toString().slice(0,2)
                                                                            }else{
                                                                                formatMinute = ((separator[1]*60)/100).toString().slice(0,2)
                                                                            }
                                                                        }else{
                                                                            formatMinute = calc.toString().slice(0,2)
                                                                            if(formatMinute.length == 1){
                                                                                formatMinute = '0' + formatMinute
                                                                            }
                                                                        }
                                                                    }
                                                                }   
                                                                formatHour = `${separator[0].length < 2 ? '0' + separator[0] : separator[0]}:${formatMinute}`
                                                                sendPersonEvents[positionItemInArray].value = String(formatHour).slice(0, 5)
                                                            }
                                                        
                                                            return (
                                                                <td className='px-4'>
                                                                    <div className='flex gap-1 items-center'>
                                                                        <input 
                                                                            type={'string'} 
                                                                            className={`border border-gray-400 text-primaryDefaultLight px-1 py-1 ${selectedInput == 2 ? 'w-48 cursor-text' : ''}`}                                                                            
                                                                            defaultValue={formatHour}
                                                                            placeholder={'hh:mm'}
                                                                            id={`send`+person.numcad+event.event+event.eventType}
                                                                            onFocus={element => {
                                                                                element.target.classList.add('activeInput')
                                                                                document.getElementById(`event${event.event}`).classList.add('selectedeventsend')
                                                                                document.getElementById(`person${person.numcad}`).classList.add('selectedpersonsend')
                                                                            }}
                                                                            onBlur={element => {
                                                                                element.target.classList.remove('activeInput')
                                                                                document.getElementById(`event${event.event}`).classList.remove('selectedeventsend')
                                                                                document.getElementById(`person${person.numcad}`).classList.remove('selectedpersonsend')
                                                                                sumTotalValues({value: element.target.value, event: event.event, eventType: event.eventType})
                                                                            }}
                                                                            onChange={element => {
                                                                                const valid = validTimeFormat(element.target.value)                                                                                
                                                                                if(valid){
                                                                                    document.getElementById(`send`+person.numcad+event.event+event.eventType).style.backgroundColor = '#FFFFFF'
                                                                                    handleEvents(valid, person.numcad, event.event, event.eventType)
                                                                                }else{
                                                                                    const searchItemInArray = underscore.where(sendPersonEvents, { person: person?.numcad, event: event?.event });                                                                                    
                                                                                    if (searchItemInArray?.length) {
                                                                                        const positionItemInArray = sendPersonEvents.indexOf(searchItemInArray[0]);
                                                                                        if(!element.target.value){
                                                                                            sendPersonEvents.splice(positionItemInArray, 1);
                                                                                        }
                                                                                    }
                                                                                    document.getElementById(`send`+person.numcad+event.event+event.eventType).style.backgroundColor = '#fca5a5'                                                                                    
                                                                                }
                                                                            }}
                                                                        /> 
                                                                        <FiClock />
                                                                    </div> 
                                                                </td>
                                                            )
                                                        } else {

                                                            let searchItemInArray = underscore.where(sendPersonEvents, { person: person.numcad, event: event.event });
                                                            let valueEvent = ''
                                                            if(searchItemInArray.length > 0){
                                                                let positionItemInArray = sendPersonEvents.indexOf(searchItemInArray[0]);
                                                                if (typeof(sendPersonEvents[positionItemInArray].value) === 'string'){
                                                                    const splitValue = String(sendPersonEvents[positionItemInArray].value)?.split(':')
                                                                    const calcMinute = Number(splitValue[1]) / 60
                                                                    valueEvent = Number(splitValue[0]) + calcMinute
                                                                } else {
                                                                    valueEvent = sendPersonEvents[positionItemInArray].value;
                                                                }
                                                            }
                                                            return (
                                                                <td className='px-4'> 
                                                                    <input 
                                                                        type={"number"} 
                                                                        className='border border-gray-400 text-primaryDefaultLight px-1 py-1'
                                                                        defaultValue={valueEvent}
                                                                        onFocus={element => {
                                                                            element.target.classList.add('activeInput')
                                                                            document.getElementById(`event${event.event}`).classList.add('selectedeventsend')
                                                                            document.getElementById(`person${person.numcad}`).classList.add('selectedpersonsend')
                                                                        }}
                                                                        onBlur={element => {
                                                                            sumTotalValues({value: element.target.value, event: event.event, eventType: event.eventType})
                                                                            element.target.classList.remove('activeInput')
                                                                            document.getElementById(`event${event.event}`).classList.remove('selectedeventsend')
                                                                            document.getElementById(`person${person.numcad}`).classList.remove('selectedpersonsend')
                                                                        }}
                                                                        onChange={element => {                                                                        
                                                                            handleEvents(element.target.value, person.numcad, event.event, event.eventType)
                                                                        }}
                                                                    /> 
                                                                </td>
                                                            )
                                                        }                                                    
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                <tr className={`bg-primaryDefaultLight sticky bottom-0 z-[300]`}>
                                    <th className={'flex justify-center items-center sticky z-[40] left-0 top-0 bg-primaryDefaultLight'}> 
                                        <div className='min-w-[20rem] text-white py-2 pb-4 px-2 border-0 border-r-2 border-r-gray-300 h-10 flex justify-center items-center w-full'>Totais</div> 
                                    </th>
                                    <td> 
                                        <div className="flex justify-center items-center text-white pb-2"> - </div> 
                                    </td>
                                    {
                                        totals.map(total => {
                                            if (total.eventType == 1 && selectedInput == 2){
                                                let formatHour = ''
                                                let stringValue = total?.total.toString()
                                                let separator = stringValue.split('.')
                                                let formatMinute = separator[1]
                                                if(!formatMinute){
                                                    formatMinute = '00'
                                                }else{
                                                    if(formatMinute[0] == '0'){
                                                        formatMinute = "0"+ ((separator[1]/10)*60).toString()[0]
                                                        if(formatMinute[1]){
                                                            formatMinute = "0"+ Math.round(((separator[1]*60)/100)).toString()[0]
                                                        }
                                                    }else{
                                                        let calc = ((separator[1]/10)*60)
                                                        if(calc.toString()[2]){
                                                            if(Number(calc.toString()[2]) > 5){
                                                                formatMinute = ((separator[1]*61)/100).toString().slice(0,2)
                                                            }else{
                                                                formatMinute = ((separator[1]*60)/100).toString().slice(0,2)
                                                            }
                                                        }else{
                                                            formatMinute = calc.toString().slice(0,2)
                                                            if(formatMinute.length == 1){
                                                                formatMinute = '0' + formatMinute
                                                            }
                                                        }
                                                    }
                                                }
                                                formatHour = `${separator[0].length < 2 ? '0' + separator[0] : separator[0]}:${formatMinute}`
                                                return (
                                                    <td> 
                                                        <div className="flex justify-center items-center text-white pb-2">{formatHour}</div> 
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td> 
                                                        <div className="flex justify-center items-center text-white pb-2">{total?.total && selectedInput == 1 ? Number(total?.total)?.toFixed(2) : total?.total}</div> 
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>                  
                    </form>
                    <div className='items-end gap-2 flex'>
                        <Button onClick={() => {setShow(true); setButtonSave(true)}} approval={false} shadow={true}>Salvar</Button>
                        <Button onClick={() => {setShow(true); setButtonSubmit(true)}} shadow={true}>Enviar</Button>
                    </div>
                </div>
            );
        }else{
            return(
                <form method='post' className="flex flex-col justify-center items-center gap-10 mt-5 pl-10" enctype="multipart/form-data">
                    <div className='flex flex-col items-center justify-center text-red-900 font-black'>
                        <p>Atenção: O layout da planilha modelo não deve ser alterado.  </p>                      
                        <p>A linha '1' onde estão os eventos e coluna 'A' onde estão os funcionários devem permanecer na mesma ordem.</p>
                        <p>Lançar na planilha excel apenas em centesimal.</p>
                    </div>
                    <div className="flex gap-3 justify-center items-center flex-row font-black cursor-pointer ml-[-4rem]">
                        <p>1 -</p>
                        <div className='border border-green-800 hover:bg-white duration-300 hover:text-green-800 w-60 h-10 items-center justify-center text-white bg-[#1F6F45] rounded-md'>
                            <button className='flex flex-row gap-1 items-center justify-center m-auto mt-2' type='button' onClick={ ()=> getPlan()}>
                                <FiDownload/> 
                                Download planilha modelo
                            </button>
                        </div>
                    </div>
                    <div className="flex gap-3 justify-center items-center flex-row font-black cursor-pointer ml-[-4rem]">
                        <p>2 -</p>
                        <div className='border border-[#311962] hover:bg-white hover:text-[#311962] w-60 h-10 items-center justify-center text-white bg-[#311962] rounded-md'>
                            <label className='flex flex-row gap-1 justify-center items-center mt-2 cursor-pointer duration-300 font-black' for='linkFile'><FiUpload/> Anexar planilha preenchida</label>
                            <input
                                className='hidden cursor-pointer'                            
                                id='linkFile' 
                                type='file' 
                                accept='.xls,.xlsx' 
                                onChange={(element)=>{
                                    setDocumentToImport(element.target)
                                    document.getElementById('showArchivePlan').innerHTML = `<p>${element.target.files[0].name}</p>`
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-3 justify-center items-center flex-row font-black cursor-pointer ml-[-4rem]">
                        <p>3 -</p>
                        <div className='border border-[#2f0585] hover:bg-white duration-300 hover:text-[#2f0585] w-60 h-10 items-center justify-center text-white bg-[#2f0585] rounded-md'>
                            <button className="flex flex-row gap-1 items-center justify-center m-auto mt-2" type='button' onClick={() => {setShow(true); setButtonSubmitImport(true)}}>
                                <FiCheck/>
                                Importar 
                            </button>
                        </div>
                    </div>          
                </form>
            )
        }

    }

    return(
        <div>
            {
                show && emptyEvents ? 
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Não foi lançado nenhum evento. 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                </Modal>
                : show && buttonSubmit ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold items-center text-lg'>
                                <p className='mr-7'>Enviar em Definitivo</p> 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                        <div className='w-full text-lg flex flex-col justify-center items-center mb-6'>
                            {
                                sendPersonEvents.length === 0 ?
                                <p className='w-full text-lg text-orange-700 text-center'>Os eventos estão vazios.</p>
                                : <></>
                            }
                            <p>Deseja realmente "Enviar em Definitivo?"</p>
                        </div>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button background={'bg-red-800'} onClick={() => {setShow(false); setButtonSubmit(false)}}>Cancelar</Button>
                            <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleSubmitDefinitive()}}>Confirmar</Button>
                        </div>
                    </div>
                </Modal>
                : show && errorAttach ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Primeiro você precisa anexar um arquivo!<br/>Faça isso na 2ª etapa 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                </Modal>
                : show && errorFormat ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Planilha fora de formatação! <br/>Preencha somente os campos necessários.
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                </Modal>
                : show && buttonSubmitImport ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold items-center text-lg'>
                                <span className='mr-7'>Deseja realmente importar esse arquivo?</span> 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                        <span className='text-center mb-3 text-md text-red-600 font-black'>Se você importar irá sobrescrever os eventos lançados.</span>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button background={'bg-red-800'} onClick={() => {setShow(false); setButtonSubmitImport(false)}}>Cancelar</Button>
                            <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleImportPlan()}}>Confirmar</Button>
                        </div>
                    </div>
                </Modal>
                : show && buttonSave ?
                <Modal>
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold items-center text-lg'>
                                <span className='mr-7 text-red-600'>Alerta!</span> 
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                        <p className='text-center mb-5 text-[1rem] p-4'>
                            <b>Você não está enviando os eventos para a folha.</b><br/>
                            Se você deseja enviar para a folha clique em <b>"Enviar"</b>.<br/>
                            Caso queira salvar, clique em "Prosseguir"
                        </p>
                        <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                            <Button background={'bg-red-800'} onClick={() => {setShow(false); setButtonSubmitImport(false)}}>Cancelar</Button>
                            <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleSubmitForSave()}}>Prosseguir</Button>
                        </div>
                    </div>
                </Modal>
                :
                <></>      
            }
            <div className='conteudo flex flex-col'>
                <div className='flex justify-around w-[100%] items-center'>
                    <div className={`${menuStatus == 1 ? 'topHeaderSendEventRigth' : 'topHeaderSendEvent'} flex justify-around pl-10 w-full items-center`}>
                        <Link to='/history' className={`${menuStatus == 1 ? 'voltar_historicoRight':'voltar_historico'} hover:text-gray-400 flex gap-1 items-center`}><FiArrowLeft /> <p> Voltar </p> </Link>
                        <div className={menuStatus == 1 ? 'titleHeaderRight' : 'titleHeader'}>
                            <h1 className='text-3xl font-black text-center'>{periodName}</h1>
                            <div className='flex flex-row font-semibold cursor-default'>
                                <div className='flex gap-1 items-center'>
                                    <input type="checkbox" id='centesimal' name='centesimal' checked={selectedInput == 1 ? true : false} className={`${selectedOption == 2 ? 'ml-[1rem]' : ''} cursor-pointer`} onClick={() => {                                        
                                        setSelectedInput(1)
                                        document.getElementById('centesimal').checked = true;
                                        document.getElementById('sexagesimal').checked = false;
                                    }}/>
                                    <p>Centesimal</p>
                                </div>
                                <div className={`${selectedOption == 2 ? 'hidden' : 'flex gap-1 items-center'}`}>
                                    <input type="checkbox" id='sexagesimal' name='sexagesimal' className={`ml-2 cursor-pointer`} onClick={() => {                                        
                                        setSelectedInput(2)
                                        document.getElementById('sexagesimal').checked = true;
                                        document.getElementById('centesimal').checked = false;
                                    }}/>
                                    <p>Sexagesimal</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${menuStatus == 1 ? 'selecionarFormaPagamentoRight' : 'selecionarFormaPagamento'} flex flex-col`}>
                            <h4>Lançar horas: </h4>
                            <div className='selecionarFormaPagamento_opcoes'> 
                                <div className='flex gap-1'>
                                    <input className='cursor-pointer' type="checkbox" id="sistemaCheck" name="sistemaCheck" onClick={()=>{ 
                                        setSelectedOption(1);
                                        setSendPersonEvents([])
                                        setEvents([]);
                                        setPersons([]);
                                        document.getElementById('sistemaCheck').checked = true;
                                        document.getElementById('excelCheck').checked = false;
                                    }}/>
                                    <label for="excelCheck">Sistema</label>
                                </div>
                                <div className='flex gap-1'>
                                    <input className='cursor-pointer' type="checkbox" id="excelCheck" name="excelCheck" onClick={()=>{ 
                                        setSelectedOption(2);
                                        document.getElementById('excelCheck').checked = true;
                                        document.getElementById('sistemaCheck').checked = false;
                                    }}/>
                                    <label for="excelCheck">Excel</label>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                { !loading ? handleSendHours() : (
                    <div className={menuStatus == 1 ? 'tableAreaRightLoaderSend' : 'tableAreaLoaderSend'}>
                        <Loader/>
                    </div>
                )}
            </div>
        </div>
    ); 

};
