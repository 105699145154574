import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
// Hooks
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu';
import { useModal } from '../../hooks/useModal';
// Components
import Loader from '../../components/Loader/loader';
import { InputAutoComplete } from '../../components/input/input.autocomplete';
import { LabelInput } from '../../components/label/label.input';

import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { FaFileExcel, FaPrint, FaSearch, FaEdit } from 'react-icons/fa';
import Multiselect from '../../components/selects/mult.select';
import { TablePaginationDefault } from '../../components/table/tablePagination.default';
import { Input } from '../../components/input/input';
import { FiSlash } from 'react-icons/fi';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { LoadingPulse } from '../../components/Loader/loadingPulse';

export function ReportIsonomySalary(){

    const navigate = useNavigate()
    const { setShowNotificationModal, setNotificationModalText, setShowAprovalModal } = useModal()
    const { company, branch } = useCompanyBranch();
    const [loading, setLoading] = useState(false);
    const { setMenuTitle } = useMenu()
    setMenuTitle('Relatórios')
    const token = localStorage.getItem('@auth:token');
    let selectedPDF = false   
    let selectedXLSX = false
    const [selectedOrder, setSelectedOrder] = useState()
    const [branchList, setBranchList] = useState([])
    const [selectedBranchs, setSelectedBranchs] = useState([])
    const [showModalJob, setShowModalJob] = useState('')
    const [selectedJob, setSelectedJob] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [filterConstructor, setFilterConstructor] = useState({ branch })
    const [filter, setFilter] = useState({ branch })
    const [verifyFilter, setVerifyFilter] = useState({ branch })

    useEffect(() => {
        setLoading(true)
        async function getData(){
            if(company === 0 || branch === 0){
                alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
                return navigate('/')
            }

            try {

                const resultBranches = await api.get(`api/v1/companies/branch/${company}`, {
                    headers: {
                        authorization: token
                    }
                })
                setBranchList(resultBranches?.data?.data?.filter(emp => emp?.value != branch))
                setLoading(false)

            } catch (error){
                setLoading(false)
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar busca de dados.')
            }
        }
        getData()
    }, [])

    async function downloadFile(){
        if (!selectedJob){
            return alert('Selecione um cargo')
        }

        setLoading(true)

        try {
            const type = selectedPDF ? 'pdf' : 'excel'
            let param = []
            selectedOrder && param.push(`orderBy=${selectedOrder?.value}`)
            selectedJob && param.push(`codcar=${selectedJob?.jobPositionId}`)
            param = param.join('&')

            const existsBranchs = selectedBranchs ? selectedBranchs?.map(item => {return item?.value}).join(',') : ''
            const file = await api.get(`api/v1/reports/isonomySalary/numemp/${company}/branch/${existsBranchs ? `${branch},${existsBranchs}` : branch}/type/${type}${param ? `?${param}` : ''}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    authorization: token
                }
            })
            let fileObj = URL.createObjectURL(file?.data)
            let myElement = document.createElement('a')
            myElement.setAttribute('href', fileObj)
            myElement.setAttribute('download', `IsonomiaSalarial_${Number(new Date())}`)
            myElement.click()
            setLoading(false)
        } catch (error){
            setLoading(false)
            setShowAprovalModal(true)
            setShowNotificationModal(true)
            return setNotificationModalText('Não há nada a listar')
        }

    }

    function functionSelectedJob(selected){
        setSelectedJob(selected)
        setShowModalJob(false)
    }

    useEffect(() => {
        if(showModalJob === true){
            window.addEventListener('keydown', eventListener)
        }else{
            window.removeEventListener('keydown', eventListener)
        }
    }, [showModalJob])

    const eventListener = (event) => {
        if(event.key == 'Escape'){
            setShowModalJob(false)
        }
    }

    const orderByList = [
        {
            name: 'Matrícula',
            value: 'numcad'
        },
        {
            name: 'Nome',
            value: 'nomfun'
        },
        {
            name: 'Data Admissão',
            value: 'datadm'
        },
        {
            name: 'Centro de Custo',
            value: 'codccu'
        },
        {
            name: 'Local de Organograma',
            value: 'numloc'
        },
        {
            name: 'Posto de Trabalho',
            value: 'postra'
        },
    ]

    return (
        <>
            <div className={`${showModalJob ? 'flex flex-col overflow-hidden' : 'overflow-hidden'}`}>
                <div className={`${showModalJob ? 'flex ' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[99999]`}>
                    <div className={`animate-[wiggle_.4s_ease-in-out] lg:pb-0 absolute lg:relative bottom-0 w-full lg:w-auto transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow max-h-[39rem] h-[39rem] overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400`}>
                        {
                            loading ?
                            <div className='flex justify-cente pl-3 py-5 items-center ml-7'>
                                <LoadingPulse/>
                            </div>
                            :
                            <div className='p-5 flex flex-col justify-start items-start gap-2 w-[35rem]'>
                                <div className='flex flex-row items-center justify-start w-full gap-2'>
                                    <Input
                                        id={'filterName'}
                                        onChange={(e) => setFilterConstructor({...filterConstructor, name: e?.target?.value})}
                                        value={filterConstructor?.name}
                                        width={80}
                                        charLimit={200}
                                        onKeyUp={(e) => {
                                            if (['Enter', 'NumpadEnter']?.includes(e?.code)){
                                                if (filterConstructor?.name){
                                                    setFilter(filterConstructor)
                                                }
                                            }
                                        }}
                                    />
                                    <FaSearch className='text-primaryDefaultLight cursor-pointer' onClick={() => setFilter(filterConstructor)}/>
                                    {
                                        filter && JSON.stringify(filter) != JSON.stringify(verifyFilter) ? 
                                        <FiSlash className='cursor-pointer text-primaryDefaultLight' title='Limpar filtro' onClick={async () => {
                                            setFilter(verifyFilter)
                                            setFilterConstructor(verifyFilter)
                                            await new Promise(resolve => setTimeout(resolve, 500))
                                        }} />
                                        : <></>
                                    }
                                    <IoIosCloseCircleOutline
                                        title='Fechar' 
                                        className='text-2xl absolute right-1 top-2 cursor-pointer text-primaryDefaultLight hover:text-bgSecondaryLight duration-100' 
                                        onClick={() => {
                                            setShowModalJob(false)
                                            setFilter({ branch })
                                            setFilterConstructor({ branch })
                                        }}
                                    />
                                </div>
                                <TablePaginationDefault
                                    onClick={(e) => e.func ? e.func.execute(e.data) : functionSelectedJob(e)}
                                    quantity={(e) => setQuantity(e)}
                                    apiUrl={`api/v1/employees/jobPositionActiveAtCompanyWithPagination/${company}`}                                    
                                    filters={filter}
                                    mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": '' }]}
                                    collumns={[
                                        {
                                            name: 'jobPositionName',
                                            type: 'string',
                                            description: 'Cargo'
                                        }
                                    ]}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className={`${showModalJob ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[100vh] sm:h-full z-[103]`}></div>
            </div>
            {
                loading ?
                <div className="animate-[wiggleLeft_1s_ease-in-out] flex flex-col w-full min-h-[85vh] items-center justify-center">
                    <Loader />
                </div>
                :
                <div className='flex flex-col w-full h-full justify-start items-center pt-10 sm:pt-14'>
                    <p className='text-xl font-semibold text-center justify-center text-primaryDefaultLight mt-5'>Isonomia Salarial</p>
                    <div className='flex flex-col w-fit h-[55%] justify-center items-center shadow-xl rounded-lg mt-20 sm:mt-20 p-2'>
                        <div className='flex flex-col justify-around h-full items-center p-3'>
                            <div className='flex flex-col'>
                                <div className='w-full flex flex-col gap-1 justify-center items-center text-black'>
                                <a 
                                    className='underline text-primaryDefaultLight cursor-pointer duration-100 hover:text-bgSecondaryLight' 
                                    onClick={() => setShowModalJob(true)}
                                    id='jobNoSelected'
                                > 
                                    Selecione um Cargo *
                                </a>
                                <i className='text-base text-primaryDefaultLight'>{selectedJob?.jobPositionName ?? 'Não selecionado'}</i>
                            </div>
                            <label className='mt-3'>Ordenar por</label>
                            <LabelInput>
                                <InputAutoComplete
                                    data={orderByList}
                                    id={'orderBy'}
                                    optionList={["name"]}
                                    selectedLabel={'name'}
                                    preSelectedValue={selectedOrder ? selectedOrder?.name : ''}
                                    value={selectedOrder ? selectedOrder?.name : ''}
                                    onChange={(e) => {
                                        setSelectedOrder(e)
                                    }}
                                />
                            </LabelInput>
                            <label className='mt-3'>Filiais</label>
                            <LabelInput>
                                <Multiselect
                                    items={[{label: "0 - Todas", value: 'Todas'}, ...branchList]}
                                    mult                                                 
                                    onChange={e => {
                                        if (e[0]?.value == "Todas"){
                                            setSelectedBranchs(branchList)
                                        } else {
                                            setSelectedBranchs(e)
                                        }
                                    }}
                                    selectedLabel={'label'}
                                    value={selectedBranchs ? selectedBranchs : []}
                                    id={`selectedBranchs`}
                                />                                
                            </LabelInput>
                        </div>
                            <div className='flex flex-row justify-between w-full gap-3 mt-5'>
                                <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95' 
                                    onClick={() => {
                                        selectedXLSX = false
                                        selectedPDF = true
                                        downloadFile()
                                    }}
                                >
                                    <FaPrint className='text-sm'/> PDF
                                </div>
                                <div className='bg-primaryDefaultLight text-white w-[50%] cursor-pointer h-7 flex flex-row justify-center items-center gap-2 hover:brightness-95' 
                                    onClick={() => {
                                        selectedPDF = false
                                        selectedXLSX = true
                                        downloadFile()}}
                                    >
                                    <FaFileExcel className='text-sm'/> XLSX
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}